@import "src/colorVariables.scss";

.button {
  all: unset;

  /* regular button styles */
  background-color: blue;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.1s ease-out, box-shadow 0.1s ease-out;

  /* click animation */
  &:active {
    transform: scale(0.95);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  /* disabled button styles */
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
