@import "src/colorVariables.scss";
.main-grid-select {
  height: 100%;
  overflow: auto;
  .header {
    height: 110px;
    display: flex;
    align-items: center;
    .left {
      margin-left: 30px;
      .title {
        font-weight: 700;
        font-size: 30px;
      }
    }
  }
  .choose-view {
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;
    gap: 40px;
    // margin-top: 40px;
    padding-bottom: 30px;
    .choice {
      cursor: pointer;
      // border: 1px solid;
      padding: 20px 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: 0.8rem;

      .image {
        align-items: center;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        height: 70px;
        width: 70px;
        svg {
          height: 40px;
        }
      }

      .text {
        color: #373737;
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 20px;
        margin-top: 8px;
        text-align: center;
      }

      &.selected {
        background-color: #f5f5f5;
        // .image {
        //     background-color: rgba(0, 0, 0, 0.8);
        //     color: #fff;
        // }
      }
    }
  }
  .grid-description {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }
  .amount-per-grid {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    .option {
      margin: 0 10px;
      width: 24px;
      height: 24px;
      font-size: 18px;
      font-weight: bold;
      user-select: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: ease-out 0.2s;
      color: rgba($color: #000000, $alpha: 0.25);
      &:hover {
        background-color: $light;
      }
      &.selected {
        color: black;
      }
      &.disabled {
        pointer-events: none;
        opacity: 0.2;
      }
    }
  }
  .grid-preset-choose {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow: auto;
    max-height: calc(100% - 420px);
    .template-wrap {
      padding: 20px 20px;
      margin-bottom: 10px;
      transition: ease-out 0.2s;
      cursor: pointer;
      &.selected {
        background-color: $light;
      }
      &:hover {
        background-color: $lighter;
      }
      .template {
        pointer-events: none;
        user-select: none;
        width: 480px;
        height: 250px;
        .media-grid {
          .grid-container {
            grid-auto-rows: 125px;
          }
        }
      }
    }
  }
}
