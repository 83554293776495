@import "src/colorVariables.scss";
.configuration {
  width: 100%;
  position: absolute;
  background-color: #fafafa;
  padding-bottom: 400px !important;
  .content {
    display: flex;
    margin: 30px auto 0 auto;
    max-width: 1280px;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 2.4rem;
    height: unset;
    input:not([type="checkbox"]),
    .div-input,
    select {
      // padding: 15px 30px 15px 10px;
      background-color: unset;
    }
    .select {
      > div {
        align-items: center;
        align-items: flex-end;
      }
    }
    .disabled,
    input[disabled] {
      background-color: rgba(162, 162, 162, 0.05) !important;
      > div {
        background-color: rgba(162, 162, 162, 0.05);
      }
    }
    > div {
      background-color: white;
      padding: 2.4rem;
      box-shadow: 0 0 10px rgba(162, 162, 162, 0.1);
      border-radius: 0.2rem;
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;
      gap: 2.4rem;
      .wrap-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        div {
          // max-width: 50%;
          h3 {
            margin: 0;
          }
          p {
            color: rgba(0, 0, 0, 0.7);
          }
        }
        .expandBrand, .expand {
          cursor: pointer;
          svg {
            font-size: 26px;
          }
        }
      }
      .wrap-add {
        display: flex;
        max-width: unset;

        .add {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          padding: 0.2rem;
          border-radius: 4px;
          color: $dark-blue;
          font-size: 0.8em;
          svg {
            height: 0.9em;
            margin-right: 4px;
          }
        }
        .add:hover {
          background-color: rgba(162, 162, 162, 0.2);
        }
      }

      table {
        tr {
          // th,
          td,
          th {
            padding-left: 20px;
            position: relative;
            padding-top: 1.2rem;

            // label {
            //   left: 30px;
            //   top: calc(1.2rem + 5px);
            // }

            .remove {
              width: 20px;

              cursor: pointer;
            }
          }
          th {
            // font-weight: 400;
            // // opacity: 0.4;
            // padding-left: 20px;
            // padding-bottom: 1.2rem;
            // border-bottom: 1px solid rgba(162, 162, 162, 0.2);
          }

          th:first-of-type,
          td:first-of-type {
            padding-left: 0;
          }
          td:last-of-type {
            width: 40px;
            // padding: 0;
          }
        }
      }
    }
    .attributes,
    .price {
      min-width: 600px;
      label {
        position: absolute;
        left: 10px;
        top: 5px;
        font-size: 14px;
        color: $dark-blue;
        z-index: 1;
      }
      .attr-list {
        display: flex;
        flex-direction: column;
        .select {
          min-width: 120px;
          width: 100%;
        }
        .type {
          border: 1px solid rgba(162, 162, 162, 0.1);
          align-items: flex-end;
          display: flex;
          width: 100%;
          justify-content: space-between;
          position: relative;
          flex-direction: column;

          // padding: 1.2rem 0;
          // padding-bottom: 0;
          gap: 0.6rem;
          > div:not(.sub-attr) {
            display: flex;
            width: 100%;
            padding: 1.2rem;
            > div:not(.remove) {
              display: flex;
              width: 100%;
              gap: 1.2rem;
              > div:not(.expand) {
                position: relative;
                width: 100%;
              }
              .expand {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
              }
            }
            .remove,
            .drag {
              position: absolute;
              right: -20px;
              top: 2.4rem;
            }
            .drag {
              cursor: grabbing;
              right: unset;
              left: -5px;
              padding: 40px 10px;
              top: 0;
              width: 20px !important;
              &.dragging {
                width: 100% !important;
              }
            }
          }

          .filterVarient {
            display: flex;
            > div {
              width: 50%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              label {
                position: unset;
              }
              input {
                display: unset;
              }
            }
          }
          .sub-attr {
            display: flex;
            height: 0;
            width: calc(100%);
            flex-direction: column;
            gap: 1.2rem;
            max-height: 600px;
            min-width: 100%;
            overflow: auto;
            table {
              tr {
                .subAttrTitle {
                  display: flex;
                  gap: 1.2rem;
                  align-items: center;
                  width: 100%;
                  > div {
                    min-width: 120px;
                  }
                }
                th,
                td {
                  text-align: left;
                  border: 0;
                  padding-bottom: 0.6rem;
                }
                td {
                  padding: 0.6rem 4px;
                  border-bottom: 1px solid rgba(162, 162, 162, 0.1);
                  .param {
                    input {
                      min-width: 100px;
                    }
                    svg {
                      position: absolute;
                      top: 14px;
                      right: 8px;
                    }
                  }
                }
                th {
                  padding-top: 1.2rem;
                  border-bottom: 1px solid rgba(162, 162, 162, 0.1);
                }
                td:first-of-type {
                  width: 20px;
                }

                // td:last-of-type {
                //   width: 100%;
                // }
                td:first-of-type,
                td:last-of-type {
                  padding-left: 1.2rem;
                }
                input {
                  background-color: white;
                  padding: 22px 30px 8px 10px;
                }
                .wrap-sub-attr {
                  display: flex;
                  gap: 1.2rem;
                  align-items: center;
                  width: 100%;
                  > div {
                    position: relative;
                    min-width: 120px;
                  }
                  .wrap-add {
                    width: unset;
                    min-width: unset;
                  }
                  .param {
                    svg {
                      position: absolute;
                      top: 4px;
                      right: 4px;
                    }
                  }
                }
              }
            }
            .show-more {
              text-align: center;
              color: $blue;
            }
          }
          .open {
            height: 100%;
            padding-bottom: 1.2rem;
            border-top: 1px solid rgba(162, 162, 162, 0.1);
            box-shadow: inset 0 0 20px rgba(162, 162, 162, 0.1);
          }
        }
      }
    }
    .price {
      table {
        width: calc(100% - 30px);
        margin-right: 0px;
        margin-left: auto;
        tr {
          position: relative;
        }
        .drag-th {
          width: 20px;
        }
        .drag {
          position: absolute;
          cursor: grabbing;
          width: 20px !important;
          height: 76px;
          top: 10px;
          padding: 0 10px;
          left: -30px;
          svg {
            margin-top: 28px;
          }
          &.dragging {
            width: 100% !important;
          }
        }
        td {
          width: 25%;
          input:not(.rate) {
            padding: 15px 10px 15px 10px;
          }
          .select {
            > div {
              align-items: center;
            }
          }
          .choose-rate,
          .rateType {
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            top: calc(50% + (0.6rem));
            transform: translateY(-50%);
            left: 30px;
          }
          .rateType {
            width: 66px;
            align-items: flex-start;
            justify-content: flex-start;
          }
          .choose-rate {
            top: calc(50% + (0.6rem));
            gap: 4px;
            .selected {
              background-color: $blue;
              color: white;
            }
            > div {
              border-radius: 4px;
              width: 22px;
              height: 22px;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          input.rate {
            padding: 15px 10px 15px 50px;
          }
        }
      }
    }
  }
}
