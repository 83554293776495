@import "src/colorVariables.scss";

@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap");
$LibreBasker: "Libre Baskerville";

.breadcrumbs-container {
  img {
    filter: invert(1) !important;
  }
}

.breadcrumbs-container {
  display: flex;

  margin: auto;
  margin-top: auto;
  max-width: 1440px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 50px;

  .breadcrumbs-logo {
    filter: brightness(0);
    width: 24px;
    height: 24px;

    img {
      display: block;
      width: 100%;
      height: 100%;
      filter: opacity(0.759);
    }
  }

  .breadcrumb-link {
    display: flex;
    align-items: center;
    .breadcrumbs-arrow-container {
      height: 26px;
      margin: 0 8px;
      img {
        display: block;
        height: 100%;
        width: 100%;
        fill: black;
        filter: opacity(0.759);
      }
    }
    .breadcrumb-text {
      width: max-content;
      // height: 19px;
      color: $dark;
      font-family: $LibreBasker;
      font-size: 15px;
      font-style: italic;
      letter-spacing: 0;
      line-height: 19px;
    }
  }

  .breadcrumb-link:hover {
    text-decoration: underline;
    text-decoration-color: $dark;
  }
}

.display.display-active {
  .breadcrumbs-container {
    max-width: 1360px !important;
  }
}
