.mediaCenter {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 25px;

	.addMedia {
		display: flex;
		flex-direction: row;
		gap: 25px;
		width: 100%;
		
		.uploadContainer{
			width: 150px;
		}
		.addLocalContainer {
			position: relative;
			display: flex;
			align-items: end;

			.addLocalButton {
				cursor: pointer;
				width: 50px;
				height: 50px;
				font-size: 20px;
				color: #777;
				border: 2px solid #f5f5f5;
				display: flex;
				align-items: center;
				justify-content: center;
				&:hover{
					background-color: #f5f5f5;
				}
			}
		}

		.mediaListOverlay {
			overflow-y: scroll;
			background-color: #fff;
			position: relative;
			box-shadow: 0 0 25px rgba(0, 0, 0, .2);
			font-size: 15px;
			font-weight: 400;
			height: calc(100% - 40px);
			width: calc(100% - 40px);
			margin-top: 20px;
			margin-left: 20px;

			.closeOverlayBtn {
				cursor: pointer;
				display: inline-block;
				position: absolute;
				top: 15px;
				right: 15px;
				color: #777;
				font-size: 25px;
			}

			.listOverlayInner {
				padding-top: 50px;
				background-color: white;
				display: flex
			}
		}
	}

	.galleryContainer{
		margin-top: 45px;
		.mediaGallery{
			width: 100%;
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(150px, 200px));
			gap: 25px;
			
			.galleryItemContainer{
				position: relative;
				box-shadow: 5px 5px 15px 5px #0000001f;
				.imageCP{
					position: absolute;
					display: flex;
					width: 100%;
					padding: 10px;
					background-color: #fff4;
					flex-direction: row;
					justify-content: end;
					z-index: 1;
					.CPButton{
						cursor: pointer;
						font-size: 1.1em;
						color: #f64e60;
					}
				}
				.imageContainer{
					aspect-ratio: 1;
					cursor: pointer;
					:hover{
						opacity: 0.7;
					}
					img{
						height: 100%; 
						width:100%;
						object-fit: cover;
					}
				}
			}
			.previewContainer{
				background-color: white;
				width: 90%;
				margin: 50px auto;
				.previewCP{
					display: flex;
					justify-content: end;
					padding: 10px;
					font-size: 2em;
					color: #aaa;
					.closeButton{
						cursor: pointer;
					}
				}
				.previewImageContainer{
					margin-top: 25px;
					padding: 30px;
					.previewImage{
						width: 100%;
						box-shadow: 5px 5px 15px 5px #0000001f;
					}
				}
			}
		}
	}
}

.mediaContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 25px 25px 0 25px;
	gap: 25px;

	.header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		.headline {
			margin: 0;
		}
	}

	.uploadSection{
		display: flex;
		flex-direction: column;

		.uploadTopCP{
			display: flex;
			flex-direction: row;
			justify-content: end;
			padding: 10px;
			.closeUploadBtn{
				cursor: pointer;
				color: #777;
				font-size: 25px;
			}
		}
	}

	.controlPanel {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		.search {
			position: relative;
			width: 300px;
			input.searchInput {
				padding: 10px 50px 10px 15px;
				background-color: #a2a2a20d;
				border-radius: 5px;
				width: 100%;
				height: 40px;
				border: 1px solid #a2a2a25d;
			}

			.searchIcon {
				position: absolute;
				height: 20px;
				top: 10px;
				right: 15px;
				color: #ccc;
			}
		}

		.checkedCPSection {
			display: flex;
			gap: 15px;
			flex-direction: row;
			align-items: center;
		}
	}

	.list {
		.mediaListTable {
			width: 100%;
			input[type=checkbox]{
				display: inline;
			}
			width: 100%;
			th {
				cursor: pointer;
				color: #a2a2a2;
				font-size: 14px;
				font-weight: 600;

				&.sortActive {
					color: #000;
				}

				.thContent {
					display: flex;
					flex-direction: row;
					justify-content: start;
					gap: 15px;
				}
			}

			tr.mediaItemRow {
				&:hover {
					background-color: #f5f5f5;
				}

				td {
					height: 80px;
					cursor: pointer;
					padding-right: 45px;
					border: none;

					&.authorCell {
						width: 120px;
					}

					&.locationsCell {
						width: 120px;
					}

					&.sizeCell {
						width: 120px;
						text-align: center;

						.imageDimensions {
							color: #aaa;
							white-space: nowrap;
						}
					}

					&.dateCell {
						width: 140px;
						white-space: nowrap;
						text-align: center;

						.shortDate {
							color: #aaa;
						}
					}

					&.checkboxCell {
						max-width: 30px;
					}

					.fileContainer {
						height: 80px;
						display: flex;
						flex-direction: row;
						gap: 25px;
						align-items: center;

						.imageContainer {
							height: 100%;
							width: 100px;
							overflow: hidden;
							display: inline-flex;
							justify-content: center;

							img {
								height: 100%;
							}
						}
					}
				}
			}

			.mediaItemCard {
				height: 750px;
				display: flex;
				flex-direction: column;
				width: 100%;

				.mediaItemHeader {
					display: flex;
					flex-direction: row;
					justify-content: end;
					gap: 25px;
					padding: 25px 45px 0 0;
					height: 100px;

					.mediaItemCPBtn {
						font-size: 20px;
						cursor: pointer;
						color: #555;
					}
				}

				.mediaItemMain {
					display: flex;
					flex-direction: row;
					flex-grow: 1;

					.mediaItemExample {
						display: flex;
						flex-direction: column;
						width: 60%;

						.mediaItem {
							background-color: #f5f5f5;
							height: 400px;
							display: flex;
							justify-content: center;

							img {
								object-fit: cover;
								height: 100%;
							}
						}

						.mediaItemInfo {
							height: 100px;
							display: flex;
							flex-direction: row;
							justify-content: space-around;
							align-items: center;
							color: #777;

							div {
								text-align: center;
							}
						}

						.mediaItemCP {
							height: 100px;
							display: flex;
							flex-direction: row;
							justify-content: center;
							align-items: center;
							gap: 75px;
							font-size: 35px;
							color: #999;

							.mediaBtn {
								cursor: pointer;

								&:hover {
									color: #555;
								}
							}
						}
					}

					.mediaItemMeta {
						flex-grow: 1;
						padding: 0 0 50px 35px;
						display: flex;
						flex-direction: column;
						justify-content: space-between;

						.mediaMetaFields {
							display: flex;
							flex-direction: column;
							justify-content: start;
							gap: 50px;
						}

						.mediaMetaButtons {
							display: flex;
							flex-direction: row;
							justify-content: end;
							gap: 25px;
						}
					}
				}
			}

			.showMoreContainer {
				cursor: pointer;

				&:hover {
					background-color: #f5f5f5;
					text-decoration: underline;
				}

				display: flex;
				justify-content: center;
				align-items: start;
				padding: 15px 0 50px 0;
				color: #009fff;
				font-size: 18px;
			}
		}
	}
}

.uploader {
	width: 100%;

	.dropzone {
		cursor: pointer;
		:hover {
			background-color: #f5f5f5;
		}

		.uploadBox {
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			flex-direction: column;
			height: 150px;
			width: 100%;
			border: 2px solid #f5f5f5;
			padding: 20px;

			&.dragOver {
				background-color: #f5f5f5;
			}
		}
	}
}