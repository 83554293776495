@import "src/colorVariables.scss";
.default-popup {
  width: 340px;
  padding: 20px;
  background-color: white;
  .info {
    text-align: left;
    margin: 10px 40px 30px 0;
  }
  .bottom-buttons {
    display: flex;
    justify-content: space-evenly;
    gap: 10px;
    .button {
      flex: 1 1 0;
      white-space: normal;
      padding: 5px 10px;
    }
    // .button-cancel {
    // }
    .button-ok {
      color: white;
      background-color: $blue;
    }
  }
}
