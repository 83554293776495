@import "src/colorVariables.scss";

.home-content,
.overlay {
  height: 100%;

  .home-display {
    overflow-y: auto;
    width: 100%;
    height: 100%;
    position: relative;

    .header {
      height: 30%;
      width: 100%;
      position: relative;
      .Options_btn {
        position: absolute;
        top: 15%;
        right: 5%;
        border: 1px solid $light;
        border-radius: 20px;
        text-align: center;
        font-weight: 500;
        font-size: 14px;
        padding: 5px 15px;
        cursor: pointer;

        svg {
          margin-left: 10px;
        }
      }

      .extended {
        background-color: $dark;
        color: $light;
      }
      .home-headline {
        position: absolute;
        top: 30%;
        width: 100%;
        text-align: center;
        h1 {
          font-size: 50px;
          color: $dark;
          font-weight: bold;
        }
        p {
          font-size: 18px;
          color: $dark;
          font-weight: 400;
        }
      }
    }

    .home-buttons {
      margin: 0 20%;
      display: block;
      margin-bottom: 20px;
      min-height: 50%;

      .create-new-page {
        width: 30%;
        height: 150px;
        color: $dark;
        margin-right: 5%;
        cursor: pointer;
        display: inline-block;
        background-color: white;
        position: relative;
        .wrap-in-button {
          position: absolute;
          top: 0;
          height: 100%;
          width: 100%;
          text-align: center;
          p {
            font-size: 17px;
            line-height: 22px;
            font-weight: bold;
          }
          .home-icon {
            height: 56px;
            width: 60px;
            margin: 15px auto;
            position: relative;
            svg {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-size: 40px;
            }
          }
        }
      }
      .create-new-page:nth-child(3n) {
        margin-right: 0;
      }
      .create-new-page.dragging {
        opacity: 0;
        cursor: move;
      }

      .create-new-page.dragging-on {
        background-color: $light;
      }

      .Submits .popUp {
        background-color: $dark-blue;
        position: absolute;
        top: 4px;
        right: 10px;
        width: 45%;
        margin: 0;
        height: 25%;
        border-radius: 40%;
        font-size: 11px;
        color: white;
        // transform: translate(-50%);
        text-align: center;
      }
    }

    .SortBtn {
      background-color: $dark;
      color: white;
      border-radius: 20px;
      padding: 5px 0px;
      font-weight: 500;
      font-size: 14px;
      width: 143px;
      text-align: center;
      //   margin: 4% 1% 4% 4%;
      position: fixed;
      bottom: 50px;
      margin-left: 50px;
      cursor: pointer;
      //   float: left;
      // position: absolute;
      // bottom: 0;
      // left: 0;

      svg {
        margin-left: 8px;
      }
    }
    .SortBtn.choosen {
      border: 1px solid $mid;
      color: black;
      background-color: white;
    }

    .okBtn {
      width: 50px;
      position: fixed;
      bottom: 50px;
      margin-left: 200px;
      border-radius: 20px;
      background-color: $blue;
      padding: 5px 0px;
      text-align: center;
      color: white;
      cursor: pointer;
    }
  }
}
