.container {
  padding: 20px;

  width: 340px;
  height: 181px;

  // background-color: #fff;
  // box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  gap: 10px;

  p {
    padding-left: 10px;

    width: 260px;

    color: #333;

    font-size: 16px;
    line-height: 20px;

    text-align: left;
  }

  .buttons {
    padding-top: 10px;

    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 14px;

    button {
      width: 132px;
      height: 30px;

      font-size: 12px;
      line-height: 15px;

      letter-spacing: 1px;

      border-radius: 2px;

      &.cancel {
        background-color: rgba(162, 162, 162, 0.1);

        color: #333;
      }

      &.confirm {
        background-color: #cf323b;

        color: #fff;
      }
    }
  }
}
