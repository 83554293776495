.modalContainer{
	height: 100vh;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	.modal{
		background-color: white;
		width: 450px;
		.header{
			padding: 10px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			background-color: #009fff;
			color: white;
			font-size: 25px;
			.titleContainer{
				flex-grow: 1;
			}
			.closeButton{
				cursor: pointer;
			}
			.preloadContainer{
			}
		}
		.content{
			padding: 25px;
			font-size: 20px;
		}
		.controls{
			display: flex;
			flex-direction: row;
			justify-content: end;
			gap: 15px;
			margin-top: 25px;
			padding: 10px;
		}
	}

}