@import "src/colorVariables.scss";
.edit-page {
  height: 100%;
  overflow: auto;
  background-color: #f4f4f5;
  .loading {
    position: relative;
    pointer-events: none;
    opacity: 0.5;
    z-index: 999999;
  }
  &.row {
    margin-left: 0;
    margin-right: 0;
    flex-wrap: nowrap;
  }
  .page-section {
    // background-color: red;
    padding-left: 30px;
    height: 100%;
    flex-basis: calc(100% - 420px);
    max-width: unset;
    display: flex;
    flex-direction: column;
    width: auto;
    .page-section-header {
      min-height: 100px;

      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .fixed {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        &.single {
          .one-language-wrap {
            justify-content: center;
          }
        }
        &.selected {
          border-bottom: 1px solid black;
          color: black;
        }
        &.disabled {
          pointer-events: none;
          opacity: 0.2;
        }
        &:hover {
          background-color: $light;
        }

        .header-title {
          padding-top: 10px;

          font-weight: 600;
          font-size: 22px;
          margin-right: 50px;
          cursor: pointer;
          transition: ease-in-out 0.2s;

          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          gap: 15px;

          &:hover {
            color: $blue;
          }

          .title-text {
            font-weight: bold;
          }
        }
        .one-language,
        .add-language {
          width: 70px;
          height: 50px;
          // padding: 15px 25px;
          margin: 10px;
          border-bottom: 1px solid $mid;
          color: $mid;
          font-size: 14px;
          transition: ease-in-out 0.2s;
          user-select: none;
          display: flex;
          align-items: center;
          justify-content: center;
          &.selected {
            border-bottom: 1px solid black;
            color: black;
          }
          &.disabled {
            pointer-events: none;
            opacity: 0.2;
          }
          &:hover {
            background-color: $light;
          }
          .one-language-wrap {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            width: 100%;
            .the-language,
            .trash {
              padding: 4px 8px;
              border-radius: 2px;
              cursor: pointer;
              transition: ease-out 0.05s;

              &:hover {
                background-color: rgba($color: #ffffff, $alpha: 1);
                &.trash {
                  color: $red;
                }
              }
            }
            .trash {
              color: $mid;
            }
          }
        }
        .one-language {
        }
        .add-language {
          cursor: pointer;
        }
      }
    }
    .page-section-content {
      height: calc(100% - 120px);
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.11);
      align-self: center;
      width: 100%;
      position: relative;
      transition: ease-in-out 0.5s;
      .blur {
        position: absolute;
        display: grid;
        place-items: center;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 11;
        font-size: 24px;
        svg {
          color: white;
        }
      }
      .spacerDiv {
        height: 150px;
      }
      &.fullscreen {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        height: 100%;
        background-color: white;
        z-index: 1;

        & .page-content-top {
          background-color: $lighter;
        }
      }

      &.tablet {
        width: 768px;
        height: calc(1024px + 50px);
      }

      // &.phone{
      //     // width: 375px;
      //     // height: calc(667px + 50px);
      // }

      &.custom {
        width: unset;
      }
      .scale-wrap {
        display: flex;
        align-items: center;
        position: absolute;
        right: 0;
        top: -44px;
        .display-scale {
          input {
            padding: 5px;
            width: 75px;
          }
        }
      }

      .react-resizable-handle {
        position: absolute;
        bottom: 20px;
        right: 20px;
        width: 20px;
        height: 20px;
        border-right: 2px solid black;
        border-bottom: 2px solid black;
        opacity: 0.2;
        transition: ease-out 0.2s;
        cursor: nwse-resize;
        &:hover {
          opacity: 1;
        }
      }

      .page-content {
        line-height: 33px;
        background-color: white;
        .wrap-header-menu {
          width: 100%;
          position: sticky;
          z-index: 10;
          top: 0;
          &.hidden {
            display: none;
          }
          .header-menu {
            z-index: 1;
            width: 100%;
            padding: 0 30px;
            display: flex;
            align-items: center;
            transition: ease-in-out 0.5s;
            background-color: white;
            background-image: linear-gradient(transparent, transparent);
            box-shadow: 0 6px 10px rgba(55, 55, 55, 0.08);
            .logo {
              display: flex;
              align-items: center;
              cursor: pointer;
              .sheephead {
                width: 64px;
                img {
                  width: 100%;
                  transition: ease-out 0.5s;
                }
              }
              .text {
                font-weight: 700;
                font-size: 28px;
                line-height: 28px;
                padding-left: 12px;
                transition: ease-out 0.5s;
              }
            }
            .top-menu {
              display: flex;
              align-items: center;
              justify-content: space-evenly;
              // max-width: 800px;
              flex-grow: 1;
              .eachMenu {
                cursor: pointer;
                display: flex;
                font-family: "Source Sans Pro";
                font-weight: bold;
                align-items: center;
                text-transform: uppercase;
                flex-direction: column;
                transition: ease-out 0.5s;
                letter-spacing: 2.27px;

                .hoverMenu {
                  height: 70px;
                  display: flex;
                  align-items: center;
                }
                .hoverMenu:hover ~ .wrap {
                  display: block;
                }

                .wrap:hover {
                  display: block;
                }
                .wrap {
                  display: none;
                  width: 100%;
                }
                .indicator {
                  position: absolute;
                  bottom: -6px;
                  z-index: 1;
                  border-left: 20px solid transparent;
                  border-right: 20px solid transparent;
                  border-bottom: 20px solid white;
                  width: 0;
                  height: 0;
                }
                .dropdownMenu {
                  position: absolute;
                  top: 70px;
                  background-color: white;
                  width: 100%;
                  left: 0;
                  box-shadow: 0 0 20px rgb(55 55 55 / 20%);
                  text-shadow: none;
                  color: black;
                  padding: 10px;
                  z-index: 0;
                  .second-level {
                    display: flex;
                    flex-wrap: wrap;
                    .indicator {
                      height: 40px;
                      width: 40px;
                      background-color: white;
                      transform: rotate(45deg);
                    }
                    .each-second-level {
                      max-width: 275px;
                      min-width: 175px;
                      margin-bottom: 40px;
                      margin-right: 30px;
                      font-size: 1em;
                      font-weight: bold;
                    }
                    .third-level {
                      // margin-top: 12px;
                      text-transform: capitalize;
                      display: flex;
                      font-family: "Libre Baskerville";
                      font-style: italic;
                      font-weight: normal;
                      cursor: pointer;
                      flex-direction: column;
                      font-size: 0.8em;
                      line-height: 2em;
                      span {
                        display: flex;
                        .icon {
                          margin-right: 18px;
                          color: $blue;
                        }
                      }
                    }
                  }
                }
              }
              .eachMenu:nth-child(2) {
                .second-level {
                  .indicator {
                    margin-left: 200px;
                  }
                }
              }
              .eachMenu:nth-child(3) {
                .second-level {
                  .indicator {
                    margin-left: 385px;
                  }
                }
              }
              .eachMenu:nth-child(4) {
                .second-level {
                  .indicator {
                    margin-left: 565px;
                  }
                }
              }
            }
            &.not-top {
              background-color: white;
              background-image: linear-gradient(transparent, transparent);
              box-shadow: 0 6px 10px rgba(55, 55, 55, 0.08);
              .logo {
                .sheephead {
                  img {
                    filter: none;
                  }
                }
                .text {
                  color: black;
                }
              }
              .eachMenu {
                color: black;
                text-shadow: none;
              }
            }
          }
        }
        .footer-menu {
          padding-top: 120px;
          padding-bottom: 55px;
          .footer-top {
            height: 150px;
            background-color: #f4f5f7;

            display: flex;
            justify-content: center;
            align-items: center;
            span {
              font-size: 2rem;
              font-weight: 700;
            }
          }
          .footer-bottom {
            height: 288px;
          }
        }
        .vfi-text-area {
          p {
            margin-top: 0;
            margin-bottom: 0;
          }
          ul {
            padding: 1px 1px 1px 8px;
            margin-left: 12px;
            li::before {
              padding: 3px 0px 0 0px;
            }
            li {
              padding-left: 0;
              margin-bottom: 15px;
            }
          }
          ol {
            list-style: none;
            padding-left: 0;
            li {
              counter-increment: step-counter;
              margin-bottom: 20px;
              min-height: 60px;
            }
            li:last-of-type {
              margin-bottom: 33px;
            }
            li::before {
              content: counter(step-counter);
              margin-right: 5px;
              font-size: 60px;
              font-weight: bold;
              float: left;
              height: 60px;
              font-family: "Libre Baskerville";
              line-height: 72px;
            }
          }

          .ck-content {
            a {
              span:only-child {
                color: unset !important;
              }
            }
          }
        }
        blockquote {
          margin-top: 60px;
          border-left: none;
          padding: 0;
          font-family: "Libre Baskerville";
          font-style: italic;
          &::before {
            content: "“";
            font-size: 109px;
            position: absolute;
            margin-top: -10px;
            color: $blue;
            font-style: normal;
          }
          & p {
            font-size: 27px;
            color: $text-color;
            font-family: "Libre Baskerville";
            font-size: 27px;
            letter-spacing: 0.3px;
            line-height: 33px;
            margin-bottom: 10px !important;

            &:last-of-type {
              font-size: 20px;
              color: $mid;
              margin-top: 10px;
            }
            &:first-of-type {
              font-size: 27px;
              color: #373737;
            }
          }
        }
      }
      .page-content-top {
        padding: 10px;
        min-height: 50px;
        background-color: white;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
        justify-content: center;
        .edit-section {
          flex-wrap: wrap;
          justify-content: center;
          display: flex;
          & > div,
          & > button {
            display: inline-block;
            margin-left: 10px;
            padding: 5px 10px;
            font-size: 14px;
            font-weight: 600;
            border-radius: 2px;
            cursor: pointer;
            transition: ease-in-out 0.2s;
            white-space: nowrap;
            background-color: white;
            border-width: 0;
            &:hover {
              background-color: rgba($color: #000000, $alpha: 0.1);
            }
            &.disabled {
              visibility: hidden;
            }
          }
          .discard-button {
            color: $red;
          }
        }
        .headline-text {
          flex-grow: 1;
          text-align: center;
          font-size: 16px;
        }
        .wrap-device-buttons {
          display: flex;
        }
        .device-display-button,
        .expand-button {
          width: 24px;
          text-align: center;
          cursor: pointer;
          border-radius: 2px;
          transition: ease-in-out 0.2s;
          padding-top: 2px;
          flex-shrink: 0;
          &:hover {
            background-color: rgba($color: #000000, $alpha: 0.1);
          }
          &.active {
            background-color: $dark;
            color: white;
          }
          &.text {
            width: unset;
            padding-left: 5px;
            padding-right: 5px;
            user-select: none;
            &.disabled {
              color: $mid;
              pointer-events: none;
            }
          }
          .fa-tablet {
            transform: rotate(-90deg);
          }
        }
        .device-display-button {
          margin: 0 10px;
          &.custom {
            width: unset;
            padding: 0 5px;
            user-select: none;
          }
        }
        .expand-button {
          margin: 0 10px;
        }
      }
      ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
        z-index: 10;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background-color: rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
      .page-content {
        height: calc(100% - 52px);
        overflow: auto;

        .ckeditor_5-text {
          h1 {
            text-transform: uppercase;
          }
        }

        &.disabled {
          .module {
            pointer-events: none;
            .vfi-div-input {
              pointer-events: none;
            }
            .module-options,
            .module-options-2 {
              opacity: 0;
            }

            .image-upload-block {
              .pen {
                opacity: 0;
              }
            }
            .ck-content {
              background-color: transparent;
            }
            input {
              background-color: transparent;
            }
            .one-media-with-subtext {
              .pen {
                opacity: 0;
              }
            }

            .add-module-above,
            .add-module-below,
            .module-options-2 {
              display: none;
            }
            .middle-top,
            .link-to {
              opacity: 0;
            }
            .download-module-options {
              display: none;
            }
          }

          .visit-faroe-islands-top {
            .link-options,
            .link-options-2 {
              display: none;
            }
            .new-slide,
            .remove-page,
            .add-media,
            .is-hero {
              opacity: 0;
            }
          }

          .add-module {
            display: none;
          }

          .vfi-carousel,
          .media-grid,
          .media-grid-related {
            .info {
              background-color: transparent;
              .button-check-wrap {
                display: none;
              }
              .button-direct-wrap {
                display: none;
              }
            }
            .add-media,
            .choose-template,
            .remove-media,
            .is-hero,
            .pen,
            .type-icon {
              display: none;
            }

            .medium-text {
              // display: none;
              input::placeholder {
                opacity: 0;
              }
            }
          }

          .whatson-module {
            .select-wrap {
              display: none;
            }
          }

          .vfi-div-input {
            background-color: transparent;
          }
        }
        .wrap-module {
          width: 100%;
        }
        .backgroundColor0 {
          display: flow-root;
          background-color: $background-color1;
          .newsletter {
            background-color: $background-color2;
          }
        }
        .backgroundColor1 {
          display: flow-root;
          background-color: $background-color2;
          .newsletter {
            background-color: $background-color1;
          }
          .download-module {
            .middle-diamond {
              background-color: $background-color2;
            }
          }
        }
        .backgroundColor3 {
          display: flow-root;
          background-color: transparent;
        }
        .backgroundColor2 {
          background-color: $background-color3;
          display: flow-root;
          .dropdownMenu {
            background-color: $background-color3 !important;
            box-shadow: 0 0 20px rgb(255, 255, 255, 0.2) !important;
          }
          .header-menu {
            background-color: $background-color3 !important;
            box-shadow: 0 6px 10px rgb(255, 255, 255, 0.08) !important;
            .indicator {
              border-bottom: 20px solid $background-color3 !important;
            }
            .logo {
              .text {
                color: white !important;
              }
            }
          }
          .newsletter {
            background-color: #424242;
          }
          .download-module {
            .middle-diamond {
              background-color: $background-color3;
            }
          }
          .social-module {
            .icon {
              border: 1px solid rgba($color: #ffffff, $alpha: 0.8);
              border-radius: 38px;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                filter: invert(1);
              }
            }
          }

          p,
          h1,
          h2,
          h3,
          div,
          blockquote,
          h4:not(.large_headline),
          .text,
          input[type="text"] {
            color: white;
            p {
              color: white;
            }
            &.vfi-div-input {
              background-color: #424242;
            }
          }
          hr {
            border-top: 1px solid rgba(255, 255, 255, 0.1);
          }
          .expand-vertical {
            .expand-vertical-content,
            .expand-vertical-button {
              background-color: #424242;
              .expand-vertical-shadow {
                background-image: linear-gradient(transparent, #424242);
              }
            }
          }
          .footer-top {
            color: $dark;

            input,
            div,
            p {
              color: $dark;
            }
          }
        }
        .module {
          position: relative;
          // max-width: 1440px;
          margin: auto;
          // margin-bottom: 35px;
          font-size: 20px;
          color: $text-color;
          margin: 0 auto;
          &.firstCarousel {
            margin-top: 0;
            .vfi-carousel {
              margin-top: 0 !important;
            }
          }

          // &.carousel {
          //   margin-bottom: 25px;
          // }

          .add-module-above,
          .add-module-below {
            position: absolute;
            width: 40px;
            height: 40px;
            border-radius: 20px;
            font-size: 21px;
            background-color: black;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            left: 50%;
            cursor: pointer;
            visibility: hidden;
            opacity: 0;
            transition: ease-in-out 0.2s;
            z-index: 100;
          }

          .add-module-above {
            transform: translate(-50%, -50%);
            top: 0;
            z-index: 100;
          }

          .add-module-below {
            bottom: 0;
            transform: translate(-50%, 50%);
          }

          &:hover {
            .add-module-above,
            .add-module-below {
              visibility: visible;
              opacity: 1;
              z-index: 100;
            }
            box-shadow: inset 0px 0px 0px 3px #373737;
            // border: 3px solid #373737
            .module-options-2 {
              opacity: 1;
            }
            .default-module-settings-buttons {
              opacity: 1;
            }
          }

          &.disable-maxwidth {
            max-width: unset !important;
          }

          &.maxwidth-1440 {
            max-width: 1440px;
          }

          h1,
          h2,
          h3 {
            font-weight: 700;

            margin-top: 20px;
            margin-bottom: 15px;
          }

          h1 {
            font-size: 42px;
            letter-spacing: 5.3px;
            line-height: 52px;
          }
          h2 {
            font-size: 26px;
            letter-spacing: 0.5px;
            line-height: 33px;
          }
          h3 {
            font-size: 20px;
            letter-spacing: 0.5px;
            line-height: 25px;

            margin-bottom: 20px;
          }

          .module-options {
            // display: none;
            position: absolute;
            right: 20px;
            top: 20px;
            width: 28px;
            height: 28px;
            padding: 4px;
            cursor: pointer;
            z-index: 2;
            border-radius: 2px;
            color: rgba($color: #000000, $alpha: 0.5);
            transition: ease-in-out 0.2s;
            &:hover {
              background-color: $light;
              color: rgba($color: #000000, $alpha: 1);
            }
          }

          .module-options-2 {
            // display: none;
            position: absolute;
            right: 20px;
            top: 20px;
            // width: 28px;
            height: 40px;
            padding: 4px 0 0 0;
            z-index: 4;
            border-radius: 2px;
            transition: ease-in-out 0.2s;
            display: flex;
            box-shadow: 0 0 20px rgba($color: #000000, $alpha: 0.1);
            opacity: 0;
            background-color: rgba($color: #ffffff, $alpha: 0.5);
            .module-option {
              width: 32px;
              height: 32px;
              font-size: 17px;
              display: grid;
              place-items: center;
              margin: 0px 5px;
              transition: ease-in-out 0.2s;
              border-radius: 2px;
              cursor: pointer;
              &:hover {
                background-color: rgba($color: #000000, $alpha: 0.1);
                color: rgba($color: #000000, $alpha: 1);
              }
              &.inactive {
                color: $mid;
                pointer-events: none;
                display: none;
              }
            }
          }

          .vfi-ckeditor {
            margin: auto;
            width: 724px;
            > div {
              // margin-left: -100px;
              width: 100%;
            }
          }

          .ck-content {
            overflow: hidden;
            height: 100%;
            background-color: rgba($color: $mid, $alpha: 0.1);
            // p {
            //   margin-bottom: 15px;
            // }
          }

          .vfi-carousel {
            &.slider_default,
            &.slider_with_thumb,
            &.wall_grid_2,
            &.wall_grid_3,
            &.flex_view {
              max-width: 1440px;
              margin: auto;
              // margin-top: 25px;
              // margin-bottom: 25px;
              position: relative;
            }

            .wall_grid_2,
            &.wall_grid_3 {
              max-width: 1200px;
            }
            &.flex_view {
              max-width: 1206px;
            }
            &.slider_carousel {
              padding-bottom: 4px;
              overflow: hidden;
              .carousel-wrap {
                max-width: 1440px;
                margin: auto;
              }
            }
            &.slider_with_thumb,
            &.slider_carousel {
              .info {
                // bottom: 85px;
              }
            }
          }

          .is-excerpt {
            color: $text-color;
            font-family: "Libre Baskerville";
            font-size: 21px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 36px;

            margin-top: 20px;
            margin-bottom: 20px;
            p {
              margin-bottom: 15px;
            }
          }

          &.a-media {
            .caption {
              font-size: 14px;
              .ck-content {
                background-color: transparent;
                p {
                  margin: 0;
                  line-height: 20px;
                }
              }
            }
          }
        }

        .height-hundred {
          height: 100%;
          @media ($smallDesktop) {
            height: 850px;
            max-height: 100vh;
          }

          // margin-bottom: 20px;
        }

        .full-width {
          max-width: unset !important;
        }

        .codex-editor__redactor {
          padding-bottom: 50px !important;
        }

        .add-module {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: auto;
          margin-top: 100px;
          width: 40px;
          height: 40px;
          border-radius: 20px;
          background-color: $dark;
          font-size: 21px;
          svg {
            color: white;
          }
        }
      }
    }
  }
  .options-section {
    height: 100%;
    max-width: 350px;
    margin-left: 10px;
    flex-basis: unset;
    .options-section-header {
      height: 100px;
      display: flex;
      align-items: center;
      .option {
        flex-grow: 1;
        height: 50px;
        position: relative;
        border-bottom: 1px solid $mid;
        color: $mid;
        margin: 0 5px;
        transition: ease-in-out 0.2s;
        user-select: none;
        cursor: pointer;
        white-space: nowrap;
        font-size: 14px;
        &.selected {
          color: black;
          border-bottom: 1px solid black;
        }
        > div {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        svg {
          font-size: 10px;
          padding-bottom: 2px;
          color: $red;
        }
      }
    }
    .option-section-content {
      height: calc(100% - 100px);
      display: flex;
      flex-direction: column;
      .content {
        &.disabled {
          opacity: 0.25;
          pointer-events: none;
        }
        width: unset;
        overflow: auto;
        background-color: transparent;

        .slug-wrap {
          display: flex;
          align-items: center;
          svg {
            color: $mid;
            font-size: 14px;
          }
          .slug-input {
            input {
              background-color: transparent;
              padding-left: 0;
              // padding-top: 5px;
              // padding-bottom: 5px;
            }
          }
        }

        .vfi-text-area {
          textarea {
            height: 70px;
          }
        }

        .encapsulate {
          position: relative;
          cursor: pointer;
          width: 100%;
          transition: ease-out 0.2s;
          user-select: none;
          .text {
            margin: 5px 36px 14px 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .arrow,
          .radio {
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);

            &.transparent {
              opacity: 0.1;
            }
          }
          .date {
            margin-top: -14px;
          }

          &.underline {
            border-bottom: 1px solid rgba($color: $mid, $alpha: 0.1);
          }

          &.stat {
            padding-bottom: 10px;
            .text {
              margin-bottom: 0;
            }
          }

          &.share,
          &.location-on-map,
          &.infoscreens,
          &.relate-to-page {
            background-color: rgba(162, 162, 162, 0.2);
            margin-top: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            .text {
              margin: 8px;
              font-size: 14px;
            }
          }

          &.infoscreens,
          &.relate-to-page {
            margin-top: 10px;
          }

          &:hover {
            background-color: rgba($color: $mid, $alpha: 0.1);
          }
        }

        .hours,
        .minutes {
          width: 50px;
          height: 50px;
          display: inline-block;
          margin: 0 5px;
          .css-yk16xz-control {
            border: none;
            background-color: rgba(162, 162, 162, 0.05);
            border-radius: 0;
          }

          .css-1hb7zxy-IndicatorsContainer,
          .css-1wy0on6 {
            display: none;
          }

          .css-g1d714-ValueContainer {
            justify-content: center;
          }
        }
        .hours {
          margin-left: 0;
        }

        &.history {
          margin-bottom: 30px;
        }
      }
      .final {
        display: flex;
        .f-button {
          width: 90px;
          height: 50px;
          margin: 30px 10px;
          border-radius: 2px;
          background-color: rgba($mid, 0.3);
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          user-select: none;
          cursor: pointer;
        }
        .publish-button {
          background-color: $blue;
          color: white;
          justify-content: space-evenly;
        }
      }
      // .general{
      //     display: flex;
      //     flex-direction: column;
      //     height: 100%;
      // }
      .option-title {
        font-size: 17px;
        font-weight: 700;
        margin: 10px 0px;

        &.go-back {
          width: fit-content;

          svg {
            margin-left: 5px;
            margin-right: 20px;
            font-size: 14px;
          }
          cursor: pointer;
        }
      }
      .small-label {
        font-size: 12px;
        color: $mid;
      }
      .title {
        margin-top: 15px;
        font-weight: 600;
      }
      .image-title {
        margin-top: 30px;
      }
      .locations-wrap {
        display: flex;
        flex-wrap: wrap;
        .location {
          margin-top: 10px;
          margin-right: 10px;
          background-color: $light;
          padding: 2px 15px;
          white-space: nowrap;
          border-radius: 100px;
          user-select: none;
          cursor: pointer;

          &.active {
            color: white;
            background-color: black;
          }
        }
      }

      .the-map-box {
        margin-top: 40px;
      }

      .feature-image-container {
        .image {
          height: 180px;

          background-color: $mid-between;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .no-image-wrap {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            cursor: pointer;
          }
        }
        .edit-image-container {
          display: flex;
          height: 40px;
          text-align: center;
          align-items: center;

          .edit-image,
          .delete-image {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            user-select: none;
            background-color: rgba($color: $mid, $alpha: 0.2);
            transition: ease-in-out 0.2s;
            &:hover {
              background-color: $mid;
              color: $light;
            }
          }
          .edit-image {
            flex-grow: 1;
            font-size: 14px;
            color: $dark;
          }
          .delete-image {
            width: 42px;
            margin-left: 1px;
            color: $dark;
          }
        }
      }
      .analytics {
        // display: none;
        // background-color: white;
        .gapi-analytics-data-chart {
          .google-visualization-table-table {
            background-color: unset;
          }
        }
        .google-visualization-table-div-page {
          display: none;
        }
        h1 {
          font-size: 17px;
          font-weight: 700;
          margin: 10px 0px;
        }
        .analytics-auth-widgets {
          display: flex;
          justify-content: flex-end;
          .analytics-sign-out-button {
            border-width: 0;
            background-color: transparent;
            color: crimson;
            font-size: 12px;
            font-weight: bold;
            margin-top: -40px;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        .dates {
          display: flex;
          flex-direction: column;
          // align-items: flex-start;
          margin: 5px 2px;
          .from,
          .to {
            margin: 5px 15px 2px;
          }
        }
        .charts-wrapper > div {
          margin: 10px 0;
          max-width: 100%;
        }
      }
    }
  }
  .tablet,
  .phone {
    &.page-section-content {
      .page-content {
        .module:not(.carousel) {
          .social-module {
            .icons-container {
              margin-left: 12px;
            }
          }
          &.a-media {
            .one-media,
            .caption,
            .mediaDiv {
              .player-wrapper,
              .ck-content,
              .upload-box {
                margin-left: 12px;
              }
            }
          }
          .mediaDiv.large,
          .mediaDiv.full {
            .player-wrapper {
              margin-left: unset;
            }
          }

          .read-also {
            width: unset;
            .read-also-wrap {
              margin-left: 12px;
            }
          }
          .media-with-subtext-and-text {
            .media-text-content {
              max-width: unset;
              .one-media-with-subtext {
                // margin-left: -212px;
              }
              &.swapped {
                .one-media-with-subtext {
                  margin-right: -260px;
                  margin-left: unset;
                }
              }
            }
          }

          .related-and-text {
            .related-feature {
              margin-left: -78px;
            }
            .related-text {
              width: 100%;
              > div {
                margin: unset;
              }
            }
          }
          .vfi-ckeditor {
            width: 100%;
            > div {
              margin-left: 12px;
            }
          }
          .text-and-expand {
            .tae-second {
              max-width: unset;
              margin-left: unset;
            }
            &.swapped {
              .expand-vertical {
                margin-left: -124px;
              }
              .tae-second {
                margin-left: unset;
              }
            }
          }
        }
      }
    }
  }
  .phone,
  .tablet {
    &.page-section-content {
      .page-content {
        .module {
          &.a-media {
            .one-media,
            .caption,
            .mediaDiv {
              .player-wrapper,
              .ck-content,
              .upload-box {
                margin-left: unset !important;
              }
            }
          }
          .vfi-ckeditor {
            > div {
              margin-left: unset !important;
            }
          }
          > div:not(.default-module-buttons) {
          }
          .related-and-text {
            flex-direction: column;
            width: 100%;
            gap: unset;
            margin: auto;
            max-width: unset;
            .related-feature {
              margin-left: unset !important;
              .wrap-related-feature {
                margin-left: 10px;
                position: unset;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
              }
            }
          }
          .text-and-expand {
            margin-top: 20px;
            width: 100%;
            gap: unset;
          }
          .social-module {
            padding-left: 10px;
            width: 100%;
            max-width: unset;
            .icons-container {
              margin-left: unset;
            }
          }

          // .text-and-expand {
          //   .tae-second {
          //     margin-left: 112px;
          //   }
          //   &.swapped {
          //     flex-direction: column-reverse;
          //     .expand-vertical {
          //       margin-left: auto !important;
          //     }
          //     .tae-second {
          //       margin-left: 112px !important;
          //     }
          //   }
          // }
        }
      }
    }
  }

  .tablet {
    &.page-section-content {
      .page-content {
        .module {
          // .text-and-expand {
          //   width: 100%;
          //   gap: unset;
          // }
          // .social-module {
          //   padding-left: 10px;
          //   width: 100%;
          //   max-width: 724px;
          //   .icons-container {
          //     margin-left: unset;
          //   }
          // }

          .text-and-expand {
            .tae-second {
              margin-left: 112px;
            }
            &.swapped {
              flex-direction: column-reverse;
              .expand-vertical {
                margin-left: auto !important;
              }
              .tae-second {
                margin-left: 112px !important;
              }
            }
          }
        }
      }
    }
  }

  .phone {
    &.page-section-content {
      .page-content {
        .module {
          // .related-text{
          //   min-width: unset !important;
          //   margin-left: unset !important;
          //   width: 100% !important;
          // }
          .vfi-ckeditor {
            > div {
              margin-left: unset !important;
              width: 100% !important;
            }
          }
        }
      }

      // .text-and-expand {
      //   .tae-second {
      //     margin-left: 0 !important;
      //   }
      //   &.swapped {
      //     .expand-vertical {
      //       margin-left: 0 !important;
      //     }
      //     .vfi-text-area.tae-second {
      //       margin-left: 0 !important;
      //     }
      //   }
      // }

      .text-and-expand {
        .tae-second {
          margin-left: 0px !important;
        }
        &.swapped {
          flex-direction: column-reverse;
          .expand-vertical {
            margin-left: auto !important;
          }
          .tae-second {
            margin-left: 0px !important;
          }
        }
      }
    }
  }

  .form {
    // display: none;
    max-width: 1440px;
    margin: auto;
    text-align: center;
    height: 200px;
    background-color: $light;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 2rem;
    font-weight: 700;
    color: $mid;
  }
}
