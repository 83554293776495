@import "src/colorVariables.scss";
.react-datepicker {
  border-radius: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 0;
  position: relative;
  .react-datepicker__header {
    background-color: transparent;
    border: 0;
  }
  .react-datepicker__day-names {
    font-weight: bold;
  }
  .react-datepicker__day--selected {
    border-radius: 0;
    background-color: $blue;
  }
  .react-datepicker__navigation:focus {
    outline: 0;
  }
}
.filter-panel {
  width: 25%;
	min-width: 300px;
  z-index: 999 !important;
  background-color: $dark;
  overflow-y: auto;
	overflow: visible;

  .padding {
    height: 100%;
    width: 100%;
    padding: 8% 8% 0 8%;
    // > div {
    //     display: block;
    //     width: 100%;
    // }
  }

  .filterHeader {
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 38px;

    padding-bottom: 20px;

    border-bottom: 1px solid rgba(162, 162, 162, 0.1);

    .closeAndText {
      margin: 0;
      padding: 0;

      li {
        list-style-type: none;
        display: inline-block;
        color: $light;
      }
      .close {
        float: left;
        padding: 0;
        // width: 5%;
        // font-size: 170%;
        cursor: pointer;

        p {
          font-size: 28px;
          line-height: 29px;
          font-weight: 300;
          color: #ffffff;
          opacity: 0.98;

          margin: 0;
        }
      }

      .text {
        width: 95%;
        font-size: 15px;
        line-height: 19px;
        text-align: center;
        text-transform: uppercase;
        opacity: 0.98;
      }
    }

    .search-parent {
      display: inline-block;
      width: 100%;
      .search {
        position: relative;
        .right-icon {
          color: $mid;
        }

        input {
          background-color: rgba(162, 162, 162, 0.1);
          width: 100%;
          color: $mid;

          ::placeholder {
            font-size: 2px;
          }
        }
        .magnifying-glass {
          position: absolute;
          top: 45%;
          right: 12px;
          transform: translateY(-50%);
          pointer-events: none;
        }
      }
    }
    .search-parent.hidden {
      display: none;
    }
  }

  .filterDate {
    border-bottom: 1px solid rgb(61, 64, 53);
    margin-top: 5%;

    .dropAndText {
      width: 100%;
      padding: 0;
      margin: 0;
      margin-bottom: 4%;
      height: 20px;
      position: relative;

      .text {
        text-transform: uppercase;
        font-size: 12px;
        position: absolute;
        top: 0;
        color: $light;
      }

      .drop {
        color: $light;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        float: right;
        font-size: 20px;
        transform: rotate(0deg);
        transition: transform 0.4s;
        transform-origin: center center;
      }
      .drop.hidden {
        transform: rotate(180deg);
      }
    }
  }

  .filterSite {
    display: flex;
    flex-direction: column;

    .dropAndText {
      width: 100%;
      height: 15px;
      padding: 0;
      margin: 10px 0 14px 0;
      position: relative;

      .text {
        text-transform: uppercase;
        font-size: 12px;
        line-height: 15px;
        font-weight: 600;
        position: absolute;
        top: 0;
        color: $light;

        margin: 0;

        opacity: 0.98;
      }

      .drop {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        color: $light;
        font-size: 20px;
        transform: rotate(0deg);
        transition: transform 0.4s;
        transform-origin: center center;
      }
      .drop.hidden {
        transform: rotate(180deg);
        transform-origin: center center;
      }
    }
    .sites.hidden {
      display: none;
    }
    .sites {
      margin: 0;
      padding: 0;

      .checkbox {
        margin: 0;
        margin-right: 10px;
        padding: 0;

        label {
          margin: 0;
        }
      }

      li {
        list-style-type: none;
        color: $mid;
        display: inline-block;
        margin-bottom: 0;
      }
    }

    .dates.hidden {
      display: none;
      height: 0;
    }
    .dates {
      display: flex;
      align-items: center;
      // height: 80%;
      // overflow: auto;
      width: 100%;
      padding: 0;
      margin: 0;
      padding-bottom: 10px;

      border-bottom: 1px solid rgba(162, 162, 162, 0.1);

      .custom-datePicker-container {
        position: relative;
        width: calc((100% - 70px) / 2);

        svg {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          pointer-events: none;

          height: 20px;
          width: 24px;
          color: #6b6b6b;
          font-size: 17px;
          line-height: 17px;
          text-align: center;
        }
      }

      .react-datepicker-wrapper {
        width: 100%;
        height: 50px;
        float: left;
        display: block;
        background-color: rgba(162, 162, 162, 0.1);
        input {
          background-color: rgba(162, 162, 162, 0.1);
          color: $mid;
          padding: 12% 5%;
        }

        div {
          height: 100%;
          input {
            width: 100%;
            height: 100%;
          }
        }
      }

      .react-datepicker_tab-loop {
        .react-datepicker-popper {
          right: 0;
          padding: 0;
        }
      }

      .to {
        width: 70px;
        float: left;
        color: $mid;
        text-align: center;
        display: block;
      }
    }

    .bobbleList {
      margin-top: 7px;
      padding: 0;
      display: inline;

      li {
        list-style-type: none;
        color: $mid;
        display: inline-block;
        padding: 2px 10px;
        border-radius: 15px;
        // border: 2px solid red;
        background-color: rgba(162, 162, 162, 0.1);
        color: #ffffff;
        margin-right: 10px;
        margin-bottom: 10px;
        cursor: pointer;
      }
      .listsite.choosen {
        background-color: #ffffff;
        color: $dark;
      }
    }
    .bobbleList.hidden {
      display: none;
    }
    .bobbleList.choosen {
      background-color: $dark;
      color: $light;
    }
  }

  .filterSite:last-of-type {
    gap: 6px;

    .dropAndText {
      margin-bottom: 4px;
    }
  }
}

.filter-panel.hidden {
  width: 0;
  height: 0;
  display: none;
  opacity: 0;
}
