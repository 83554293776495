@import "src/colorVariables.scss";
.main-pages-list-view {
  height: calc(100% - 110px);

  .listHeaderWrapper {
    margin-bottom: 30px;
  }

  .list-header {
    position: absolute;
    top: 2px;
    left: 160px;
    top: 45px;

    .status-filter-parent {
      // margin-left: 60px;
      .status-filter {
        cursor: pointer;
        user-select: none;
        margin: 0 10px;
        color: black;
        white-space: nowrap;
        .status-filter-amount {
          color: $mid;
        }
        transition: ease-in-out 0.1s;
      }
      .status-filter.selected {
        color: $blue;
      }
    }
  }
  .top-filter {
    margin: 46px 0 0 160px;
    display: flex;
    .filter-option {
      margin: 0 10px;
    }
  }
  .search-box {
    margin-top: 110px;
    margin-left: 30px;
    width: 424px;
    input {
      &::placeholder {
        opacity: 0.4;
      }
    }
  }
  .pages-table-wrap {
    height: calc(100% - 88px);
    overflow: auto;
    .pages-table {
      width: calc(100% - 60px);
      margin: 30px;
      margin-top: 0;
      user-select: none;
      thead {
        th {
          color: $mid;
          font-weight: normal;
          font-size: 14px;
          padding-bottom: 12px;
          &.page,
          &.created {
            cursor: pointer;
          }
          .text {
            margin-right: 10px;
          }
          .located,
          .duplicate,
          .delete {
            text-align: center;
            margin-right: unset;
          }

          &.external,
          &.seo,
          &.published {
            width: 45px;
            text-align: center;
          }
          &.checkbox {
            label {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 0;
            }
          }
        }
      }
      tbody {
        tr {
          border-top: 1px solid rgba(162, 162, 162, 0.1);
          td {
            padding: 10px 0px;
            position: relative;
            &.page {
              cursor: pointer;
              .page-info {
                display: flex;
                img {
                  width: 70px;
                  height: 40px;
                  object-fit: cover;
                  margin-top: 4px;
                }
                .right-side {
                  margin-left: 20px;

                  .headline {
                    min-height: 24px;
                    padding: unset;
                    background-color: unset;
                    font-weight: unset;
                    .deleted {
                      font-style: italic;
                      color: $mid;
                    }
                  }
                  .last-edit {
                    color: $mid;
                  }
                }
              }
            }
            &.created {
              .create-date {
                font-size: 16px;
              }
              .user-created {
                font-size: 14px;
                color: $mid;
              }
            }
            &.located,
            &.duplicate,
            &.delete {
              width: 65px;
              text-align: center;
              font-size: 18px;
            }
            &.duplicate,
            &.delete {
              cursor: pointer;
              transition: ease-out 0.2s;
              &:hover {
                background-color: $light;
              }
            }
            &.delete {
              &:hover {
                color: $red;
              }
            }
            .ellipsis {
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translate(-50%, 0);
              .the-path {
                white-space: nowrap;
                position: absolute;
                bottom: 0;
                z-index: 1;
                color: white;
                background-color: black;
                transform: translateX(-50%);
                left: 50%;
                visibility: hidden;
                opacity: 0;
                transition: ease-in-out 0.2s;
                padding: 2px 10px;
              }
              &:hover {
                .the-path {
                  visibility: visible;
                  opacity: 1;
                }
              }
            }
            &.external,
            &.seo,
            &.published {
              .wrapper {
                display: flex;
                justify-content: center;
              }
              // display: flex;
              // justify-content: center;
              // margin: auto;
              // > div{
              //     display: inline-block;
              //     text-align: center;
              // }
            }
            .dot {
              width: 10px;
              height: 10px;
              border-radius: 2px;
              &.red {
                background-color: $red;
              }
              &.green {
                background-color: $green;
              }
            }
            &.checkbox {
              label {
                width: 100%;
                height: 100%;
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }
      }
    }
  }
}
