@import "src/colorVariables.scss";
.edit-module.image-settings {
  height: 400px;
  .top {
    position: relative;
    .close {
      position: absolute;
      right: 10px;
      font-size: 18px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  .info {
    font-family: "Libre Baskerville";
    font-style: italic;
    border: 1px solid;
    position: absolute;
    right: 25px;
    transform: translateY(-2px);
    border-radius: 100%;
    width: 23px;
    height: 23px;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  .option.description {
    height: unset;
  }
  .title-2 {
    margin: 20px 20px -2px 20px;
    font-weight: bold;
  }
}
