@import "src/colorVariables.scss";
.upload-or-find-image {
  .upload-or-find-image {
    display: flex;
    flex-direction: column;
    margin: 0 20px;
    cursor: pointer;

    .big-box {
      height: 200px;
      background-color: rgba(162, 162, 162, 0.05);
      display: grid;
      place-items: center;
      margin-top: 20px;
      border: 4px solid transparent;

      &.drag-over {
        border: 4px dashed $blue;
      }

      .upload-bar {
        height: 5px;
        width: 50%;
        top: 50%;
        left: 50%;
        background-color: $mid;
        .progress-bar {
          height: 100%;
          background-color: $blue;
        }
      }
    }
    .text {
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(162, 162, 162, 0.1);
      font-size: 14px;
    }

    &.choose-media {
      margin-bottom: 20px;
      .text {
        transition: ease-out 0.2s;
        &:hover {
          background-color: $blue;
          color: white;
        }
      }
    }
  }
}
