@import "src/colorVariables.scss";

.title {
  color: $mid;
  opacity: 0.98;
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 15px;
  margin: 0;
  margin-top: 4rem;

  text-transform: uppercase;

  border-bottom: 1px solid rgba(162, 162, 162, 0.2);
  padding-bottom: 13px;
}

.categoriesContainer {
  display: flex;
  flex-direction: column;
}

.catContainer {
  display: flex;

  border-bottom: 1px solid rgba(162, 162, 162, 0.2);
  padding: 15px 0;

  .catTitle {
    max-width: 235px;
    width: 100%;
    color: $dark;
    line-height: 20px;
  }

  ul {
    display: flex;
    flex-direction: column;

    li:first-of-type {
      color: $dark;
      margin-top: 0;
    }
  }
}
