@import "src/colorVariables.scss";
.whatson-choose {
  height: 100%;

  .close {
    top: 15px;
    right: 20px;
    position: absolute;
    z-index: 2;
    cursor: pointer;
    width: 20px;
    text-align: center;
  }

  .bottom-buttons {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .cancel,
    .apply,
    .next,
    .previous {
      display: flex;
      height: 50px;
      margin-right: 50px;
      align-items: center;
      font-size: 20px;
      font-weight: bold;
      padding-left: 20px;
      padding-right: 20px;
      user-select: none;
      cursor: pointer;
      transition: ease-out 0.2s;
      &.disabled {
        background-color: $mid;
        opacity: 0.25;
        pointer-events: none;
      }
    }
    .apply,
    .next {
      background-color: $blue;
      color: white;
      &:hover {
        background-color: $dark-blue;
      }
    }
    .cancel {
      background-color: $light;
      &:hover {
        background-color: $mid;
      }
    }
    .amount-text {
      margin-right: 20px;
      font-size: 16px;
    }
  }
}
