@import "src/colorVariables.scss";

.vfi-input-text {
  line-height: 0px;
  position: relative;
  label {
    width: 100%;
    margin-bottom: 0;
    position: relative;
  }
  .right-icon,
  .remove-text-button {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-12px, -50%);

    .fa-exclamation {
      color: #f64e60;
    }
    .fa-check {
      color: #7ecf8f;
    }
  }
  .remove-text-button {
    font-size: 24px;
    cursor: pointer;
  }
  input[type="text"] {
    padding: 15px 30px 15px 15px;
    background-color: $input-fill;
    border: none;
    border-radius: 2px;
    border: 2px solid transparent;
    position: relative;
    width: 100%;
  }
  input[type="text"]:hover {
    box-shadow: 0px 0px 2px #919191;
    //border: 1px solid #E3E3E3;
  }

  input[type="text"]:focus {
    background-color: rgba($color: #ffffff, $alpha: 0.1);
    box-shadow: 0px 0px 1px #313131;
    // border: 2px solid #009FFF;
    border: 2px solid transparent;
  }

  input[type="text"].error {
    border: 2px solid #f64e60;
  }

  input[type="text"].check {
    border: 2px solid #7ecf8f;
  }

  input[disabled]:hover {
    box-shadow: none;
  }

  .errors {
    position: absolute;
    font-size: 13px;
    color: #ef2533;
    bottom: -10px;
    .error {
      display: inline-block;
      margin-right: 5px;
    }
  }
  .custom-right-side {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-12px, -50%);
  }
  .max-chars {
    position: absolute;
    right: -14px;
    bottom: -11px;
    transform: translateX(-50%);
    text-align: right;
    font-size: small;
    font-style: italic;
    color: #a2a2a2;
  }
  .submit-buttons {
    .yes,
    .no {
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: transparent;
      border: none;
    }
    .yes {
      right: 20px;
      color: $green;
    }
    .no {
      right: 0;
      color: $red;
    }
  }
}
