@import "src/colorVariables.scss";

.main {
  .content {
    // .overlayParent{
    //     width: inherit;
    // }
    .overlayParent {
      &.popupselect {
        .overlay {
          background-color: transparent;
          box-shadow: none;
        }
        .overlay-blur {
          background-color: rgba(0, 0, 0, 0.45);
        }
      }

      .overlay {
        // filter: blur(0px) grayscale(0%) contrast(100%);
        // -webkit-filter: blur(0px) grayscale(0%) contrast(100%);
        // -moz-filter: blur(0px) grayscale(0%) contrast(100%);
        // -o-filter: blur(0px) grayscale(0%) contrast(100%);
        // -ms-filter: blur(0px) grayscale(0%) contrast(100%);
        position: absolute;
        top: 20px;
        right: 20px;
        left: 20px;
        bottom: 20px;
        // width:  100%;
        // margin-left: 20px;
        // margin-right: 20px;
        height: calc(100% - 40px);
        box-sizing: border-box;
        background-color: white;
        // box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
        font-weight: 400; //Regular
        font-size: 15px;
        z-index: 20;

        .custom-show {
          .close svg {
            font-size: 28px;
          }
        }
      }

      .overlay-blur {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.45);
        z-index: 1;
        animation: ease-out 0.5s appear;
      }

      .popup-select {
        position: fixed;
        width: 490px;
        height: 208px;
        background-color: white;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        .pop-title {
          height: 26px;
          font-size: 13px;
          letter-spacing: 1px;
          background-color: $grey-menu;
          font-weight: 600;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .pop-description {
          display: flex;
          flex-grow: 1;
          margin-top: 24px;
          svg {
            flex-basis: 75px;
            display: flex;
            justify-content: center;
            font-size: 28px;
            color: $mid;
          }
        }
        .pop-buttons {
          height: 82px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          button {
            height: 50px;
            width: 125px;
            border: none;
            border-radius: 2px;
            font-size: 17px;
            margin-right: 20px;

            &.cancel-button {
              background-color: transparent;
              &:hover {
                text-decoration: underline;
              }
            }
            &.submit-button {
              background-color: $blue;
              color: white;
              font-weight: 600;
              &:hover {
                background-color: rgba($color: $blue, $alpha: 0.8);
              }
            }
          }
        }
      }
    }
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
