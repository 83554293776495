@import "src/colorVariables.scss";
.vfi-carousel.edit-module {
  height: 630px;
  width: 430px;
  display: flex;
  flex-direction: column;
  user-select: none;
  margin: 0;

  .top {
    background-color: $light;
    display: flex;
    justify-content: center;
    height: 26px;
    flex-shrink: 0;

    .top-choice {
      padding: 0 22px;
      font-size: 13px;
      font-weight: bold;
      letter-spacing: 1px;
      user-select: none;
      cursor: pointer;
      color: $mid;
      height: 100%;
      display: grid;
      place-items: center;

      &.active {
        color: black;
      }
    }
  }
  .content {
    display: block;
    width: unset;
    padding-top: 20px;
    max-height: 604px;
    overflow: auto;
    .media {
      padding: 0 20px;
      flex-wrap: wrap;
      position: relative;
      .medium {
        height: unset;
        flex-grow: 1;
        margin: 1px;
        cursor: grab;
        position: relative;
        max-width: 250px;
        .remove {
          position: absolute;
          top: 5px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 1;
          background-color: #ffffff;
          display: flex;
          padding: 5px;
          color: $red;
          cursor: pointer;
          opacity: 0.4;
          border-radius: 2px;
          transition: ease-out 0.2s;
          &:hover {
            opacity: 0.6;
          }
        }
        .one-media {
          height: unset;
          .upload-box {
            height: unset;
            .image-container {
              height: 110px;
            }
          }
        }
      }
    }
    .drag-n-drop {
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 20px;
      bottom: 0px;
      section {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        & > div {
          width: 100%;
          height: 100%;
        }
      }
      .upload-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        transition: ease-out 0.2s;
        cursor: pointer;
        border: 2px solid $light;
        &:hover {
          background-color: $light;
        }

        &.drag-over {
          border: 2px solid $blue;
        }

        .text-content {
          display: flex;
          align-items: center;
          gap: 10px;
          padding: 0 10px;
        }
      }
      .upload-bar {
        height: 5px;
        width: 50%;
        top: 50%;
        left: 50%;
        background-color: $mid;
        .progress-bar {
          height: 100%;
          background-color: $blue;
        }
      }
    }
    .add {
      height: 200px;
      width: 100%;
      margin: 20px 0 80px;
      border: 2px solid #f5f5f5;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      transition: ease-out 0.2s;
      &:hover {
        background-color: #f5f5f5;
      }
    }
    .design {
      .gallery-types {
        margin: 0 10px 40px 10px;
        display: flex;
        justify-content: space-evenly;
        .g-type {
          width: 80px;
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
          transition: ease-out 0.2s;
          &:hover {
            background-color: $lighter;
          }
          &.active {
            background-color: $light;
          }
          img {
            margin-top: 12px;
            width: 22px;
            height: 22px;
          }
          .text {
            text-align: center;
            font-size: 12px;
            margin-top: 5px;
          }
        }
      }
      .background-color {
        .value {
          display: flex;
          gap: 8px;
          > div {
            height: 20px;
            width: 20px;
            border-radius: 2px;
            border: 1px solid rgba(162, 162, 162, 0.3);
            cursor: pointer;
          }
          .color1 {
            background-color: $background-color1;
          }
          .color2 {
            background-color: $background-color2;
          }
          .color3 {
            background-color: $background-color3;
          }
          .color4 {
            background-image: linear-gradient(
              to right,
              white,
              black,
              white,
              black,
              white,
              black,
              white
            );
          }
          .selected {
            border: 2px solid rgba($color: $blue, $alpha: 1);
          }
        }
      }
      .option {
        margin: 0 20px;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid rgba(162, 162, 162, 0.1);
        transition: ease-out 0.2s;
        width: unset;
        &.whole-click {
          cursor: pointer;
          &:hover {
            background-color: $lighter;
          }
        }
        .label {
          margin-left: 10px;
        }
        .value {
          margin-right: 10px;
          .css-2b097c-container {
            width: 210px;
            .css-yk16xz-control,
            .css-1pahdxg-control {
              border: none;
              .css-g1d714-ValueContainer {
                display: flex;
                justify-content: flex-end;
              }
              .css-1okebmr-indicatorSeparator {
                display: none;
              }
              .css-tlfecz-indicatorContainer,
              .css-1gtu0rj-indicatorContainer {
                padding-right: 0;
                > svg {
                  display: none;
                }

                &::before {
                  font-family: "Font Awesome 5 Pro";
                  font-weight: 100;
                  content: "\f078";
                  font-size: 16px;
                  visibility: visible;
                  color: black;
                }
              }
            }
          }
        }
      }
      .grid-template-choose {
        .content {
          padding-top: 0;
        }
        .templates {
          padding: 0;
          background-color: transparent;
          grid-gap: unset;
          gap: unset;
          .template {
            width: 50%;
            margin: 0;
            background-color: transparent;
            padding: 0;
            &:hover {
              background-color: $light;
            }
            &.selected {
              background-color: $light;
            }
            .media-grid,
            .media-grid-related {
              &:hover {
                background-color: transparent;
              }
              padding: 10px 20px 0 20px;
              width: 100%;
              .grid-container {
                height: 100%;
                width: 100%;
              }
            }
            .text {
              font-size: 12px;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }
    }
  }
}
