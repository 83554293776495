.social-module {
  margin-top: 5px;
  width: 700px;
  margin: auto;
  .icons-container {
    display: flex;
    // margin-left: -96px;
    .icon {
      width: 38px;
      height: 38px;
      margin-right: 10px;
      display: grid;
      place-items: center;
    }
  }
}
