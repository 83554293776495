@import "src/colorVariables.scss";

.infoscreen-options-button {
  all: unset;
  cursor: pointer;
}

.infoscreen-options-button {
  width: 20px;
  min-width: 20px;
  right: 10px;
  text-align: center;
  border-radius: 2px;
  transition: ease-in-out 0.1s;

  opacity: 0;

  &:hover {
    background-color: #a2a2a2;
  }
}
