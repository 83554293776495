@import "src/colorVariables.scss";
.page-module-choose {
  height: 100%;
  .close {
    position: absolute;
    right: 0;
    margin-top: 20px;
    margin-right: 20px;
    opacity: 1;
    cursor: pointer;
    z-index: 1;
  }
  .content {
    display: flex;
    flex-direction: column;
    width: unset;
    height: 100%;
    h2 {
      margin: 20px;
    }
    .modules {
      flex-grow: 1;
      background-color: $lighter;
      margin: 0 20px;
      padding: 10px;
      // display: flex;
      align-content: flex-start;
      flex-wrap: wrap;
      overflow: auto;

      .title {
        background-color: lightgray;
        display: block;
        padding-left: 5px;
        margin-top: 20px;
        &:first-child {
          margin: 0;
        }
      }

      .choice {
        display: inline-block;
        margin-right: 10px;
        margin-top: 5px;
        padding: 5px;
        background-color: $light;
        text-align: center;
        // max-width: 150px;
        width: 150px;
        vertical-align: middle;
        cursor: pointer;
        user-select: none;
        transition: ease-in-out 0.2s;

        &.selected {
          background-color: $green;
        }
        svg {
          font-size: 24px;
        }
      }

      // .icon{
      //     display: flex;
      //     flex-direction: column;
      //     justify-content: center;
      //     width: 80px;
      //     user-select: none;
      //     padding: 10px;
      //     border-radius: 30px;
      //     transition: ease-in-out 0.2s;
      //     cursor: pointer;
      //     img{
      //         filter: brightness(0);
      //     }
      //     .name{
      //         text-align: center;
      //     }
      //     &.selected{
      //         background-color: black;
      //         color: white;
      //         img{
      //             filter: brightness(0) invert(1);
      //         }
      //     }
      // }
    }
    .final-options {
      display: flex;
      justify-content: flex-end;
      .option {
        width: 90px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: bold;
        margin: 20px 20px;
        cursor: pointer;

        &.select {
          background-color: $blue;
          color: white;
        }

        &.cancel {
          background-color: $light;
        }

        &.inactive {
          opacity: 0.2;
          cursor: default;
        }
      }
    }
  }
}
