@import "src/colorVariables.scss";
.prices {
  opacity: 1;
  transition: opacity 0.5s;
  .table-wrap {
    margin-top: 20px;
    table {
      width: inherit;
      .vfi-input-text {
        width: unset;
        @media ($smallPhone) {
          padding: 15px 10px;
        }
      }
      .delete {
        display: flex;
        svg {
          font-size: 12px;
          cursor: pointer;
        }
      }
      th {
        font-weight: normal;
      }
      td {
        padding-right: 10px;
        padding-bottom: 9px;
      }
    }
    .add-row-wrap {
      text-align: center;
      max-width: 772px;
      .add-row {
        font-size: 15px;
        cursor: pointer;
        user-select: none;
        transition: ease-in-out 0.2s;
        .add-price-text {
          margin-right: 8px;
        }
      }
      .add-row:hover {
        // color: $light-blue;
        .add-price-text {
          text-decoration: underline;
        }
      }
    }
  }
  p,
  .vfi-input-text {
    max-width: 755px;
  }
  p {
    margin-top: 40px;
  }
}
.prices.hidden {
  opacity: 0;
  pointer-events: none;
}
