@import "src/colorVariables.scss";
.payment {
  overflow: auto;
  background-color: #fafafa;
  .top {
    height: 100px;
    border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
    top: 0;
    .close {
      font-size: 2rem;
      background-color: transparent;
      border: none;
    }
  }
  .payment-wrap {
    // height: 100%;
    // padding: 0px 60px;
    max-width: 1280px;
    margin: auto;
    position: relative;
    .content {
      padding: 20px;
      // background-color: white;
      // margin-top: 40px;
      margin-bottom: 40px;
      .box {
        background-color: white;
        box-shadow: 0 0 10px rgba(162, 162, 162, 0.1);
        padding: 2.4rem;
        border-radius: 0.2rem;
        h3 {
          margin-bottom: 30px;
        }
      }
      .text-input {
        position: relative;
        label {
          position: absolute;
          left: 10px;
          top: 5px;
          font-size: 14px;
          color: #008dff;
        }
      }
      .split-evenly {
        display: flex;
        gap: 10px;
        > * {
          flex: 1 1 0;
        }
      }
      .small-label {
        font-size: 0.8rem;
        color: $mid;
      }
      .margin-left {
        margin-left: 10px;
      }
      .margin-right {
        margin-right: 10px;
      }
      .margin-bottom {
        margin-bottom: 10px;
      }
      .margin-top {
        margin-top: 10px;
      }
      .status-list-wrap {
        max-width: 750px;
        .status-list {
          // display: flex;
          // justify-content: space-between;
          // @media (max-width: 1440px) {
          display: block;
          // }

          .status {
            background-color: $light;
            padding: 10px;
            border-radius: 2px;
            width: fit-content;
          }
        }
      }
      .user-info-row {
        display: flex;
        margin-top: 10px;
        .user-info-wrap {
          // width: 1200px;
          .user-info {
            min-width: 350px;
          }
        }
        .right-side-of-user-info {
          flex-grow: 1;
          padding-left: 10px;
          .pdf-button {
            background-color: $blue;
            border: none;
            color: white;
            font-size: 1.2rem;
            padding: 5px 10px;
            float: right;
          }
        }
      }
      .orders {
        display: grid;
        grid-template-columns: 30px 70px auto 100px 120px;
        // row-gap: 5px;
        column-gap: 10px;
        .orders-head {
          // font-weight: 600;
          padding-bottom: 4px;
          &.order-price,
          &.order-price-per {
            // text-align: center;
            display: block;
          }
          color: $blue;
        }
        .order-price,
        .order-price-pr,
        .order-total-result {
          display: flex;
          justify-content: space-between;
          .amount {
            flex-grow: 1;
            text-align: right;
            margin-right: 5px;
          }
        }
        .product,
        .service {
          display: flex;
          align-items: center;
          border-top: 1px solid $light;
          padding: 8px 0;
        }
        .orders-foot {
          margin-top: 20px;
          font-weight: 600;
          &.order-total {
            text-align: right;
          }
          &.order-total-result {
            .amount {
              text-decoration: underline double;
              text-underline-offset: 4px;
            }
            // display: none;
          }
        }
      }
      .orders-row {
        margin-top: 10px;
        display: flex;
        gap: 10px;
        > div {
          flex-grow: 1;
        }
        .order-history-wrap {
          max-width: 600px;
          .order-history {
            display: grid;
            grid-template-columns: /*30px*/ auto auto;
            > div {
              &.header {
                padding: 0 0 5px 0;
                color: $blue;
              }
              &.info {
                padding: 5px 0;
                border-top: 1px solid $light;
                padding-right: 10px;
              }
            }
            .icon {
              margin-right: 10px;
            }
          }
        }
      }
      .options-row {
        margin-top: 40px;
      }
    }
  }
}
