@import "src/colorVariables.scss";

.confirm-modal-background {
  width: 100%;
  height: 100%;
  background-color: rgba(162, 162, 162, 0.5);

  position: fixed;
  top: 0;
  left: 0;

  // pointer-events: none !important;
  z-index: 1000;
}

.confirm-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 340px;
  height: 168px;
  background-color: white;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.11);

  display: flex;
  flex-direction: column;
  gap: 32px;

  padding: 30px;
}

.confirm-modal-message {
  color: $dark;
  font-family: "Source Sans Pro";
  font-size: 17px;
  letter-spacing: 0;
  line-height: 22px;

  span {
    font-weight: bold;
  }
}

.confirm-modal-close-btn-container {
  display: flex;
}

.confirm-modal-close-btn {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  font-size: 18px;
}

.confirm-modal-container {
  display: flex;
  gap: 10px;

  &.top-container {
    gap: 30px;
  }
}

.modal-btn {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  width: 132px;
  height: 30px;

  &.revoke-access {
    font-size: 12px;
    line-height: 15px;
    text-align: center;
  }

  &.confirm-btn {
    background-color: $blue;

    &.revoke-access {
      color: #ffffff;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }
  &.cancel-btn {
    background-color: $mid-between;

    &.revoke-access {
      color: #000000;
      letter-spacing: 0.4px;
    }
  }
}
