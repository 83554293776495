@import "src/colorVariables.scss";
.currency-options {
  height: 630px;
  width: 430px;
  display: flex;
  flex-direction: column;
  user-select: none;
  margin: 0;
  #handle {
    cursor: move;
    height: 28px;
    display: flex;
    align-items: center;
    margin-left: 8px;
    svg {
      margin: 0 1px;
    }
  }
  .close {
    top: 4px;
    right: 4px;
  }
  .top {
    background-color: $light;
    display: flex;
    justify-content: center;
    height: 26px;
    flex-shrink: 0;
    .top-choice {
      padding: 0 22px;
      font-size: 13px;
      font-weight: bold;
      letter-spacing: 1px;
      user-select: none;
      cursor: pointer;
      color: $mid;
      height: 100%;
      display: grid;
      place-items: center;

      &.active {
        color: black;
      }
    }
  }
  .content {
    width: 100%;
    padding: 20px 20px 0px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    > div {
      display: flex;
      width: 100%;
      gap: 10px;
      justify-content: space-between;
      align-items: center;
      > div {
        flex-basis: 50%;
      }
      .currencyResult {
        text-align: center;
        font-size: 1.5rem;
      }
      .currencyInput {
        flex-basis: 50%;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        background-color: hsl(0, 0%, 100%);
        border-color: hsl(0, 0%, 80%);
        border-radius: 2px;
        border-style: solid;
        border-width: 1px;
        cursor: default;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        min-height: 38px;
        outline: 0 !important;
        position: relative;
        -webkit-transition: all 100ms;
        transition: all 100ms;
        box-sizing: border-box;
        padding: 2px 8px;
      }
    }
    svg {
      margin: 0 auto;
      font-size: 30px;
    }
  }
}
