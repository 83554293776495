.container {
    width: 100%;
    height: 100%;

    opacity: 0;

    transition: 0.2s;

    .content {
        padding: 51px 0 0 60px;

        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .header {
            padding-bottom: 33px;
            padding-right: 60px;

            width: 100%;

            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            h1 {
                font-size: 30px;

                font-weight: bold;
            }

            .addAmenity {
                width: 160px;
                height: 50px;

                background-color: #009FFF;

                font-size: 17px;
                font-weight: bold;
                line-height: 21px;

                color: #fff;

                display: flex;
                justify-content: center;
                align-items: center;

                transition: 0.2s;

                &:hover {

                    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);

                    transform: translateY(-1px);
                }
            }

        }

        .searchSection {
            padding-bottom: 31px;

            .searchInputContainer {


                position: relative;


                .searchInput {
                    padding: 10px 15px;

                    width: 415px;
                    height: 50px;

                    background-color: rgba(162, 162, 162, 0.1);

                    border: none;

                    transition: 0.1s;

                    &:hover {

                        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
                    }
                }

                .searchIcon {

                    font-size: 17px;
                    color: #6B6B6B;

                    position: absolute;
                    right: 20px;
                    top: 50%;

                    transform: translateY(-50%);

                    opacity: 98%;

                    pointer-events: none;
                }
            }
        }

        .tableContainer {
            padding-right: 60px;

            width: 100%;
            height: 100%;

            overflow: auto;

            table {
                max-height: 100%;
                height: fit-content;

                border-collapse: collapse;
                width: 100%;

                td,
                th {
                    text-align: left;
                    padding: 8px;
                }

                th {

                    user-select: none;

                    cursor: pointer;

                    outline: none;

                    transition: 0.2s;

                    .tableHeaderContent {
                        width: 100%;

                        font-size: 14px;

                        font-weight: normal;

                        background-color: rgba(255, 255, 255, 0);
                        color: #A2A2A2;

                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;

                        gap: 10px;

                        transition: 0.2s;

                        p {
                            margin: 0;
                        }

                        &:hover,
                        &:focus {
                            color: #787878;
                        }
                    }

                    &:focus {
                        background-color: rgba(0, 0, 0, 0.05);
                    }
                }

                td {
                    font-size: 16px;

                    columns: #313131;
                }

                tr {
                    border-bottom: 1px solid rgba(162, 162, 162, 0.1);

                    .last {
                        text-align: right;

                        .tableHeaderContent {
                            justify-content: flex-end;
                        }
                    }
                }

                .amenity {

                    min-height: 0;

                    height: 0px;

                    color: #333;

                    overflow: hidden;
                    visibility: collapse;

                    border-bottom: 1px solid rgba(162, 162, 162, 0);

                    cursor: pointer;

                    opacity: 0;

                    outline: none;

                    transition: 0.2s;

                    &:hover,
                    &:focus {
                        color: #000;

                        // text-decoration: underline;

                        background-color: rgba(160, 160, 160, 0.05);
                        //box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);

                        border-bottom: 1px solid rgba(162, 162, 162, 0);

                        //transform: translateY(-1px);
                    }

                    &.active {
                        height: 60px;

                        visibility: visible;

                        border-bottom: 1px solid rgba(162, 162, 162, 0.1);

                        opacity: 1;
                    }
                }

                .amenitiesLoading {
                    width: 100%;
                    height: 100%;

                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .loader {

                        @keyframes loading {
                            0% {
                                transform: rotate(0deg);
                            }
                            100% {
                                transform: rotate(360deg);
                            }
                        }

                        animation-name: loading;
                        animation-duration: 2s;
                        animation-iteration-count: infinite;
                        animation-timing-function: linear;
                    }
                }
            }

            /* width */
            &::-webkit-scrollbar {
                width: 8px;

                border-radius: 1000px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                background: #f1f1f1;

                border-radius: 1000px;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #c2c2c2;

                border-radius: 1000px;

                transition: 0.2s;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #a7a7a7;
            }

        }
    }

    &.loaded {
        opacity: 1;
    }
}