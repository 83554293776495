@import "src/colorVariables.scss";
.edit-footer {
  height: 100%;
  overflow: auto;
  background-color: #f4f4f5;
  a:link {
    color: $text-color;
  }
  a:visited {
    color: $text-color;
  }

  .loading {
    pointer-events: none;
    opacity: 0.5;
  }
  &.row {
    margin-left: 0;
    margin-right: 0;
    flex-wrap: nowrap;
  }

  .options-list {
    .option-in-list {
      .vfi-input-text {
        margin: 5px 10px;
      }
      .URLtext {
        margin-left: 12px;
        background-color: #f9f9fa;
      }
    }
  }

  .footer-section {
    // background-color: red;
    padding-left: 30px;
    height: 100%;
    flex-basis: calc(100% - 420px);
    max-width: unset;
    display: flex;
    flex-direction: column;
    width: auto;
    .footer-section-header {
      min-height: 100px;

      .fixed {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        &.single {
          .one-language-wrap {
            justify-content: center;
          }
        }
        &.selected {
          border-bottom: 1px solid black;
          color: black;
        }
        &.disabled {
          pointer-events: none;
          opacity: 0.2;
        }
        &:hover {
          background-color: $light;
        }

        .header-title {
          font-weight: 600;
          font-size: 19px;
          margin-right: 50px;
          cursor: pointer;
          transition: ease-in-out 0.2s;

          &:hover {
            color: $blue;
          }
        }
        .one-language,
        .add-language {
          width: 70px;
          height: 50px;
          // padding: 15px 25px;
          margin: 10px;
          border-bottom: 1px solid $mid;
          color: $mid;
          font-size: 14px;
          transition: ease-in-out 0.2s;
          user-select: none;
          display: flex;
          align-items: center;
          justify-content: center;
          &.selected {
            border-bottom: 1px solid black;
            color: black;
          }
          &.disabled {
            pointer-events: none;
            opacity: 0.2;
          }
          &:hover {
            background-color: $light;
          }
          .one-language-wrap {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            width: 100%;
            .the-language,
            .trash {
              padding: 4px 8px;
              border-radius: 2px;
              cursor: pointer;
              transition: ease-out 0.05s;

              &:hover {
                background-color: rgba($color: #ffffff, $alpha: 1);
                &.trash {
                  color: $red;
                }
              }
            }
            .trash {
              color: $mid;
            }
          }
        }
      }
    }
    .footer-section-content {
      height: calc(100% - 120px);
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.11);
      align-self: center;
      width: 100%;
      position: relative;
      transition: ease-in-out 0.5s;
      .blur {
        position: absolute;
        display: grid;
        place-items: center;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 9;
        font-size: 24px;
      }

      .footer-content {
        // line-height: 33px;
        background-color: white;
        .fixedColumns {
          display: flex;
          padding: 0 20px;
          justify-content: center;
          .footerTrash {
            &.disabled {
              pointer-events: none;
              opacity: 0.2;
            }
          }

          .one-column,
          .add-column {
            width: 120px;
            height: 50px;
            // padding: 15px 25px;
            margin: 10px;
            border-bottom: 1px solid $mid;
            color: $mid;
            font-size: 14px;
            transition: ease-in-out 0.2s;
            user-select: none;
            display: flex;
            align-items: center;
            justify-content: center;
            &.selected {
              border-bottom: 1px solid black;
              color: black;
            }
            &.disabled {
              pointer-events: none;
              opacity: 0.2;
            }
            &:hover {
              // background-color: $light;
            }
            .one-column-wrap {
              display: flex;
              justify-content: space-between;
              align-items: stretch;
              width: 100%;
              .the-column,
              .trash {
                padding: 4px 8px;
                border-radius: 2px;
                cursor: pointer;
                transition: ease-out 0.05s;

                &:hover {
                  // background-color: rgba($color: #ffffff, $alpha: 1);
                  background-color: $light;
                  &.trash {
                    color: $red;
                  }
                }
              }
              .trash {
                color: $mid;
              }
            }
          }
          .add-column {
            cursor: pointer;
          }
        }
        .row.module {
          width: 600px;
        }
        .vfi-text-area {
          width: 80%;
          margin-right: auto;
          margin-left: auto;
          p {
            margin-top: 0;
            margin-bottom: 0;
          }
          ul {
            padding: 1px 1px 1px 8px;
            margin-left: 12px;
            li::before {
              padding: 3px 0px 0 0px;
            }
            li {
              padding-left: 0;
              margin-bottom: 15px;
            }
          }
          ol {
            list-style: none;
            padding-left: 0;
            li {
              counter-increment: step-counter;
              margin-bottom: 10px;
              padding: 0 0 10px 0;
              min-height: 60px;
            }

            li::before {
              content: counter(step-counter);
              margin-right: 5px;
              font-size: 60px;
              font-weight: bold;
              float: left;
              height: 60px;
              // font-family: "Libre Baskerville";
              line-height: 72px;
            }
          }
        }
        blockquote {
          margin-top: 60px;
          border-left: none;
          padding: 0;
          // font-family: "Libre Baskerville";
          font-style: italic;
          &::before {
            content: "“";
            font-size: 109px;
            position: absolute;
            margin-top: -10px;
            color: $blue;
            font-style: normal;
          }
          & p {
            color: $text-color;
            // font-family: "Libre Baskerville";
            font-size: 8px;
            letter-spacing: 0.3px;
            line-height: 33px;
            margin-bottom: 10px !important;

            &:last-of-type {
              font-size: 20px;
              color: $mid;
              margin-top: 10px;
            }
            &:first-of-type {
              font-size: 27px;
              color: #373737;
            }
          }
        }
      }
      .footer-content-top {
        padding: 10px;
        min-height: 50px;
        background-color: white;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
        justify-content: center;
        .edit-section {
          flex-wrap: wrap;
          justify-content: center;
          display: flex;
          & > div {
            display: inline-block;
            margin-left: 10px;
            padding: 5px 10px;
            // font-size: 14px;
            // font-weight: 600;
            border-radius: 2px;
            cursor: pointer;
            transition: ease-in-out 0.2s;
            white-space: nowrap;
            &:hover {
              background-color: rgba($color: #000000, $alpha: 0.1);
            }
          }
          .discard-button {
            color: $red;
          }
        }
        .headline-text {
          flex-grow: 1;
          text-align: center;
          font-size: 16px;
        }
        .display-footer {
          margin: 0 10px;
          padding: 0 5px;
          cursor: pointer;
          border-radius: 2px;
          transition: ease-in-out 0.2s;
          &:hover {
            background-color: rgba($color: #000000, $alpha: 0.1);
          }
          &.active {
            background-color: $dark;
            color: white;
          }
        }
      }

      .footer-content {
        height: calc(100% - 50px);

        .top_module {
          width: 100%;
          height: 20%;
          background-color: $light;
          .cols {
            height: 100%;
            display: flex;
            max-width: 1440px;
            margin: auto;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;

            .Col1 {
              .center {
                img {
                  width: 60px;
                  height: 60px;
                  vertical-align: middle;
                }
                .text {
                  display: inline-block;
                  margin: 0 0 0 10px;
                  font-size: 1em;
                  font-weight: 400;
                  vertical-align: middle;
                  font-family: "VFIfonts", Fallback, sans-serif;
                  font-weight: bold;
                  font-size: 2rem;
                }
              }
            }
            .Col2 {
              .center {
                .Headtext {
                  font-family: "Libre Baskerville";
                  font-style: italic;
                  color: #acacac;
                }
                .addIcon {
                  cursor: pointer;
                  svg {
                    color: $mid;
                    font-size: 16px;
                    transition: ease-in-out 0.2s;
                    user-select: none;
                    margin-left: 10px;
                  }
                  &.disabled {
                    pointer-events: none;
                    opacity: 0.2;
                  }
                  :hover {
                    color: black;
                  }
                }
                .socialIcons {
                  // min-width: 30px;
                  span {
                    position: relative;
                    padding: 3px 2px;
                    .brand {
                      height: 18px;
                      width: auto;
                      padding: 0 4px;
                      margin: 0 2px;
                    }
                    .pen {
                      height: 10px;
                      width: auto;
                      position: absolute;
                      top: 0;
                      right: 0;
                      cursor: pointer;
                    }
                  }
                }
              }
            }

            .Col3 {
              .center {
                display: flex;
                align-items: center;
                .Headtext {
                  font-family: "Libre Baskerville";
                  font-style: italic;
                  color: #acacac;
                  white-space: nowrap;
                }
                .vfi-text-area {
                  min-width: 130px;

                  .ck-content {
                    white-space: nowrap;
                    background-color: white;
                    // text-decoration: none;
                    // font-family: "Libre Baskerville";
                    // font-style: italic;
                  }
                }
              }
            }
          }
        }

        .module {
          overflow-y: auto;
          position: relative;
          max-width: 1440px;
          margin: auto;
          height: 60%;
          padding: 0 30px;
          color: $text-color;

          p,
          h1,
          h2,
          h3 {
            line-height: 1.5rem;
            margin-bottom: 14px;
          }

          h1,
          h2,
          h3 {
            font-weight: bolder;
          }

          h3 {
            font-size: 1.25em;
            margin-top: 50px;
          }

          .module-options {
            // display: none;
            position: absolute;
            right: 20px;
            top: 20px;
            width: 28px;
            height: 28px;
            padding: 4px;
            cursor: pointer;
            z-index: 2;
            border-radius: 2px;
            color: rgba($color: #000000, $alpha: 0.5);
            transition: ease-in-out 0.2s;
            &:hover {
              background-color: $light;
              color: rgba($color: #000000, $alpha: 1);
            }
          }

          .col-lg-1,
          .col-lg-2,
          .col-lg-3,
          .col-lg-4,
          .col-lg-5,
          .col-lg-6,
          .col-lg-7,
          .col-lg-8,
          .col-lg-9,
          .col-lg-10,
          .col-lg-11,
          .col-lg-12,
          .col,
          &.col {
            padding: 0;
          }

          .col-lg-1 {
            max-width: 62px;
          }

          .row {
            margin-left: 0;
            margin-right: 0;
          }

          .ck-content {
            overflow: hidden;
            height: 100%;
            background-color: rgba($color: $mid, $alpha: 0.1);
          }

          &.row,
          .row {
            align-items: stretch;
          }
        }

        &.disabled {
          .module {
            .vfi-text-area {
              pointer-events: none;
            }

            .ck-content {
              background-color: transparent;
            }
            input {
              background-color: transparent;
            }
            .link-to {
              opacity: 0;
            }
          }
          .top_module {
            .cols {
              .center {
                .vfi-text-area {
                  pointer-events: none;
                  padding: 0;

                  .ck-content {
                    // padding:0;
                    // margin:0;
                    padding: 5px 15px;
                    background-color: $light;
                  }
                }
              }
              .link-to {
                opacity: 0;
              }
            }
          }
        }
      }
    }
  }
}
