@import "src/colorVariables.scss";
.footer-display-wrap {
  width: 100%;
  height: calc(100% - 50px);
  background-color: white;
  a:link {
    color: $text-color;
  }
  a:visited {
    color: $text-color;
  }

  .footer-display {
    display: block;
    height: 100%;
    width: 100%;
    .top_module {
      width: 100%;
      height: 20%;
      background-color: $light;
      .cols {
        height: 100%;
        display: flex;
        max-width: 1440px;
        margin: auto;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;

        .paragraph_libre {
          font-family: "Libre Baskerville";
          font-style: italic;
          font-weight: normal;
          font-size: 1rem;
        }

        .Col1 {
          .center {
            img {
              width: 60px;
              height: 60px;
            }
            .text {
              display: inline-block;
              margin: 0 0 0 10px;
              font-size: 1em;
              font-weight: 400;
              vertical-align: middle;
              font-family: "VFIfonts", Fallback, sans-serif;
              font-weight: bold;
              font-size: 2rem;
            }
          }
        }
        .Col2 {
          .center {
            .Headtext {
              float: left;
              font-family: "Libre Baskerville";
              font-style: italic;
              color: #acacac;
            }
            .socialIcons {
              // min-width: 30px;
              span {
                padding: 3px 2px;
                .brand {
                  height: 18px;
                  width: auto;
                  padding: 0 4px;
                  margin: 0 2px;
                }
              }
            }
          }
        }

        .Col3 {
          .center {
            display: flex;
            align-items: center;
            .Headtext {
              float: left;
              font-family: "Libre Baskerville";
              font-style: italic;
              color: #acacac;
            }

            .vals {
              padding: 0 15px;
              float: left;
              p,
              h4 {
                margin: 0;
                padding: 0;
              }
            }
          }
        }
      }
    }
    .modules {
      display: flex;
      max-width: 1440px;
      margin: auto;
      height: 80%;
      flex-direction: row;
      background-color: white;
      justify-content: space-between;
      p,
      h3 {
        margin-bottom: 14px;
        line-height: 1.5rem;
      }
      h3 {
        font-weight: bolder;
        font-size: 1.25em;
        margin-top: 50px;
      }
      .footer-column {
        flex: 1 1 0px;
        padding-right: 10px;
        padding-left: 10px;

        .paragraph_libre {
          font-family: "Libre Baskerville";
          font-style: italic;
          font-weight: normal;
          font-size: 1rem;
        }
      }
    }
  }
}
