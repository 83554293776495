@import "src/colorVariables.scss";
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap");
$LibreBasker: "Libre Baskerville";
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700;800&display=swap");
$sansPro: "Source Sans Pro";

.main {
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  text-align: left;
  display: flex;
  width: 100%;
  overflow-x: hidden;
  // overflow-y: scroll;
  // align-items: stretch;
  height: 100%;
  &.previewUserId {
    margin-top: 30px;
    height: calc(100% - 30px);
  }

  // input[type=text]{
  //     padding: 16px 17px 18px 17px;
  //     background-color: rgba(162,162,162,0.05);
  //     border: none;
  //     border-radius: 5px;
  //     border: 2px solid transparent;
  // }

  // input[type=text]:hover{
  //     box-shadow: 0px 0px 1px #313131;
  // }

  // input[type=text]:focus{
  //     background-color: white;
  //     box-shadow: 0px 0px 1px #313131;
  //     border: 2px solid #009FFF;
  // }

  .end-user-preview {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    font-size: 18px;
    font-weight: 600;
    background-color: $note;
    padding: 2px 5px;
    transition: ease-out 0.2s;
    user-select: none;
    z-index: 15;
    padding-left: 15px;
    top: 0;
    .text {
      flex-grow: 1;
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      color: black;
    }
    .close {
      cursor: pointer;
      opacity: 1;
    }
  }
  .paragraph_libre {
    font-family: $LibreBasker;
  }
}
