$tooltipColor: #fff;
$fontColor: #333;
$shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
$opacity: 0.95;
$bgColClick: #fff;
$bgColHover: #fff; //#f9f9f9;

@keyframes show {
  0% {
    visibility: hidden;
  }

  99% {
    visibility: hidden;
  }

  100% {
    visibility: visible;
  }
}

.tooltip-click-element {
  // cursor: pointer;
}

.easy-tooltip {
  all: unset;

  box-sizing: border-box;

  z-index: 5000;

  max-width: 40vw;
  max-height: 60vh;
  width: auto;
  height: auto;

  // background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

  border-radius: 2px;

  position: fixed;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  overflow: hidden;

  animation-name: show;
  animation-fill-mode: forwards;

  font-family: "Source Sans Pro";

  &.click {
    padding: 20px;

    background-color: $bgColClick;

    gap: 10px;

    .arrow {
      background-color: $bgColClick;
    }
  }

  &.hover {
    padding: 6px 10px;

    background-color: $bgColHover;

    .arrow {
      background-color: $bgColHover;
    }
  }

  .tooltip-body {
    max-width: 40vw;
    width: fit-content;
    // max-height: 600px;
    height: 100%;

    font-weight: normal;

    overflow-y: auto;

    color: #333;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    position: relative;

    &.click {
      // padding: 20px;
    }

    &.hover {
      // padding: 20px;
      font-size: 12px;

      color: #000;
    }

    /* width */
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .arrow {
    z-index: 100000;

    position: fixed;
    width: 10px;
    height: 10px;

    &.right {
    }
    &.left {
    }
    &.top {
    }
    &.bottom {
    }

    transform-origin: center;
    transform: rotateZ(45deg);
  }

  .top-container {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .close-btn {
      // margin: 10px;
      padding: 5px;

      font-size: 18px;
      font-weight: 300;

      display: flex;
      justify-content: center;
      align-items: center;

      user-select: none;
      cursor: pointer;
    }

    .title {
      margin: 0;
      padding: 0;

      font-size: 24px;
    }
  }
}
