
.container {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;

    
    .content {
        width: 100%;
        height: 100%;

        overflow: auto;
    }
}