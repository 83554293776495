@import "src/colorVariables.scss";
.infoscreen-standard-filters {
  height: 100%;
  display: flex;
  flex-direction: column;
  header {
    display: flex;
    padding: 20px 40px;
    align-items: center;
    position: relative;
    flex: 0 0 90px;
    .close {
      float: left;
      opacity: 1;
      cursor: pointer;
      svg {
        font-size: 28px;
      }
    }
    .title {
      // flex-grow: 1;
      text-align: center;
      font-size: 30px;
      font-weight: bold;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .final-options {
      height: 100%;
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      button {
        background-color: transparent;
        height: 100%;
        border: none;
        &.save {
          margin-left: 50px;
          background-color: $blue;
          width: 160px;
          font-weight: bold;
          color: white;
          border-radius: 2px;
          &:hover {
            background-color: rgba($color: $blue, $alpha: 0.9);
          }
        }
        &.cancel {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  .isf-content {
    padding: 70px 40px 0;
    flex: 1 1 0;
    overflow: auto;
    h2 {
      font-size: 30px;
      font-weight: bold;
    }
    p.details {
      font-size: 18px;
      margin-bottom: 40px;
    }
    .list-containers {
      display: flex;
      .list {
        flex: 1 1 0;
        .list-title {
          margin-bottom: 15px;
        }
        .choice {
          width: fit-content;
          user-select: none;
          label {
            cursor: pointer;
            display: flex;
            align-items: center;
            .choice-check {
              margin-bottom: 0;
            }
            .text {
              margin-left: 12px;
            }
            .region-located-here-info {
              font-style: italic;
              margin-left: 8px;
            }
          }
          &.disabled {
            color: $mid;
            .vfi-checkbox-2 {
              background-color: $mid;
            }
          }
          // &.select-all{
          //     .text{
          //         font-weight: 600;
          //     }
          // }
        }
      }
    }
  }
}
