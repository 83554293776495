@import "src/colorVariables.scss";
.one-media-with-subtext {
  .image-upload-block {
    height: 250px;
  }
  .vfi-text-area {
    .ck-content {
      font-size: 13px;
      line-height: 18px;
      color: $text-color;
      padding: 10px 10px;
      height: 80px;
    }
  }
}
