@import "src/colorVariables.scss";

.page-location {
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    margin: 0 40px;
    align-items: center;
    flex-basis: 80px;
    margin-bottom: 20px;

    .close {
      cursor: pointer;
      opacity: 1;
      float: unset;
    }

    .header-text {
      flex-grow: 1;
      font-size: 30px;
      font-weight: bold;
      padding-left: 40px;
    }

    .save-changes,
    .discard-changes,
    .edit {
      font-weight: bold;
      font-size: 14px;
      padding: 5px;
      margin: 5px;
      cursor: pointer;

      &:hover {
        background-color: $light;
      }
    }

    .discard-changes {
      color: $red;
    }
  }

  .pl-content {
    width: 100%;
    display: flex;
    // height: calc(100% - 100px);
    flex: 1 1 0;
    overflow: auto;

    .left-side {
      width: 255px;
      display: flex;
      flex-direction: column;

      .page-image {
        .one-media {
          height: unset;

          .image-container {
            border: none;

            .missing-image {
              min-width: unset;
              min-height: calc(255px * 9 / 16);
            }
          }
        }

        img {
          width: 100%;
          height: calc(255px * 9 / 16);
          object-fit: cover;
        }
      }

      .display-list {
        flex-grow: 1;
        background-color: rgba(77, 77, 77, 0.12);

        .display-at {
          font-size: 12px;
          margin: 24px 0 10px 30px;
          color: $mid;
          font-weight: 600;
        }

        .choice {
          padding: 4px 0 6px 30px;
          transition: ease-out 0.2s;
          user-select: none;
          cursor: pointer;

          &:hover {
            background-color: rgba(77, 77, 77, 0.24);
          }

          &.selected {
            background-color: black;
            color: white;
          }
        }
      }
    }

    .right-side {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      // height: 100%;
      overflow: auto;

      .top-content {
        display: flex;
        flex-basis: calc(255px * 9 / 16);
        // background-color: azure;
        align-items: center;
        border-bottom: 1px solid #eeeeef;

        .page-title {
          flex-grow: 1;
          font-size: 30px;
          font-weight: bold;
          margin-left: 40px;
        }

        .languages {
          display: flex;
          margin-right: 40px;

          .lang {
            width: 70px;
            height: 50px;
            font-size: 14px;
            border-bottom: 1px solid $mid;
            margin: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            user-select: none;

            &.selected {
              border-bottom: 1px solid black;
              font-weight: bold;
            }
          }
        }
      }

      .lower-content {
        flex-grow: 1;

        .table-websites {
          margin-top: 24px;
          margin-left: 40px;

          // width: calc(100% - 80px);
          th {
            font-size: 12px;
            color: $mid;
          }

          // td,th{
          // border: 1px solid;
          // }
          .italic {
            font-style: italic;
            // color: $mid;
          }

          td.site {
            font-weight: 600;
            user-select: none;
            position: relative;
            width: 300px;
          }

          .logo {
            width: 24px;
            height: 24px;
            object-fit: contain;
            filter: brightness(0);
            opacity: 0.2;
          }

          .page-directory {
            display: flex;
          }

          .arrow {
            font-size: 10px;
            margin: 0 10px;
          }

          .edit-directory {
            margin-left: 20px;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover {
            }
          }

          .table-gap {
            height: 40px;
          }
        }

        .sites-shared-to {
          // margin-left: 40px;
          // margin-top: 10px;
          td {
            padding-top: 10px;
          }

          .site {
            display: flex;

            // margin-top: 10px;
            // justify-content: center;
            label {
              cursor: pointer;
              margin-bottom: 0;
              display: flex;
            }

            .site-check {
              margin-right: 10px;
              position: absolute;
              left: -30px;
            }

            .site-text {
              transition: ease-out 0.2s;
              color: $mid;
            }

            &.selected {
              .site-text {
                color: black;
              }
            }

            .slug-error {
              color: $red;
              font-size: 10px;
              margin-top: 6px;
              margin-left: 10px;
            }
          }
        }

        &:not(.in-edit) {
          .sites-shared-to {
            .site {
              pointer-events: none;

              .site-check {
                visibility: hidden;
              }

              .slug-error {
                visibility: hidden;
              }
            }
          }
        }
      }

      .infoscreens-content {
        height: 100%;
        padding: 24px 40px;
      }

      .sub-text {
        font-size: 12px;
        color: $mid;
        font-weight: 600;
      }
    }
  }
}
