.mainContainer {
  .configHeader {
    position: relative;
    .expContrBtn {
      display: inline-block;
      position: absolute;
      top: 10px;
      right: 0;
      cursor: pointer;
      font-size: 26px;
    }
  }
  .tableContainer {
    &.hidden {
      display: none;
    }

    table {
      width: 100%;

      tbody {
        tr:first-of-type {
          td {
            padding-bottom: 50px;
          }
        }
      }
      .insertBtn {
        cursor: pointer;
      }
    }
  }
}
