@font-face {
  font-family: "Libre Baskerville Bold";
  src: local("Libre Baskerville Bold"),
    url(./fonts/LibreBaskerville-Bold.ttf) format("tff");
}
@font-face {
  font-family: "Libre Baskerville Italic";
  src: local("Libre Baskerville Italic"),
    url(./fonts/LibreBaskerville-Italic.ttf) format("tff");
}
