@import "src/colorVariables.scss";
.whatson-filter-show {
  .select-whatson {
    cursor: pointer;
    user-select: none;
    &:hover {
      color: $green;
    }
  }
}
