
@mixin flex-column() {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
};


.container {

    @include flex-column;

    opacity: 0;

    transition: 0.1s opacity;

    &.loaded {
        opacity: 1;
    }

    &.requesting {
        opacity: 0.8;

        pointer-events: none;
    }

    .input_container {

        width: 100%;

        @include flex-column;

        .physical_location {
            padding-bottom: 5px;

            font-family: "Source Sans Pro";
            font-size: 12px;
            line-height: 13px;

            color: #a2a2a2;

            user-select: none;
        }

        .input_address {
            width: 100%;
        }
    }


    .map_container {

        width: 100%;
        height: 320px;

        position: relative;

        .map {
            width: 100%;
            height: 100%;
        }

        .loader_wrapper {

            font-weight: bold;
            
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            gap: 10px;

            position: absolute;
            top: 10px;
            left: 10px;

            .spinner_wrapper {

                @keyframes spin {
                    0% {transform: rotate(0deg);}
                    100% {transform: rotate(360deg);}
                }

                display: flex;
                justify-content: center;
                align-items: center;
                
                .spinner {
                    animation-name: spin;
                    animation-duration: 2s;
                    animation-timing-function: linear;
                    animation-iteration-count: infinite;
                }
            }
        }
    }

}