@import "src/colorVariables.scss";
.icon-settings.edit-module {
  width: 430px;
  height: 295px;
  position: relative;

  .top {
    position: relative;
    .close {
      position: absolute;
      right: 10px;
      font-size: 18px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  .info {
    font-family: "Libre Baskerville";
    font-style: italic;
    border: 1px solid;
    position: absolute;
    right: 25px;
    transform: translateY(-2px);
    border-radius: 100%;
    width: 23px;
    height: 23px;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
  .select-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 30px;
    .label {
      // margin: auto;
    }
    .wrap-icon {
      display: flex;
      // justify-content: center;
      align-items: center;
      .icon {
        position: relative;
        height: 60px;
        min-width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(162, 162, 162, 0.2);
        img {
          height: 100%;
        }
        .add:hover {
          background-color: rgba(255, 255, 255, 0.8);
        }
        .add {
          display: flex;
          align-items: center;
          height: 40px;
          width: 40px;
          justify-content: center;

          cursor: pointer;
          position: absolute;
        }
      }
    }
  }
}
