.whatson-sidebar {
  h1 {
    margin-bottom: 30px;

    font-size: larger;
    font-weight: 700;
    padding: 40px 15px 0 25px;
  }
  .small-gray {
    padding-bottom: 8px;

    text-transform: uppercase;

    font-size: 12px;
    margin: 20px 0 0 25px;
		opacity: 0.98;
		color: #A2A2A2;
  	font-weight: 600;
  	line-height: 13px;
  }

  // List option stylings..
  .list-parent {
    height: 200px;
  }
  .list-option {
    padding: 5px 25px;
    cursor: pointer;
    color: #313131;
  }
  .list-option:hover {
    background-color: rgba($color: #efefef, $alpha: 1);
  }
  .list-option.selected {
    background-color: lightgray;
  }
}
