@import "src/colorVariables.scss";

// .whatson-content{
.whatson-navigation {
  // float: right;
  // margin-top: -6px;
  position: absolute;
  z-index: 2;
  right: 80px;
  top: 139px;
  // background: white;
  padding-top: 32px;

  .preview-container,
  .save-draft-container,
  .undo,
  .redo,
  .back,
  .forward {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
  }

  .save-draft-container,
  .undo,
  .redo,
  .back,
  .forward {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
  }

  .undo,
  .redo {
    color: $mid;
    margin-left: 20px;
  }
  .controls {
    display: inline-block;
    margin-left: 20px;
    .back,
    .forward {
      margin-left: 20px;
      width: 90px;
      height: 50px;
      border-radius: 2px;
      position: relative;

      span {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 17px;
      }

      &.whatson-front {

        width: 150px;

        display: flex;
        justify-content: center;
        align-items: center;

        span {
          position: initial;

          left: initial;
          top: initial;

          transform: initial;
        }
      }
    }
    .back {
      background-color: $light;
      color: $dark;
    }
    .forward {
      background-color: $blue;
      color: white;
    }
  }

  .save-draft-container,
  .preview-container {
    background-color: white;
    transition: ease-in-out 0.2s;
    border-radius: 2px;
    .save-draft,
    .preview {
      font-size: 14px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      // font-size: 17px;
      width: 100%;
      text-align: center;
    }
    &:hover {
      background-color: $lighter;
      .save-draft,
      .preview {
        //color: $light-blue;
        text-decoration: underline;
      }
    }

    margin-left: 20px;
    width: 90px;
    height: 50px;
    border-radius: 2px;
    position: relative;
    background-color: white;
  }

  .hidden {
    display: none;
  }

  @media ($phone) {
    padding-top: 0;
    right: 20px;
    top: 149px;
    .controls {
      .back,
      .forward {
        // height: 40px;
        span {
          font-size: 1rem;
        }
      }
    }
  }
}
// }
