@import "src/colorVariables.scss";
.palette-options {
  height: 630px;
  width: 430px;
  display: flex;
  flex-direction: column;
  user-select: none;
  margin: 0;
  #handle {
    cursor: move;
    height: 28px;
    display: flex;
    align-items: center;
    margin-left: 8px;
    svg {
      margin: 0 1px;
    }
  }
  .close {
    top: 4px;
    right: 4px;
  }
  .top {
    background-color: $light;
    display: flex;
    justify-content: center;
    height: 26px;
    flex-shrink: 0;
    .top-choice {
      padding: 0 22px;
      font-size: 13px;
      font-weight: bold;
      letter-spacing: 1px;
      user-select: none;
      cursor: pointer;
      color: $mid;
      height: 100%;
      display: grid;
      place-items: center;

      &.active {
        color: black;
      }
    }
  }
  .content {
    width: 100%;
    .palette {
      gap: 4px;
      display: flex;
      padding: 4px;
      flex-wrap: wrap;
      > div {
        width: calc(50% - 2px);
        height: 120px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      > div:hover {
        background-color: rgba($color: $mid, $alpha: 0.1);
        cursor: pointer;
      }
      .list {
        > div {
          background-color: rgba($color: $mid, $alpha: 0.1);
          display: flex;
          margin: auto;
          justify-content: space-between;
          align-items: center;
          width: 70%;
          font-family: "Libre Baskerville Bold";
          padding: 10px;
        }
      }
      .image {
        > div {
          width: 60%;
          height: 70%;
          position: relative;
          margin: auto;
          img {
            filter: brightness(1.1);
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}
