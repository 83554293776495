@import "src/colorVariables.scss";
@import "src/fonts.scss";
.my-whatson-wrap {
  display: flex;
  height: 100%;
  .region-select {
    width: 100%;
  }
  .views-wrap {
    display: flex;
    align-items: center;
    width: 100%;
    .multi-range-slider {
      height: 20px;
      position: relative;
      width: 100%;
      padding: 0;
      .thumb {
        width: 100%;
      }
      .slider {
        width: 100%;
      }
    }
  }
  .my-whatson {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-grow: 1;
    padding-left: 20px;
    .header {
      flex-basis: 110px;
      flex-shrink: 0;
      margin-top: 30px;
      // border-bottom: 1px solid;
      // background-color: orange;

      .left,
      .right {
        display: flex;
        align-items: center;
        // height: 100%;
      }
      .left {
        justify-content: space-between;
        flex-wrap: wrap;
        padding-left: 10px;
      }
      .left:nth-child(2) {
        padding: 20px 0 20px 10px;
      }
      .right {
        justify-content: space-evenly;
      }
      .title {
        font-weight: 700;
        font-size: 30px;
      }
      .search {
        input {
          min-width: 340px;
        }
      }
      .new-place {
        color: #fff;
        cursor: pointer;
        display: inline-block;
        font-size: 17px;
        font-weight: 600;
        height: 50px;
        width: 160px;
        margin-right: 20px;
        background-color: $blue;
        justify-content: center;
        display: flex;
        align-items: center;
      }

      .star-rating {
        margin-left: 10px;
        .star-title {
          font-weight: 600;
          font-size: 14px;
        }
        .stars {
          white-space: nowrap;
        }
        .sub-selected {
          font-size: 12px;
          color: $mid;
        }
      }

      .views-wrap,
      .bookings-wrap {
        position: relative;
        height: 60px;
        display: flex;
        flex-direction: column;
        .text {
          padding-left: 15px;
          font-size: 14px;
          color: $mid;
          margin-bottom: 8px;
          color: $text-color;
          font-weight: 600;
        }
      }
      .search-n-filter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
      .search-button,
      .boxes-button,
      .map-button {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
          background-color: $light;
        }
      }
      .search-button,
      .filter-button {
        margin-left: 5px;
      }
      .filter-button {
        margin-right: 20px;
        background-color: #fff;
        border: 1px solid hsla(0, 0%, 64%, 0.1);
        border-radius: 20px;
        color: #000;
        cursor: pointer;
        padding: 9px 15px 9px 20px;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        svg {
          margin-left: 15px;
        }
        &.active {
          background-color: $blue;
          color: white;
        }
      }
    }
    .content {
      // flex-grow: 1;
      flex: 0 1 auto;
      width: unset;
      display: flex;
      overflow: auto;

      .places-list {
        flex-basis: 360px;
        // border-right: 1px solid;
        overflow: auto;
        .places-list-wrap {
          height: 100%;
          .no-places {
            font-size: 24px;
            padding-top: 20px;
            display: flex;
            justify-content: center;
            color: $mid;
            font-weight: bold;
          }
          .place {
            height: 120px;
            display: flex;
            align-items: center;
            // border-bottom: 1px solid;
            cursor: pointer;
            user-select: none;
            &.selected {
              background-color: $light;
            }
            .image-wrap {
              padding: 10px;
              flex-basis: 150px;
              height: 100%;
              flex-shrink: 0;
              .one-media {
                height: 100%;
                img {
                  // object-fit: contain;
                }
              }
              // img{
              //     width: 100%;
              //     height: 100%;
              //     object-fit: contain;
              // }
            }
            .place-content {
              flex-grow: 1;
              color: $text-color;
              .place-name {
                font-size: 22px;
                line-height: 18px;
                font-weight: 600;
                font-family: "Libre Baskerville Italic";
              }
              .sub-info {
                padding-bottom: 10px;
              }
              .place-info {
                display: flex;
                .tours,
                .views {
                  display: flex;
                  align-items: center;
                  margin-right: 8px;
                  .icon {
                    display: flex;
                    justify-content: center;
                  }
                  .amount {
                    margin-left: 6px;
                    text-align: center;
                  }
                }
              }
            }
          }
        }
      }
      .map-wrap {
        background-color: #cad2d3;
        flex-grow: 1;

        .mapboxgl-popup-tip {
          border-bottom-color: black;
        }
        .mapboxgl-popup-content {
          max-width: 380px;
          max-height: 450px;
          overflow: auto;
          position: static;
          padding: 0;
          box-shadow: unset;
          .top {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 30px;
            z-index: 1;
            .close {
              color: white;
              opacity: 1;
              cursor: pointer;
              margin-top: 15px;
              margin-right: 10px;
              background-color: rgba($color: #000000, $alpha: 0.1);
              width: 30px;
              height: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          .one-media {
            width: "100%";
            height: 200px;
            .image-container {
              border: 0;
            }
          }
          .text {
            padding: 10px;
            h2 {
              margin-top: 10px;
            }
            p {
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .bottom-buttons {
              display: flex;
              justify-content: flex-end;
              user-select: none;
              .button {
                padding: 4px 10px;
                font-size: 14px;
                font-weight: 600;
                cursor: pointer;
              }
              .edit {
                color: white;
                background-color: $blue;
              }
            }
          }
        }
      }
    }
  }
  .right-side-info {
    .place-categories {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      .option {
        display: flex;
        .checkbox {
          margin-right: 10px;
        }
        .text {
          color: $mid;
        }
      }
    }
  }
}
