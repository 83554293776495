@import "src/colorVariables.scss";

.related-and-text {
  margin-top: 20px;
  display: flex;
  width: 824px;
  margin: auto;
  gap: 80px;
  .related-feature {
    line-height: initial;
    cursor: pointer;

    &.active {
      pointer-events: all;
    }

    min-width: 160px;
    margin-left: -190px;

    .wrap-related-feature {
      position: absolute;
      z-index: 1;
      max-width: 190px;
      hr {
        max-width: 160px;
        margin: 0;

        &.bottom {
          margin-top: 15px;
        }
      }
      p.top {
        margin: 15px 0;
        font-size: 14px;
        font-weight: bold;
      }
      img {
        height: 112px;
        width: 160px;
        object-fit: cover;
      }
      .image-error {
        color: $red;
        font-size: 10px;
        margin-bottom: 15px;
      }
      .title {
        font-family: "Libre Baskerville";
        font-size: 17px;
      }
    }
  }
  .related-text {
    width: 724px;
  }
}
.tablet,
.phone {
  .module {
    .related-and-text {
      flex-direction: column;
      .related-feature {
        margin-left: 6px !important;
        .wrap-related-feature {
        }
      }
      .related-text {
        > div {
          margin-left: unset;
        }
      }
    }
  }
}
.tablet {
  .module {
    .related-and-text {
      .related-feature {
        margin-left: 12px !important;
        .wrap-related-feature {
        }
      }
      .related-text {
        > div {
          margin-left: 12px;
        }
      }
    }
  }
}
