.custom-calendar {
  button {
    background-color: transparent;
    border: none;
    font-size: 15px;
  }
  .react-calendar__navigation {
    display: block;

    .react-calendar__navigation__prev2-button,
    .react-calendar__navigation__next2-button {
      display: none;
    }

    .react-calendar__navigation__label {
      text-align: left;
      pointer-events: none;
    }

    .react-calendar__navigation__prev-button::before {
      font-family: "Font Awesome 5 Pro";
      font-weight: 100;
      content: "\f053";
      font-size: 14px;
      visibility: visible;
    }
    .react-calendar__navigation__next-button::before {
      font-family: "Font Awesome 5 Pro";
      font-weight: 100;
      content: "\f054";
      font-size: 14px;
      visibility: visible;
      margin-left: 6px;
    }
    .react-calendar__navigation__prev-button {
      order: 1;
      visibility: hidden;
    }
    .react-calendar__navigation__next-button {
      order: 2;
      visibility: hidden;
    }
  }
  .react-calendar__viewContainer {
    .react-calendar__month-view {
      .react-calendar__month-view__weekdays {
        font-size: 12px;
        flex-wrap: wrap;
        flex-shrink: 1;
        margin-top: 10px;
        .react-calendar__month-view__weekdays__weekday {
          display: flex;
          justify-content: center;
          abbr {
            text-decoration: none;
            cursor: default;
          }
        }
      }
    }
    .react-calendar__month-view__days {
      button {
        height: 38px;
        margin: 0px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        &.react-calendar__tile--active {
          abbr {
            background-color: black;
            color: white;
            width: 33px;
            height: 33px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100px;
          }
        }
        abbr {
          font-size: 15px;
        }
      }

      .react-calendar__month-view__days__day--neighboringMonth {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
}
