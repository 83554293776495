@import "src/colorVariables.scss";
.edit-module.save-template {
  height: 190px;
  .content {
    margin: 0 20px;
    overflow: visible;
    .save {
      .save-name {
        // display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
      }
      p.label {
        flex-basis: 120px;
        margin-bottom: 2px;
      }
      .the-input {
        flex-grow: 1;
      }
      .save-button {
        background-color: $blue;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 40px;
        margin-top: 20px;
        margin-left: auto;
        cursor: pointer;
        transition: ease-out 0.2s;
        &:hover {
          background-color: $darker-blue;
        }
      }
    }
  }
  .blur {
    display: none;
    &.active {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba($color: black, $alpha: 0.25);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
    }
  }
  .status {
    position: absolute;
    bottom: 10px;
    &.red {
      color: $red;
    }
    &.green {
      color: $green;
    }
  }
}
