.media-with-subtext-and-text {
  .vfi-text-area {
    min-width: unset;
    max-width: 400px;
  }

  .vfi-text-area {
    order: 3;
  }

  .divider {
    order: 2;
  }

  .one-media-with-subtext {
    order: 1;
    justify-content: right;
  }

  .media-text-content {
    display: flex;
    gap: 80px;
    justify-content: center;
    .text-content {
      max-width: 480px;
      .ck-content {
        // p{
        //   // width: 414px;
        // }
      }
    }
  }

  .one-media-with-subtext {
    display: flex;
    // justify-content: right;
    align-items: center;
    margin-left: -236px;
    margin-bottom: 12px;
    div {
      max-width: 400px;
      width: 100%;
    }
    .one-media {
      height: 250px;
      width: 100%;
      max-width: 400px;
      margin: 0;
    }
    .vfi-text-area {
      .ck-content {
        p {
          font-size: 13px !important;
          letter-spacing: 0.19px !important;
          line-height: 18px !important;
        }
      }
    }
  }
  &.swapped {
    .vfi-text-area {
      order: 1;
    }

    .divider {
      order: 2;
    }

    .one-media-with-subtext {
      order: 3;
      // justify-content: left;
      margin-left: unset;
    }
    .one-media-with-subtext {
      margin-right: -236px;
    }
  }
}

.phone,
.tablet {
  .media-with-subtext-and-text {
    margin: 0 !important;
    flex-wrap: wrap;

    div {
      margin: auto;
    }

    .left-spacer {
      display: none;
    }

    .media-text-content {
      flex-direction: column;
      flex-wrap: nowrap;
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0 !important;
      gap: 0;
      .divider {
        display: none;
      }

      .text-content {
        .ck-content {
          width: 100%;
        }
      }

      .text-content,
      .one-media-with-subtext {
        flex: unset !important;
        max-width: 100% !important;
      }
    }

    .vfi-text-area {
      order: 3;
    }
    .divider {
      order: 2;
    }
    .one-media-with-subtext {
      order: 1;
    }
  }
}
.tablet {
  .media-with-subtext-and-text {
    .media-text-content {
      max-width: 724px;
      flex-direction: column;
    }
  }
}
