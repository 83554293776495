@import "src/colorVariables.scss";
.page-choose {
  height: 100%;

  .headlineWrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;
    border-bottom: 1px solid #eeeeef;

    .headline {
      margin: 0;

      opacity: 0.98;
      color: #000000;
      font-family: "Source Sans Pro";
      font-size: 30px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 38px;
      text-align: center;
    }
  }

  .close {
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;

    font-size: 28px;

    cursor: pointer;
    height: 28px;
    width: 17.5px;
    color: #000;
    opacity: 1;

    p.icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      width: 17.5px;
      height: 28px;
    }
  }

  .main-pages {
    &.select-page {
      .header {
        .left {
          display: none;
        }
        .right {
          position: absolute;
          top: -56px;
          right: 0;

          display: flex;
          justify-content: right;
          align-items: center;
          gap: 30px;

          padding-right: 40px;

          .pages-show-option {
            margin: 0;
          }
        }
      }
    }

    .sites-pages-wrap {
      .sites-headline {
        padding-top: 30px;
      }
      .pages-headline {
        margin-top: 30px;
      }
    }
    .main-pages-list-view {
      .listHeaderWrapper {
        display: flex;
        justify-content: left;
        align-content: center;
        margin-top: 30px;
        margin-bottom: 30px;
        flex-direction: row-reverse;
        column-gap: 50px;
      }
      .list-header {
        align-items: center;

        position: unset;
      }
      .search-box {
        margin-top: 0px;
      }

      .pages-table-wrap {
        margin-top: 0;
        height: calc(100% - 44px);
      }
    }
  }
  .move-page-save {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: $blue;
    color: white;
    padding: 15px 30px;
    font-weight: 600;
    font-size: 17px;
    user-select: none;
    cursor: pointer;
  }
}
