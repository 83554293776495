html,
body,
#root {
  height: 100%;
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  /* font-family: 'Source Sans Pro', sans-serif; */
  /* -webkit-font-smoothing: antialiased; */
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: "VFIFonts";
  src: local("VFIFonts"),
    url(./fonts/Typodermic-LibelSuitBk-Regular.otf) format("opentype");
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
