@import "src/colorVariables.scss";

.media-grid {
  &.media-grid-instagram {
    max-width: 1360px;

    &.media-grid-instagram {
      .instagram-header {
        max-width: 1440px;
        margin: auto;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        svg {
          height: 25px;
          width: 25px;
        }

        .instagram-headline {
          max-width: 700px;
          width: 100%;
          margin: 22.5px auto;
          .vfi-div-input {
            width: 100%;
            color: rgba(55, 55, 55, 0.2);
            font-family: "Libre Baskerville";
            font-size: 30px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 35px;
            text-align: center;

            word-break: break-word;
            background-color: rgb(245, 245, 245);
          }
        }
      }
    }

    .grid-container {
      display: grid;
      grid-auto-rows: 453px;

      &.grid-amount1-pre1 {
        grid-template-areas: "one";
      }

      // TWO ----------------
      &.grid-amount2-pre1 {
        grid-template-areas: "one two";
        &.swapped {
          grid-template-areas: "two one";
        }
      }

      // THREE --------------
      &.grid-amount3-pre1,
      &.grid-amount4-pre1 {
        grid-auto-rows: 453px;

        .item4 {
          display: none;
        }

        grid-template-areas: "one two thr";
        &.swapped {
          grid-template-areas: "thr two one";
        }
      }

      // FOUR --------------

      // FIVE --------------
      &.grid-amount5-pre1,
      &.grid-amount6-pre1,
      &.grid-amount7-pre1,
      &.grid-amount8-pre1 {
        grid-auto-rows: 340px;

        .item6,
        .item7,
        .item8,
        .item9,
        .item10 {
          display: none;
        }

        grid-template-areas:
          "one two two thr"
          "fou two two fiv";
        &.swapped {
          grid-template-areas:
            "thr two two one"
            "fiv two two fou";
        }
      }

      // NINE --------------
      &.grid-amount9-pre1,
      &.grid-amount10-pre1 {
        grid-auto-rows: 272px;

        .item10 {
          display: none;
        }

        grid-template-areas:
          "one one two thr fou"
          "one one fiv six six"
          "sev eig nin six six";
        &.swapped {
          grid-template-areas:
            "fou thr two one one"
            "six six fiv one one"
            "six six nin eig sev";
        }
      }
    }
  }
}

.phone {
  .media-grid {
    &.media-grid-instagram {
      .grid-container {
        // TWO ----------------
        &.grid-amount2-pre1 {
          grid-template-areas:
            "one"
            "two";
        }

        // THREE --------------
        &.grid-amount3-pre1,
        &.grid-amount4-pre1 {
          grid-template-areas:
            "one"
            "two"
            "thr";
        }

        // FIVE --------------
        &.grid-amount5-pre1,
        &.grid-amount6-pre1,
        &.grid-amount7-pre1,
        &.grid-amount8-pre1 {
          grid-template-areas:
            "one fou"
            "two two"
            "two two"
            "thr fiv";
        }

        // NINE --------------
        &.grid-amount9,
        &.grid-amount10 {
          grid-template-areas:
            "sev one one"
            "eig one one"
            "nin fiv two"
            "six six thr"
            "six six fou";
        }
      }
    }
  }
}
