@import "src/colorVariables.scss";
.side-description.edit-module {
  width: 430px;
  height: 595px;
  position: relative;

  .top {
    position: relative;
    .close {
      position: absolute;
      right: 10px;
      font-size: 18px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  .option.description {
    height: unset;
  }

  .info {
    font-family: "Libre Baskerville";
    font-style: italic;
    border: 1px solid;
    position: absolute;
    right: 25px;
    transform: translateY(-2px);
    border-radius: 100%;
    width: 23px;
    height: 23px;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
  // .content{
  //     .option{
  //         &.link{
  //             height: unset;
  //             .label{
  //                 &.button{
  //                     cursor: pointer;
  //                 }
  //             }
  //             .value{
  //                 width: 100%;
  //                 margin-right: 0;
  //                 .vfi-input-text{
  //                     padding: 30px 0;
  //                 }
  //             }
  //         }
  //         &.full{
  //             .label,.value{
  //                 width: 100%;
  //                 margin-right: 0;
  //                 & > div{
  //                     width: 100%;
  //                 }
  //                 .css-yk16xz-control, .css-1pahdxg-control{
  //                     .css-g1d714-ValueContainer{
  //                         justify-content: flex-start;
  //                     }
  //                 }
  //             }
  //         }
  //         &.fifty-fifty{
  //             justify-content: space-evenly;
  //             padding: 30px 0;
  //             & > div{
  //                 flex-basis: 50%;
  //                 &:first-child{
  //                     background-color: black;
  //                     margin-right: 20px;
  //                     text-align: center;
  //                     padding: 10px;
  //                     font-size: 17px;
  //                     font-weight: bold;
  //                     color: white;
  //                     border-radius: 2px;
  //                 }
  //                 &:last-child{
  //                     border: 2px solid black;
  //                     .vfi-input-text{
  //                         padding: 0;
  //                         height: 44px;
  //                         label{
  //                             height: 100%;
  //                             input{
  //                                 height: 100%;
  //                             }
  //                         }
  //                     }
  //                 }
  //             }
  //         }
  //     }
  // }
}
