.container {
  z-index: 1000;

  width: 100%;
  height: 100%;

  background-color: rgba(100, 100, 100, 0.1);

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;

  opacity: 0;

  transition: 0.2s;

  &.loaded {
    opacity: 1;
  }

  .content {
  }
}
