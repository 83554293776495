@import "src/colorVariables.scss";
.new-password {
  .content {
    // height: 570px;
    #Login {
      .wrap-container {
        button:disabled {
          background-color: $mid;
          cursor: unset;
          opacity: 1;
        }
      }
    }
  }
  .show-password-label {
    display: flex;
  }
  .errors {
    .error {
      color: $red;
      font-size: 14px;
      text-align: left;
    }
  }
}
