@import "src/colorVariables.scss";

$xxxl: "max-width: 2019px";
$xxl: "max-width: 1535px";
$xl: "max-width: 1279px";
$lg: "max-width: 1279px";
$md: "max-width: 767px";
$sm: "max-width: 639px";

$color-border-light: rgba(162, 162, 162, 0.2);

$vfi-dark: #373737;

@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap");
$LibreBasker: "Libre Baskerville";
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700;800&display=swap");
$sansPro: "Source Sans Pro";

.page-section-content {
  &.lg {
    .page-footer-display {
      .footer {
        .footer-container {
          padding: 40px;
          .upper-foot-wrap {
            gap: 30px;
            height: fit-content;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .footer-logo {
              margin: 10px 0 0 0;
            }

            .social-media {
              .follow-us {
                display: none;
              }

              i {
                font-size: 28px;
              }
            }

            .other-sites {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              position: static;

              .text {
                padding: 0.3rem 0;

                display: block;
                position: static;
                left: none;
              }
            }
          }
        }

        .lower-foot {
          gap: 20px;
        }
      }
    }
  }

  &.md {
    .page-footer-display {
      .footer {
        .footer-container {
          .upper-foot-wrap {
            height: auto;
          }
        }

        .lower-foot {
          padding: 1.2rem 1rem;

          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  &.sm {
    .page-footer-display {
      .footer {
        .footer-container {
          .upper-foot-wrap {
            .other-sites {
              h4 {
                padding: 0.6rem 0;

                display: flex;
                flex-direction: column;

                a {
                  padding: 0.4rem 0;
                }

                & > *:not(a) {
                  display: none;
                }
              }

              p {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
