@import "src/colorVariables.scss";
.location-lock {
  width: 100%;
  .map-wrap {
    height: 500px;
    .overlays {
      .mapboxgl-marker {
        // top: 20px !important;
        // left: 4px !important;
        svg {
          height: 1.4em;
          color: $dark-blue;
        }
      }
    }
  }
  .contact-info {
    position: absolute;
    top: 0;
    z-index: 2;
    height: 100%;
    max-width: 1280px;
    width: 100%;
    margin: auto;
    left: 50%;
    transform: translateX(-50%);
    .wrap-info {
      height: 100%;
      background-color: white;
      width: calc((1280px / 3) - 44px);
      max-width: 100%;
      display: flex;
      flex-direction: column;
      padding: 40px;
      > div {
        height: 50%;
        display: flex;
        flex-direction: column;
        gap: 4px;
        .headline {
          margin-bottom: 10px;
          .ck-content {
            padding: 0;
          }
        }
        label {
          input {
            padding: 5px 10px;
            font-size: 0.8em;
            line-height: 0.8em;
          }
        }
        > div {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          > div {
            width: 100%;
          }
          ol {
            display: none;
          }
        }
        > div::before {
          font-family: "Font Awesome 5 Pro";
          font-size: 16px;
          font-weight: 100;
          width: 16px;
        }
        .location::before {
          content: "\f3c5";
        }
        .phone::before {
          content: "\f095";
        }
        .email::before {
          content: "\f0e0";
        }
      }
      .bottom {
        border-top: 1px solid rgba(162, 162, 162, 0.1);
        > div {
          height: 100%;
          > div {
            height: 100%;
          }
        }
        .ck-content {
          padding: 0;
          table {
            tr {
              td {
                padding: 0;
                border-top: 1px solid rgba(162, 162, 162, 0.1);
              }

              td:last-of-type {
                text-align: right;
              }
            }
          }
        }
      }
    }
  }
}
