.checkboxContainer {
  display: flex;
  gap: 1rem;

  margin: 0;

  label {
    margin: 0;
  }

  span {
    line-height: 22px;
  }

  .checkmark {
    display: inline-block;
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    box-shadow: inset 0px 0px 0px 2px #ebedf2;
    position: relative;
    transition: ease-in-out 0.2s;
    cursor: pointer;

    line-height: 22px;
    &.checked {
      background-color: black;
    }
  }

  .checkmark:hover {
    box-shadow: inset 0px 0px 0px 2px #cbcdd2;
  }
  input:checked ~ .checkmark {
    background-color: #000000;
    border-radius: 3px;
    box-shadow: none;

    .leftShorter {
      position: absolute;
      top: 10px;
      left: 4px;
      width: 6px;
      height: 2.5px;
      transform: rotate(45deg);
      background-color: white;
    }

    .rightLonger {
      position: absolute;
      top: 8px;
      left: 7px;
      width: 10px;
      height: 2.5px;
      transform: rotate(-45deg);
      background-color: white;
    }
  }
}
