@import "src/colorVariables.scss";
.link-to-edit.edit-module {
  width: 430px;
  height: 265px;
  .content {
    .option {
      &.link {
        height: unset;
        .label {
          &.button {
            cursor: pointer;
          }
        }
        .value {
          width: 100%;
          margin-right: 0;
          .vfi-input-text {
            padding: 30px 0;
          }
        }
      }
      &.full {
        .label,
        .value {
          width: 100%;
          margin-right: 0;
          & > div {
            width: 100%;
          }
          .css-yk16xz-control,
          .css-1pahdxg-control {
            .css-g1d714-ValueContainer {
              justify-content: flex-start;
            }
          }
        }
      }
      &.fifty-fifty {
        justify-content: space-evenly;
        padding: 30px 0;
        display: flex;
        flex-direction: row;
        & > div {
          flex-basis: 50%;
          &:first-child {
            background-color: black;
            margin-right: 20px;
            text-align: center;
            padding: 10px;
            font-size: 17px;
            font-weight: bold;
            color: white;
            border-radius: 2px;
          }
          &:last-child {
            border: 2px solid black;
            .vfi-input-text {
              padding: 0;
              height: 44px;
              label {
                height: 100%;
                input {
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
