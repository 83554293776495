.main .sidebar {
  // display: inline-block;
  // position: relative;
  // align-items: stretch;
  // height: 100%;
  // vertical-align: top;
  padding: 0;
  width: 18%;
  -moz-box-shadow: inset 0 0 10px #000000;
  -webkit-box-shadow: inset 0 0 10px #000000;
  box-shadow: inset -15px 0 15px rgba($color: #eaeaea, $alpha: 1);
  min-width: 200px;
  max-width: 255px;
  width: inherit;
}

.main .sidebar.collapsed {
  width: 0;
  overflow: hidden;
  visibility: hidden;
}
