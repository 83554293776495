@import "src/colorVariables.scss";
.vfi-text-area.big-first-letter {
  margin-top: 20px;
  .ck-content {
    *:first-child {
      &::first-letter {
        font-size: 60px;
        color: $blue;
        float: left;
        margin-top: 12px;
        margin-right: 4px;
        font-family: "Libre Baskerville";
        line-height: 50px;
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }
}
.phone {
  .vfi-text-area.big-first-letter {
    .ck-content {
      *:first-child {
        &::first-letter {
          font-size: 50px;
          line-height: 34px;
        }
      }
    }
  }
}
