@import "src/colorVariables.scss";
.forgot-password {
  height: 570px;

  .password-title {
    color: #373737;
    font-family: "Libre Baskerville";
    font-size: 25px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 25px;
    text-align: center;
  }

  .info {
    max-width: 410px;
    margin: auto;
    margin-top: 30px;
    text-align: left;
    margin-bottom: 42px;
    &.center {
      text-align: center;
    }
    .blue {
      color: $blue;
      font-weight: bold;
    }
  }

  .text-n-button {
    max-width: 450px;
    margin: auto;

    .vfi-input-text {
      margin-bottom: 20px;
      .errors {
        bottom: -10px;
      }

      input[type="email"] {
        padding: 15px 30px 15px 15px;
        background-color: $input-fill;
        border: none;
        border-radius: 2px;
        border: 2px solid transparent;
        position: relative;
        width: 100%;
      }
    }
  }

  button[type="submit"] {
    font-family: Helvetica, sans-serif;
    font-weight: 700;
    // font-size: calc(0.27548vw + 12.71074px);
    font-size: 17px;
    background-color: $color-button-blue;
    border-radius: 2px;
    transition: ease-in-out 0.2s;
  }
  button {
    color: white;
    padding: 16px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
  }
  button:hover {
    opacity: 0.8;
  }
  button:disabled {
    background-color: $mid;
    cursor: unset;
    opacity: 1;
  }

  .back {
    margin: auto;
    margin-top: 40px;
    text-align: left;
    max-width: 450px;
    color: $mid;
    user-select: none;
    cursor: pointer;
    svg {
      margin-right: 8px;
    }
  }
}
