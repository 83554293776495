@import "src/colorVariables.scss";

.orders {
	width: 100%;
	height: 100%;
	overflow: auto;
	display: flex;
	flex-direction: column;

	.top {
		padding: 40px;
		display: flex;
		flex-direction: column;
		position: sticky;
		top: 0;
		z-index: 2;
		background-color: #fafafa;
		gap: 30px;

		>div {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.title {
				display: flex;
				align-items: center;
				font-size: 30px;
				gap: 10px;

				.backBtn {
					cursor: pointer;
				}

				h1 {
					margin: 0;
					font-weight: bold;
				}
			}

			.rightSide {
				display: flex;
				gap: 20px;
				flex-wrap: nowrap;
				justify-content: right;

				button {
					border: none;
					background-color: transparent;
					padding: 5px 10px;
					font-weight: 600;

					&:hover {
						opacity: 0.75;
					}

					&:disabled,
					&[disabled] {
						opacity: 0.2;
					}

					&.editButton {
						background-color: rgba(162, 162, 162, 0.1);
					}

					&.discardButton {
						color: $red;
						font-weight: 500;

						&:hover {
							text-decoration: underline;
						}
					}

					&.saveButton {
						background-color: $blue;
						color: white;
					}

					&.open-pdf {
						background-color: white;
					}

					&.sendPdf {
						background-color: $blue;
						color: white;
					}

					&.sendPaymentWithEmail {
						background-color: $dark;
						color: white;
					}

					&.approve {
						background-color: $green;
						color: white;
					}

					&.deny {
						background-color: $red;
						color: white;
					}
				}
			}

			.search {
				width: 424px;
				position: relative;
				display: flex;
				align-items: center;

				input {
					padding: 15px 30px 15px 15px;
					background-color: rgba(162, 162, 162, 0.05);
					border: none;
					border-radius: 5px;
					border: 2px solid transparent;
					position: relative;
					width: 100%;
				}

				svg {
					position: absolute;
					right: 20px;
				}
			}
			.orderListCP{
				display: flex;
				flex-direction: row;
				gap: 20px;
			}
		}
	}

	.orderList {
		table {
			width: 100%;

			tr:hover {
				td {
					background-color: rgba(162, 162, 162, 0.1);
				}
			}

			tr {
				cursor: pointer;

				th {
					font-size: 14px;
					font-weight: 600;
					color: #a2a2a2;
					padding: 10px 20px;
					-webkit-user-select: none;
					user-select: none;
					transition: ease-in-out 0.2s;
				}

				th,
				td {
					padding: 10px 20px;
					border-bottom: 1px solid rgba(162, 162, 162, 0.1);
				}

				td:first-of-type,
				th:first-of-type {
					padding: 0 0 0 10px;
				}

				td {
					.status {
						font-weight: 600;
						font-size: 0.9rem;
						padding: 4px 8px;
						border-radius: 2px;

						&.pending {
							background-color: $blue;
							color: white;
						}

						&.manual {
							background-color: black;
							color: white;
						}

						&.approved,
						&.done {
							background-color: $green;
							color: white;
						}

						&.denied {
							background-color: $red;
							color: white;
						}

						&.ready {
							background-color: black;
							color: white;
						}
					}
				}
			}
		}
	}
}

.order {
	overflow: auto;
	background-color: #fafafa;

	// .top {
	//   height: 100px;
	//   border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
	//   top: 0;
	//   .close {
	//     font-size: 2rem;
	//     background-color: transparent;
	//     border: none;
	//   }
	// }
	.orderWrap {
		// height: 100%;
		// padding: 0px 60px;
		max-width: 1280px;
		margin: auto;
		position: relative;

		.orderContent {
			padding: 20px;
			// background-color: white;
			// margin-top: 40px;
			margin-bottom: 40px;

			.box {
				background-color: white;
				box-shadow: 0 0 10px rgba(162, 162, 162, 0.1);
				padding: 2.4rem;
				border-radius: 0.2rem;

				h3 {
					margin-bottom: 30px;
				}
			}

			.textInput {
				position: relative;

				label {
					position: absolute;
					left: 10px;
					top: 5px;
					font-size: 14px;
					color: #008dff;
				}

				input,
				textarea {
					background-color: #a3a3a311;
					border: 1px solid #a3a3a333;
					border-radius: 5px;
					padding: 24px 30px 6px 10px;
					width: 100%;

					&.noLabel {
						padding: 6px 10px;
					}
				}
			}

			.splitEvenly {
				display: flex;
				gap: 10px;

				>* {
					flex: 1 1 0;
				}
			}

			.smallLabel {
				font-size: 0.8rem;
				color: $mid;
			}

			.marginLeft {
				margin-left: 10px;
			}

			.marginRight {
				margin-right: 10px;
			}

			.marginBottom {
				margin-bottom: 10px;
			}

			.marginTop {
				margin-top: 10px;
			}

			.statusListWrap {
				max-width: 750px;

				.statusList {
					display: block;
					font-weight: 600;
					font-size: 0.9rem;

					.statusRow {
						max-width: 250px;
						display: flex;
						justify-content: space-between;
						margin-top: 15px;

						.status {
							margin-left: 10px;
							padding: 4px 8px;
							border-radius: 2px;

							&.pending {
								background-color: $blue;
								color: white;
							}

							&.manual {
								background-color: black;
								color: white;
							}

							&.approved,
							&.done {
								background-color: $green;
								color: white;
							}

							&.denied {
								background-color: $red;
								color: white;
							}

							&.ready {
								background-color: black;
								color: white;
							}
						}
					}
				}
			}

			.userInfoRow {
				display: flex;
				gap: 5px;

				margin-top: 10px;

				.userInfoWrap {

					// width: 1200px;
					.userInfo {
						min-width: 350px;
					}
				}

				.rightSideOfUserInfo {
					flex-grow: 1;
					padding-left: 10px;

					.pdf-button {
						background-color: $blue;
						border: none;
						color: white;
						font-size: 1.2rem;
						padding: 5px 10px;
						float: right;
					}
				}
			}

			.orders {
				display: grid;
				grid-template-columns: 30px 70px auto 100px 120px;
				// row-gap: 5px;
				column-gap: 10px;

				.ordersHead {
					// font-weight: 600;
					padding-bottom: 4px;

					&.orderPrice,
					&.orderPricePer {
						// text-align: center;
						display: block;
					}

					color: $blue;
				}

				.orderPrice,
				.orderPricePr,
				.orderTotalResult {
					display: flex;
					justify-content: space-between;

					.amount {
						flex-grow: 1;
						text-align: right;
						margin-right: 5px;
					}
				}

				.product,
				.service {
					display: flex;
					align-items: center;
					border-top: 1px solid $light;
					padding: 8px 0;
				}

				.orderFoot {
					margin-top: 20px;
					font-weight: 600;

					&.orderTotal {
						text-align: right;
					}

					&.orderTotalResult {
						.amount {
							text-decoration: underline double;
							text-underline-offset: 4px;
						}

						// display: none;
					}
				}
			}

			.ordersRow {
				margin-top: 10px;
				display: flex;
				gap: 10px;

				>div {
					flex-grow: 1;
				}

				.orderHistoryWrap {
					max-width: 600px;

					.orderHistory {
						display: grid;
						grid-template-columns:
							/*30px*/
							auto auto;

						>div {
							&.header {
								padding: 0 0 5px 0;
								color: $blue;
							}

							&.info {
								padding: 5px 0;
								border-top: 1px solid $light;
								padding-right: 10px;
							}
						}

						.icon {
							margin-right: 10px;
						}
					}
				}
			}

			.options-row {
				margin-top: 40px;
			}
		}
	}
}

.button {
	cursor: pointer;
}