@import "src/colorVariables.scss";
.whatson-leaflet {
  height: 100%;
  display: flex;

  .the-list {
    width: 0px;
    height: 100%;
    background-color: rgba(162, 162, 162, 0.1);
    box-shadow: inset -10px 0 20px -7px rgba(0, 0, 0, 0.1);
    position: relative;

    &.loaded {
      transition: ease-out 0.75s;
      width: 255px;
      .option {
        padding: 5px 0 5px 30px;
      }
    }

    .bg-1 {
      background-color: rgba(162, 162, 162, 0);
    }
    .bg-2 {
      background-color: rgba(162, 162, 162, 0.1);
    }
    .bg-3 {
      background-color: rgba(162, 162, 162, 0.2);
    }
    .bg-4 {
      background-color: rgba(162, 162, 162, 0.3);
    }
    .bg-5 {
      background-color: rgba(162, 162, 162, 0.4);
    }
    .bg-6 {
      background-color: rgba(162, 162, 162, 0.5);
    }
    .bg-7 {
      background-color: rgba(162, 162, 162, 0.6);
    }

    .whatson-table {
      max-height: 1055px;
      overflow-y: scroll;
      margin: 0 30px;
      th {
        padding: 0 15px 10px;

        font-size: 14px;
        font-weight: 600;
        color: #a2a2a2;
        opacity: 0.98;
        // padding: 10px 0;
        -webkit-user-select: none;
        user-select: none;
        transition: ease-in-out 0.2s;
        svg {
          margin-left: 9px;
        }
      }
      th.title,
      th.region {
        cursor: pointer;
      }
      th.region {
        width: 100%;
      }
      th.hearted {
        text-align: center;
      }
      th:hover {
        color: #626262;
      }
      th.sorted-by {
        color: black;
      }
      th.favourite {
        text-align: center;
      }
      th.check-select {
        text-align: center;
        label {
          margin: 0;
          padding: 0 0 0 10px;
        }
      }
      tbody {
        tr {
          &:hover {
            td {
              background-color: $mid-between;
            }
          }
          td {
            padding: 0 15px;

            td.region {
              width: 100%;
              min-width: 100px;
              max-width: 120px;
              .what-region {
                font-size: 15px;
                white-space: nowrap;
                max-width: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-right: 10px;
              }
            }
            // td.location {
            //     min-width: 100px;
            //     max-width: 120px;
            //     .location-sites {
            //         white-space: nowrap;
            //         text-overflow: ellipsis;
            //         overflow: hidden;
            //         width: 95%;
            //         font-size: 15px;
            //         color: $dark;
            //     }
            // }
          }
        }
      }
    }

    .title {
      font-size: 14px;
      font-weight: 600;
      color: #a2a2a2;
      opacity: 0.98;
      padding: 10px 0;
      -webkit-user-select: none;
      user-select: none;
      transition: ease-in-out 0.2s;
      .vfi-checkbox {
        margin: 0;
        cursor: pointer;
      }
    }

    .top-margin {
      height: 120px;
    }

    .option {
      font-size: 16px;
      padding: 0;
      white-space: nowrap;
      cursor: pointer;
      background-color: transparent;
      // transition: ease-in 0.1s;
      overflow: hidden;
      position: relative;
      &:hover {
        background-color: lightgray;
        box-shadow: inset -10px 0 20px -7px rgba(0, 0, 0, 0.1);
        .options-button {
          opacity: 1;
        }
      }

      .default-button {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
      .options-button {
        position: absolute;
        width: 20px;
        right: 10px;
        top: 50%;
        text-align: center;
        transform: translateY(-50%);
        border-radius: 2px;
        transition: ease-in-out 0.1s;
        &:hover {
          background-color: $mid;
        }
      }
      &.active {
        background-color: black;
        color: $light;
        box-shadow: -10px 0 20px inset rgba(255, 255, 255, 0.3);
      }
    }
  }

  .c-whatson-pages {
    flex-grow: 1;
    background-color: white;
    .the-list {
      width: unset;
      .option {
        &.active {
          background-color: $mid-between;
          color: $text-color;
        }
        .inside-option {
          pointer-events: none;
          display: flex;
          align-items: center;
          .title {
            margin-left: 20px;
          }
          img {
            width: 70px;
            height: 40px;
            object-fit: cover;
          }
          label {
            margin: 0;
          }
        }
        .checkbox {
          flex-grow: 1;
          display: flex;
          padding-right: 20px;
          justify-content: flex-end;
          pointer-events: none;
          margin: 0;
        }
      }
    }
  }
}
