.list-sidebar {
  h1 {
    font-size: larger;
    font-weight: 700;
    padding: 40px 15px 0 25px;
  }
  .small-gray {
    font-size: 12px;
    color: gray;
    margin: 20px 0 0 25px;
  }

  // List option stylings..
  .list-parent {
    height: 200px;
  }
  .list-option {
    padding: 5px 25px;
    cursor: pointer;
    color: #313131;
  }
  .list-option:hover {
    background-color: rgba($color: #efefef, $alpha: 1);
  }
  .list-option.selected {
    background-color: lightgray;
  }

  .setting {
    display: none;
  }
}
