$disabled-BG-color: #ebedf2;

.vfi-switch-button {
  //   display: none;
  width: 40px;
  min-width: 40px;
  height: 24px;
  cursor: pointer;
  margin-bottom: 0;
  .background {
    transition: ease-out 0.2s;
    width: 100%;
    height: 100%;
    background-color: $disabled-BG-color;
    border: 2px solid $disabled-BG-color;
    border-radius: 12px;
    .front-button {
      transition: ease-out 0.2s;
      background-color: white;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      margin-left: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        transition: ease-out 0.2s;
        font-size: 14px;
        opacity: 0;
      }
    }
  }
  &.checked {
    .background {
      background-color: black;
      border-color: black;
      .front-button {
        margin-left: 16px;
        svg {
          opacity: 1;
        }
      }
    }
  }
  &.disabled {
    cursor: default;
    .background {
      opacity: 0.25;
    }
  }
  input[type="checkbox"] {
    display: none;
  }
}
