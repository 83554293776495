@import "src/colorVariables.scss";
.opening-hours {
  max-width: 660px;
  .opening-hours-wrap {
    .opening-hour-wrap {
      display: flex;
      // align-items: center;
      position: relative;
      border-bottom: 1px solid rgba(162, 162, 162, 0.25);
      padding-bottom: 15px;
      margin-bottom: 15px;
      @media ($phone) {
        flex-direction: column;
        margin-bottom: 40px;
        align-items: flex-start;
        gap: 4px;
      }
      .delete {
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        font-size: 12px;
        width: 20px;
        text-align: center;
      }
      .day {
        margin-right: 70px;
      }
      .time-from-to {
        margin-right: 70px;
        .from,
        .to {
          display: flex;
          align-items: center;
          .text {
            width: 45px;
          }
          .vfi-custom-select {
            width: 60px;
            margin-right: 5px;
            .css-1s2u09g-control,
            .css-1pahdxg-control {
              background-color: $light;
              border: 2px solid transparent;
              margin-bottom: 2px;
              box-shadow: none;
            }
            .css-1pahdxg-control {
              border: 2px solid $blue;
            }
          }
        }
      }
      .closed {
        text-align: center;
      }
    }
  }
  .add-icon {
    cursor: pointer;
    user-select: none;
    width: 100px;
    text-align: center;
    margin: auto;
    margin-top: 10px;
    span {
      margin-right: 5px;
    }
    &:hover {
      text-decoration: underline;
    }
    svg {
      font-size: 16px;
    }
  }
}
