@import "src/colorVariables.scss";
.svg-choose {
  height: 100%;
  .close {
    position: absolute;
    right: 0;
    margin-top: 20px;
    margin-right: 20px;
    opacity: 1;
    cursor: pointer;
    z-index: 1;
  }
  .content {
    display: flex;
    flex-direction: column;
    width: unset;
    height: 100%;
    h2 {
      margin: 20px;
    }
    .final-options {
      display: flex;
      justify-content: flex-end;
      .option {
        width: 90px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: bold;
        margin: 20px 20px;
        cursor: pointer;

        &.select {
          background-color: $blue;
          color: white;
        }

        &.cancel {
          background-color: $light;
        }
      }
    }
  }
}
.icons {
  flex-grow: 1;
  background-color: $lighter;
  margin: 0 20px;
  padding: 10px;
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;

  .icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80px;
    user-select: none;
    padding: 10px;
    border-radius: 30px;
    transition: ease-in-out 0.2s;
    cursor: pointer;
    img {
      filter: brightness(0);
    }
    .name {
      text-align: center;
    }
    &.selected {
      background-color: black;
      color: white;
      img {
        filter: brightness(0) invert(1);
      }
    }
  }
}
.only-svg-choose.icons {
  width: 420px;
  height: 100%;
  max-height: 600px;
  background-color: white;
  padding: 15px 0;
  margin: 0;
  .top {
    width: 100%;
  }
  .content {
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    flex-wrap: wrap;
    padding: 20px;
    overflow: auto;
    max-height: 416px;
    .icon.selected {
      background-color: $light;
      color: black;
      img {
        filter: brightness(0);
      }
    }
  }
}
