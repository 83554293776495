// .main{
.question-info {
  cursor: pointer;
  position: relative;
  .fa-question-circle {
    cursor: pointer;
  }
  .question-box {
    // overflow: auto;
    max-height: 500px;
    position: fixed;
    width: 455px;
    padding: 20px;
    background-color: white;
    box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.1);
    // left: 0;
    transform: translate(calc(-50% + 8px), 30px);
    z-index: 1;

    .close-tab {
      position: sticky;
      float: right;
      font-size: 18px;
      cursor: pointer;
      width: 15px;
      top: 0;
    }

    h3 {
      font-size: 18px;
      font-weight: 600;
    }
    img {
      width: 50%;
    }
    ul {
      padding-left: 20px;
      li {
        font-size: 16px;
        margin-bottom: 15px;
      }
      li::marker {
        font-size: 11px;
        width: 4px;
      }
    }
    .point-up {
      position: absolute;
      top: 0;
      left: 50%;
      width: 10px;
      height: 10px;
      transform: translate(-50%, -50%) rotate(45deg);
      background-color: white;
    }
  }
  .question-box.hidden {
    display: none;
  }
}
// }
