.whatson-module {
  position: relative;
  .the-iframe {
    border: none;
    pointer-events: none;
  }
  .select-wrap.row {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    .select.what-region.css-2b097c-container,
    .select.what-whatson.css-2b097c-container {
      padding: 0 15px 30px;
      opacity: 0.8;
      transition: ease-in-out 0.2s;
      &:hover {
        opacity: 1;
      }
    }
  }
}
