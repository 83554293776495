.is-articles-content {
  .is-articles-display {
    div.header-panel {
      .header-title {
        width: 15%;
        h1 {
          margin: 0;
        }
      }
      .status-filter-parent {
        width: 60%;
      }
      .add-new-media-parent {
        width: 25%;
        .add-new-media {
          width: 100%;
          max-width: 230px;
          .text {
            width: 100%;
          }
        }
      }
    }
  }
}
