@import "src/colorVariables.scss";

$xxxl: "max-width: 2019px";
$xxl: "max-width: 1535px";
$xl: "max-width: 1279px";
$lg: "max-width: 1279px";
$md: "max-width: 767px";
$sm: "max-width: 639px";

$color-border-light: rgba(162, 162, 162, 0.2);

$vfi-dark: #373737;

@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap");
$LibreBasker: "Libre Baskerville";
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700;800&display=swap");
$sansPro: "Source Sans Pro";

.page-footer-display {
  .footer {
    padding-top: 110px;
    padding-bottom: 55px;

    .footer-container {
      background-color: $color-border-light;
      .upper-foot-wrap {
        height: 150px;
        display: flex;
        max-width: 1440px;
        margin: auto;
        padding: 0 10px;
        align-items: center;
        justify-content: space-between;
        > div {
          flex: 1 1 0;
        }

        .footer-logo {
          .logo {
            display: inline-block;
            height: min-content;
            width: 100%;

            &:hover {
              color: black;
            }

            div {
              display: inline-block;
              vertical-align: middle;
            }

            .sheephead {
              width: 50px;
              height: 50px;

              img {
                width: 100%;
                height: unset;
              }
            }

            .text {
              color: #212529;
              text-decoration: none;

              font-family: "VFIfonts", Fallback, sans-serif;
              font-size: 1.5rem;
              margin-left: 10px;
              font-weight: bold;
            }
          }
        }

        .social-media {
          white-space: nowrap;
          display: flex;
          align-items: center;
          flex-grow: 2;

          .follow-us {
            font-family: $LibreBasker;
            font-style: italic;
            color: #acacac;
          }

          i {
            color: #212529;
            text-decoration: none;

            margin: 0 10px;
          }
        }

        .other-sites {
          white-space: nowrap;
          display: flex;
          position: relative;
          flex-grow: 2;

          p,
          h4 {
            margin: 0;
          }

          h4 {
            display: flex;
            align-items: center;
          }

          .text {
            font-family: $LibreBasker;
            font-style: italic;
            color: #acacac;
            position: absolute;
            left: -100px;
          }

          a {
            color: #212529;
            text-decoration: none;

            font-family: $LibreBasker;
            font-style: italic;
            margin-right: 10px;
            font-size: 1rem;
          }

          a:hover {
            text-decoration: underline;
          }

          span {
            display: flex;
          }
        }
      }
    }

    .lower-foot {
      max-width: 1440px;
      padding: 0 10px;
      margin: auto;
      display: flex;
      padding-top: 50px;
      .footer-info {
        flex: 1 1 0;

        > div {
          p,
          h4 {
            line-height: 40px;
            margin: 0;
            font-size: 1rem;
          }
        }

        .top-lang,
        .top,
        .choice {
          margin-bottom: 14px;
          user-select: none;

          a {
            color: #212529;
            text-decoration: none;
          }

          a:hover {
            text-decoration: underline;
          }
        }

        a {
          color: #212529;
          text-decoration: none;
        }
      }

      .top-lang {
        font-size: 16px;
        font-family: $LibreBasker;
        font-style: italic;
        color: #acacac;
        margin-top: 6px;
      }

      .lang-option-wrapper {
        .lang-button,
        input[type="submit"],
        input[type="reset"] {
          background: none;
          color: inherit;
          border: none;
          padding: 0;
          font: inherit;
          cursor: pointer;
          outline: inherit;
        }

        .lang-button {
          color: $vfi-dark;
          font-family: $LibreBasker;
          font-size: 16px;
          font-style: italic;
          letter-spacing: 0;
          line-height: 20px;
          text-align: left;
          text-decoration: underline;
        }

        .lang-button::first-letter,
        .lang-option::first-letter {
          text-transform: uppercase;
        }

        .lang-list {
          position: absolute;
          margin-top: 10px;
        }

        .lang-option {
          text-decoration: none;
          margin-top: 3px;

          font-family: $sansPro;
          font-size: 18px;
          font-weight: 600;
          margin-left: 15px;

          a {
            color: $vfi-dark;
          }

          a:hover {
            text-decoration: underline;
          }

          a.active {
            cursor: unset;
            text-decoration: none;
            color: #009fff;
          }
        }

        i {
          padding-left: 5px;
        }
      }

      .top {
        margin-top: 50px;
        font-size: 20px;
        font-weight: bold;
      }

      .site-select-wrap {
        & .lang-select {
          appearance: none;
          background-color: transparent;
          border: none;
          font-size: 1rem;
        }
      }
    }
  }
}
