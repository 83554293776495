.navigation {
  // padding: 84px 0;
  padding: 44px 0;

  width: 0px;
  height: 100%;

  // background-color: rgba(0, 0, 0, 0.03);
  background-color: #fff;
  box-shadow: inset -15px 0 15px #eaeaea;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  overflow: hidden;

  position: relative;

  transition: 0.7s;

  transition-timing-function: ease-in-out;

  // Non Navigation Wrapper
  .nnw {
    padding: 20px 30px 0;

    width: 255px;

    h1 {
      height: 24px;

      font-size: larger;
      font-weight: 700;

      text-overflow: clip;

      // padding: 40px 15px 0 25px;
      margin-bottom: 0;
    }

    p {
      // margin-bottom: 0;
      margin: 0;
      padding: 0;

      // font-size: 12px;
      // color: #A2A2A2;

      text-overflow: clip;

      text-transform: uppercase;

      font-family: "Source Sans Pro", sans-serif;

      font-size: 12px;
      color: #a2a2a2;
      // margin: 20px 0 0 25px;
      // margin-top: 20px;

      font-weight: 600;
      white-space: nowrap;
      // color: $mid;
      min-height: 18px;
    }
  }

  .view {
    padding-left: 30px;

    width: 255px;
    height: 32px;

    background-color: rgba(0, 0, 0, 0);

    border: none;

    text-overflow: clip;

    text-align: left;
    text-transform: capitalize;

    outline: none;

    &:hover {
      background-color: #efefef;
    }

    &:focus {
      background-color: #efefef;
    }

    &.selected {
      color: #fff;
      background-color: #000;

      //background-image: linear-gradient(90deg, rgb(211, 211, 211) 0%, rgb(218, 218, 218) 50%, rgb(227, 227, 227) 80%);
    }
  }

  &.loaded {
    width: 255px;
  }

  .add {
    width: 50px;
    height: 50px;

    background-color: #313131;

    color: #fff;
    font-size: 20px;

    border-radius: 1000px;

    display: flex;
    justify-content: center;
    align-items: center;

    opacity: 0;

    position: absolute;
    bottom: 40px;
    left: 50%;

    transform: translate(-50%);

    cursor: pointer;

    transition: 0.3s;
  }

  &:hover {
    .add {
      opacity: 1;
    }
  }
}
