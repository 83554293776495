@import "src/colorVariables.scss";
.vfi-text-area {
  position: relative;
  // min-width: calc(706px + 0.6em);
  //display: inline-block;
  h1 {
    margin: 0;
    font-weight: bold;
    font-size: 42px;
    letter-spacing: 5.3px;
    line-height: 52px;
  }
  textarea {
    height: 200px;
    width: 100%;
    resize: none;
    padding: 15px 30px 15px 15px;
    background-color: rgba(162, 162, 162, 0.05);
    border: none;
    border-radius: 2px;
    border: 2px solid transparent;
    position: relative;
  }

  .max-chars {
    position: absolute;
    right: 0;
    transform: translateX(-50%);
    text-align: right;
    font-size: small;
    font-style: italic;
    color: $mid;
  }

  .ck-editor {
    .ck-editor__top {
      display: none;
    }
    .ck-editor__main {
      .ck-content {
        height: 100px;
        box-shadow: none;
        //resize: both;
        p {
          margin-bottom: 0;
        }
      }
      .ck-content.ck-focused {
        background-color: white;
        //border: 2px solid #009FFF;
        box-shadow: 0px 0px 2px #009fff;
      }
      .ck-content.ck-blurred {
        background-color: rgba(162, 162, 162, 0.05);
        border-color: transparent;
        //border: 2px transparent;
      }
      .ck-content.ck-read-only {
        color: gray;
      }
    }
  }

  .errors {
    position: absolute;
    font-size: 13px;
    color: #ef2533;
    .error {
      display: inline-block;
      margin-right: 5px;
    }
  }
}
.vfi-text-area.error {
  .ck-editor {
    .ck-editor__main {
      .ck-content {
        height: 100px;
        box-shadow: none;
        border-radius: 2px;
        border-color: red;
        //resize: both;
        p {
          margin-bottom: 0;
        }
      }
    }
  }
  textarea {
    box-shadow: none;
    border-radius: 2px;
    border-color: red;
    p {
      margin-bottom: 0;
    }
  }
}
.phone {
  .vfi-text-area {
    min-width: 100%;
    h1 {
      font-size: 30px;
    }
    p,
    li {
      font-size: 17px;
      line-height: 27px;
    }
    li::before {
      font-size: 50px !important;
      height: 50px !important;
      line-height: 57px !important;
    }
  }
}
.tablet {
  .vfi-text-area {
    min-width: unset;
  }
}
