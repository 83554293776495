@import "src/colorVariables.scss";
.ck-balloon-rotator__content {
  // .ck-toolbar {
  .ck {
    .ck-button__label {
      font-size: 14px;
    }
  }
  .ck-heading_paragraph_libre {
    .ck-button__label {
      font-family: "Libre Baskerville";
    }
  }
  .ck-heading_first_letter_big {
    .ck-button__label {
      &::first-letter {
        font-size: 40px;
        color: $blue;
        float: left;
        margin-top: 12px;
        margin-right: 4px;
        font-family: "Libre Baskerville";
        line-height: 30px;
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }
  .ck.ck-heading_excerpt {
    .ck-button__label {
      font-family: "Libre Baskerville";
      font-weight: bold;
      font-size: 1.2em;
    }
  }
  .ck.ck-heading_god_day {
    font-size: 40px;
  }
  .ck.ck-heading_heading1 {
    .ck-button__label {
      font-weight: bold;
      letter-spacing: 5.3px;
      font-size: 1.3em;
    }
  }
  .ck.ck-heading_heading2_libre {
    .ck-button__label {
      font-family: "Libre Baskerville";
      font-size: 1.2em;
    }
  }
  .ck.ck-heading_heading2 {
    .ck-button__label {
      font-weight: 500;
      font-size: 1.2em;
    }
  }
  .ck.ck-heading_heading3 {
    .ck-button__label {
      font-weight: 500;
      font-size: 1.1em;
    }
  }
  .ck.ck-heading_god_day {
    .ck-button__label {
      color: #009fff;
      font-family: "Libre Baskerville";
      font-style: italic;
      font-size: 1.2em;
      letter-spacing: 0.2px;
      padding: 20px 0;
    }
    &.ck-on {
      .ck-button__label {
        color: white;
      }
    }
  }
  // }
}
.ck-content {
  .table {
    table {
      border: unset;
      tbody {
        td,
        th {
          border: unset;
        }
      }
    }
  }

  .excerpt {
    color: #373737;
    font-family: "Libre Baskerville";
    font-size: 21px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 36px;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    display: block;
  }
  .large_headline {
    color: #009fff;
    font-family: "Libre Baskerville";
    font-size: 90px;
    font-style: italic;
    letter-spacing: 0.2px;
    line-height: 120px;
    text-align: center;
    display: block;
    margin: 0 !important;
  }
  .paragraph_libre {
    font-family: "Libre Baskerville";
    font-weight: normal;
    font-size: 1rem;
    display: block;
  }
  .first_letter_big {
    font-size: unset;
    line-height: unset;
    &::first-letter {
      font-size: 60px;
      color: $blue;
      float: left;
      margin-top: 12px;
      margin-right: 4px;
      font-family: "Libre Baskerville";
      line-height: 50px;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
  .h2_libre {
    font-family: "Libre Baskerville";
  }
  h3 {
    font-family: "Source Sans Pro";
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 25px;
  }
  .drop-cap {
    &::first-letter {
      font-size: 60px;
      color: $blue;
      float: left;
      margin-top: 12px;
      margin-right: 4px;
      font-family: "Libre Baskerville";
      line-height: 50px;
    }
  }
}
.ck-heading_god_day,
.ck-heading_excerpt {
  font-family: "Libre Baskerville";
}
