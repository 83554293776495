@import "src/colorVariables.scss";

.Sites-display-wrap {
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.11);
  width: 25%;
  z-index: 999 !important;
  background-color: white;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  a:link {
    color: $text-color;
  }
  a:visited {
    color: $text-color;
  }

  .siteMenuWrap {
    padding: 50px 40px 0 27px;
    flex-grow: 200;
    span {
      .paragraph_libre {
        i {
          font-family: "Libre Baskerville";
          font-weight: normal;
          font-size: 1rem;
          display: block;
        }
      }
      p,
      .paragraph_libre i,
      h3 {
        margin-bottom: 14px;
        line-height: 1.5rem;
      }
      h3 {
        font-weight: bolder;
        font-size: 1.25em;
        // margin-top: 50px;
      }

      p {
        // Used as spacer between H3's,
        // 24px + h4 margin-bottom of 16px = 40px space
        margin-bottom: 0;
      }

      h3 {
        font-size: 17px;
        font-weight: bold;
        letter-spacing: 0.4;
        line-height: 22px;

        margin-bottom: 1.25rem;
      }

      h4 {
        &.paragraph_libre {
          font-size: 16px;
          font-style: italic;
          letter-spacing: 0;
          line-height: 20px;

          margin-bottom: 1rem;

          a {
            color: #000000;
          }
        }
      }

      .footer-column {
        flex: 1 1 0px;
        padding-right: 10px;
        padding-left: 10px;
      }
    }

    // position:absolute;
    // top: 0;
    // left: 0;
    // right: 0;
  }
  .follow {
    background-color: $light;
    min-height: 150px;
    flex-grow: 1;
    position: relative;
    .follow_content {
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 70%;
      height: 50%;
      .text {
        color: #acacac;
        font-family: "Libre Baskerville";
        font-size: 16px;
        font-style: italic;
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;

        margin-bottom: 1.25rem;
      }
      .socialIcons {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 1.25rem;
        .socialIconItem {
          .brand {
            font-size: 22px;
            height: 22px;
            width: 26px;
            padding: 0 4px;
            margin: 0 2px;
          }
        }
      }
    }
  }
}
