@import "src/colorVariables.scss";

.user-content,
.overlay {
  height: 100%;
  overflow-y: auto;

  .users-display {
    height: 100%;
    display: flex;
    // display: flex;
    // flex-direction: column;
    // position: relative;

    .users-display-left-side {
    }
    .users-display-right-side {
      padding: 57px 40px 0 40px;
      flex-grow: 1;
      overflow: auto;
    }
    .header-panel {
      .header-title {
        display: inline-block;
        width: 50%;
        h1 {
          font-size: 30px;
          opacity: 0.98;
          font-weight: bold;
        }
      }

      .add-new-user-parent {
        display: inline-block;
        width: 50%;
        text-align: right;
        .add-new-user {
          display: inline-block;
          background-color: #009fff;
          color: white;
          vertical-align: middle;
          font-size: 17px;
          font-weight: 600;
          height: 50px;
          width: 160px;
          position: relative;
          cursor: pointer;
          .text {
            width: 160px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
          }
        }
      }

      .search-parent {
        display: inline-block;
        width: 50%;
        margin-top: 20px;
        .search {
          position: relative;
          width: 50%;
          input {
            //background-color: rgba(162,162,162,0.1);
            //border-radius: 2px;
            //border: 0;
            //padding: 12px 6px;
            width: 100%;

            ::placeholder {
              font-size: 2px;
            }
          }
          .magnifying-glass {
            position: absolute;
            top: 45%;
            right: 12px;
            transform: translateY(-50%);
            pointer-events: none;
          }
        }
      }

      .all-sort-and-filter {
        display: inline-block;
        width: 50%;
        text-align: right;
        margin-top: 20px;
        .image-show,
        .list-show,
        .filter-button {
          cursor: pointer;
          display: inline-block;
          margin-left: 35px;
          padding: 5px 10px;
          transition: ease-in-out 0.5s;
          border-radius: 2px;
          .icon {
            margin: auto;
          }
        }

        .image-show.selected,
        .list-show.selected {
          color: #009fff;
        }

        .filter-button {
          padding: 9px 15px 9px 20px;
          border: 1px solid transparent;
          border-radius: 20px;
          user-select: none;
          background-color: $blue;
          color: white;
          .slide-icon {
            margin-left: 15px;
          }
        }
        .filter-button.hidden {
          padding: 9px 15px 9px 20px;
          border: 1px solid rgba(162, 162, 162, 0.1);
          border-radius: 20px;
          user-select: none;
          background-color: white;
          color: black;
          cursor: pointer;
          .slide-icon {
            margin-left: 15px;
          }
        }
        .filter-button.hidden {
          padding: 9px 15px 9px 20px;
          border: 1px solid rgba(162, 162, 162, 0.1);
          border-radius: 20px;
          user-select: none;
          cursor: pointer;
          .slide-icon {
            margin-left: 15px;
          }
        }
      }
    }

    .user-list {
      flex: 1;
      margin-top: 30px;
      padding-bottom: 20px;
      .user-table {
        width: 100%;
        th {
          font-size: 14px;
          font-weight: 600;
          color: #a2a2a2;
          opacity: 0.98;
          padding: 10px 0;
          user-select: none;
          transition: ease-in-out 0.2s;
          width: 25%;
          svg {
            margin-left: 9px;
          }
        }
        th.name,
        th.company,
        th.role,
        th.activity {
          cursor: pointer;
        }
        th:hover {
          color: #626262;
        }
        th.sorted-by {
          color: black;
        }

        tbody {
          cursor: pointer;
          tr {
            &:hover {
              background-color: $light;
            }
            td {
              padding: 10px 0;
              border-top: 1px solid rgba(162, 162, 162, 0.1);
            }

            td.name,
            td.company {
              max-width: 120px;
              min-width: 100px;
              .name-name,
              .company-name {
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 95%;
                font-size: 15px;
                color: $dark;
                img {
                  width: 32px;
                  height: 32px;
                  object-fit: cover;
                  border-radius: 16px;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
