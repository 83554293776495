.infoscreen-content {
  .infoscreen-page {
    position: relative;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    flex: 1;
    height: 100vh;

    padding: 40px;
    padding-left: 36px;

    .shadow-maker {
      z-index: 1;
      top: 0;
      left: -4px;
      background-color: transparent;
      height: 100%;
      box-shadow: -15px 0px 20px rgba(211, 211, 211, 0.5);
    }
  }

  .infoscreen-content-body {
    position: relative;
    z-index: 2;

    display: flex;
    flex-direction: row;
    gap: clamp(1rem, 15%, 200px);

    height: 100%;
    overflow-y: auto;

    padding-left: 4px;

    > div {
      h2 {
        margin-bottom: 1rem;
      }
    }

    .checkbox {
      display: flex;
      flex-direction: row;
      align-items: center;

      gap: 10px;
    }
  }
}
