@import "src/colorVariables.scss";

#WhatsonDesignTypeChoose {
  &.choose-view {
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;
    // gap: 40px;
    // margin-top: 40px;
    padding-bottom: 30px;

    margin-top: 1rem;
    .choice {
      cursor: pointer;
      // border: 1px solid;
      padding: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: 0.8rem;

      width: 50%;

      .image {
        align-items: center;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        height: 70px;
        width: 70px;
        svg {
          height: 40px;
        }
      }

      .text {
        color: #373737;
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 20px;
        margin-top: 8px;
        text-align: center;
      }

      &.selected {
        background-color: #f5f5f5;
        // .image {
        //     background-color: rgba(0, 0, 0, 0.8);
        //     color: #fff;
        // }
      }
    }
  }
}
