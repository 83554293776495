
$width: 300px;

.container {
    z-index: 1000;

    padding: 0;
    margin: 0;

    width: $width;
    height: 100px;

    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

    position: fixed;
    bottom: 30px;
    right: 0;

    transform: translateX($width);

    transition: 0.3s;

    transition-timing-function: ease-in-out;

    .closeBtn {
        padding: 0;
        margin: 0;

        width: 100%;
        height: 100%;

        width: 28px;
        height: 28px;

        background-color: rgba(255, 255, 255, 0);

        display: flex;
        justify-content: center;
        align-items: center;

        position: absolute;
        top: 5px;
        right: 5px;

        svg {
            width: 23px;
            height: 23px;
        }
    }

    .content {
        width: 100%;
        height: 100%;
    }

    &.loaded {
        transform: translateX(0);
    }
}