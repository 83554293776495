.brands{
	width: 100%;
	overflow: auto;
	padding-bottom: 50px;
	.brandsHeader{
		background-color: #fafafa;
		height: 200px;
		display: flex;
		flex-direction: row;
		align-items: start;
		justify-content: space-between;
		padding: 40px;
		h1{
			font-weight: bold;
			font-size: 2.5em;
		}
		.brandForm{
			display:flex;
			flex-direction: column;
			gap: 25px;
			.brandField{
				display: flex;
				flex-direction: column;
				label{
					color: #999;
				}
				input{
					border: 1px #e0e0e0 solid;
					border-radius: 3px;
					padding: 5px 15px;
					color: #333;
				}
			}
		}
	}
	.brandList{
		padding: 40px;

		.topCP{
			height: 60px;
			display: flex;
			flex-direction: row;
			.orderChangeCP{
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 25px;

				.message{
					white-space: nowrap;
					color: #999;
				}
			}
		}

		table{
			width: 100%;
			
			tr{
				
				&:hover{
					background-color: #f9f9f9;
				}
				
				&.activeRow{
					background-color: #f0f0f0;
				}
			}
			
			th{
				color: #999;
			}
			
			td{
				padding: 15px;
				border-bottom: 1px solid #e0e0e0;
				
				&.clickableTd{
					cursor: pointer;
				}
				
				.sortCP{
					display: flex;
					flex-direction: column;
					
					.sortBtn{
						cursor: pointer;
						color: #777;
						
						&:hover{
							color: #000;
						}
					}
				}
			}
			.editRow{
				.editRowCP{
					height: 50px;
					display: flex;
					flex-direction: row;
					justify-content: end;
					align-items: center;
					.closeButton{
						color: #a0a0a0;
						font-size: 1.5em;
						cursor: pointer;
					}
				}
				.nameEditField{
					display: flex;
					flex-direction: row;
					gap: 25px;
					align-items: center;
					margin-bottom: 35px;
					input{
						padding: 10px;
						width: 300px;
					}
					.deleteBrandBtn{
						cursor: pointer;
						color: #f64e60;
						justify-self: end;
						margin-left: auto;
					}
				}
				.brandPrices{
					max-width: 350px;
					.priceRules{
						table{
							width: 100%;
						
							td{
								padding: 10px;
						
								.deleteBtn{
									cursor: pointer;
									color: #f64e60;
								}
							}
						}
					}
					.loadContainer{
						height: 40px;
					}
					.priceRuleLabelInput{
						margin-bottom: 25px;
						padding: 15px;
						width:100%;
					}
					.formInputContainer{
						display: flex;
						flex-direction: row;
						gap: 15px;
						justify-content: space-between;
						margin-bottom: 45px;
						.rateInput{
							max-width:100px;
							padding: 5px 15px;
						}
					}
					.addCPBtnContainer{
						display: flex;
						flex-direction: row;
						gap: 15px;
						justify-content: end;
					}
				}
			}
		}
	}
}