@import "src/colorVariables.scss";
.one-media.edit-module {
  .media-sizes {
    display: flex;
    flex: 0 0 auto;
    width: 100%;
    .media-size {
      // border: 1px solid black;
      height: 70px;
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 5px;
      cursor: pointer;
      transition: ease-out 0.2s;
      &:hover {
        background-color: $lighter;
      }
      p {
        margin: 0;
        font-size: 12px;
      }
      &.active {
        background-color: $light;
      }

      .box {
        border: 1px transparent;
        width: 30px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 22px;
      }
      .box-350 {
        border: 1px solid black;
        width: 20px;
        height: 15px;
        margin-top: 25px;
        margin-bottom: 5px;
      }
      .box-700 {
        border: 1px solid black;
        width: 30px;
        height: 20px;
        margin-top: 22px;
        margin-bottom: 3px;
      }
      .box-1440 {
        border: 1px solid black;
        width: 50px;
        height: 30px;
        margin-top: 15px;
      }
    }
  }
  .is-vimeo {
    width: 100%;
    margin: 20px 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    > label {
      cursor: pointer;
      display: flex;
      align-items: center;
      .vimeo-checkbox {
        margin: 0 10px 0 4px;
        display: flex;
      }
    }
    .vimeo-link {
      position: relative;
      margin-top: 8px;
      width: 100%;
      &.disabled {
        opacity: 0.3;
        pointer-events: none;
      }
      span {
        position: absolute;
        top: 15px;
        left: 17px;
      }
      .vfi-input-text {
        input {
          // padding-left: 145px;
        }
      }
    }
    .video-settings {
      margin-top: 10px;
      width: 100%;
      background-color: $lighter;
      .sub-title {
        text-align: center;
        font-weight: bold;
        margin-top: 5px;
      }
      > label {
        // margin: 0 20px;
        width: 100%;
      }
      .checkbox {
        margin-bottom: 0;
      }
    }
  }

  .media .option {
    width: 100%;
    padding: 20px 10px;
    margin: 0;
  }
}
