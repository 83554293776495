@import "src/colorVariables.scss";
// Before grid styling ------------------------------------------------------

// .module {
//   &.firstMediaGrid {
//     padding-top: 25px;
//   }
//   &.lastMediaGrid {
//     padding-bottom: 25px;
//   }
// }
.module {
  .media-grid-related {
    max-width: 1440px;
    margin: auto;

    .grid-container {
      grid-gap: 4px;
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }
}
.media-grid-related {
  .vfi-text-area {
    min-width: unset;
  }
  .max-text {
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    font-size: 30px;
    text-shadow: 0 0 10px rgba($color: #ffffff, $alpha: 0.5);
  }

  .add-media,
  .remove-media,
  .is-hero,
  .choose-template {
    // color: white;
    position: absolute;
    bottom: 20px;
    right: 20px;
    font-size: 24px;
    padding: 5px 10px;
    border-radius: 2px;
    cursor: pointer;
    background-color: rgba($color: #ffffff, $alpha: 0.75);
    transition: ease-in-out 0.2s;
    &:hover {
      background-color: rgba($color: #ffffff, $alpha: 1);
    }
    z-index: 1;
  }
  .choose-template {
    bottom: 80px;
    color: $blue;
  }
  .add-media,
  .choose-template {
    // width: 38px;
    // height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .one-media {
    height: 100%;
    max-width: unset;
    .image-container {
      max-height: unset;
      border: none;
      .pen {
        top: 0;
        right: 0;
        //transform:translate(50%,-50%);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          color: white;
          font-size: 20px;
          opacity: 0;
        }
      }
    }
  }
  .item {
    position: relative;
    opacity: 1;
    transition: ease-in-out 0.2s;
    .image-redirect {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: all;
      cursor: pointer;
    }
    .type-icon {
      position: absolute;
      top: 20px;
      left: 20px;
      // box-shadow: 0 0 10px 10px rgba($color:white,$alpha:0.4);
      // background-color: rgba($color:white,$alpha:0.4);;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      color: white;
    }
    .bottom-shadow {
      position: absolute;
      height: 40%;
      width: 100%;
      bottom: 0;
      background-image: linear-gradient(transparent, black);
      opacity: 0.7;
      pointer-events: none;
    }
    .title {
      position: absolute;
      bottom: 44px;
      background-color: rgba($color: #ffffff, $alpha: 0);
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% - 30px);
      transition: ease-in-out 0.1s;
      &.focus {
        background-color: rgba($color: #ffffff, $alpha: 0.5);
      }
      // .ck-content{
      //     background-color: transparent;
      // }
      p {
        line-height: 30px;
        text-align: center;
        font-family: "Libre Baskerville";
        font-size: 21px;
        color: white;
        margin-bottom: 0 !important;
      }
    }
    .top-choices {
      position: absolute;
      top: 10px;
      left: 0;
      right: 0;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: ease-in-out 0.2s;
      .choice {
        margin: 1px;
        color: white;
        width: 28px;
        height: 28px;
        background-color: rgba($color: #ffffff, $alpha: 0.2);
        text-align: center;
        border-radius: 2px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 17px;
        transition: ease-out 0.2s;
        &:hover {
          background-color: rgba($color: #ffffff, $alpha: 0.5);
        }
        &.change-page {
          &:hover {
            background-color: rgba($color: $blue, $alpha: 1);
            color: white;
          }
        }
      }
      .remove {
        color: red;
      }
    }
    &.in-drag {
      opacity: 0;
    }
    &:hover:not(.inactive) {
      .title {
        background-color: rgba($color: #ffffff, $alpha: 0.5);
      }
      .top-choices {
        opacity: 1;
      }
      .one-media {
        opacity: 0.5;
        .image-container {
          .pen {
            background-color: unset;
            svg {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

// All grid styling ---------------------------------------------------------

.media-grid-related {
  .item1 {
    grid-area: one;
  }
  .item2 {
    grid-area: two;
  }
  .item3 {
    grid-area: thr;
  }
  .item4 {
    grid-area: fou;
  }
  .item5 {
    grid-area: fiv;
  }
  .item {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .grid-container {
    display: grid;
    // height: 600px;
    grid-auto-rows: 250px;

    &.grid-amount1-pre1 {
      grid-template-areas: "one";
    }
    &.grid-amount1-pre2 {
      grid-template-areas:
        "one"
        "one";
    }

    // TWO ----------------
    &.grid-amount2-pre1 {
      grid-template-areas: "one two";
      &.swapped {
        grid-template-areas: "two one";
      }
    }
    &.grid-amount2-pre2 {
      grid-template-areas:
        "one two"
        "one two";
      &.swapped {
        grid-template-areas:
          "two one"
          "two one";
      }
    }

    // THREE --------------
    &.grid-amount3-pre1 {
      grid-template-areas: "one one two thr thr";
      &.swapped {
        grid-template-areas: "thr thr two one one";
      }
    }
    &.grid-amount3-pre2 {
      grid-template-areas:
        "one one two thr thr"
        "one one two thr thr";
      &.swapped {
        grid-template-areas:
          "thr thr two one one"
          "thr thr two one one";
      }
    }

    // FOUR --------------
    &.grid-amount4-pre1 {
      grid-template-areas: "one two thr fou";
      &.swapped {
        grid-template-areas: "fou thr two one";
      }
    }
    &.grid-amount4-pre2 {
      grid-template-areas:
        "one two thr fou"
        "one two thr fou";
      &.swapped {
        grid-template-areas:
          "fou thr two one"
          "fou thr two one";
      }
    }

    // FIVE --------------
    &.grid-amount5-pre1 {
      grid-template-areas: "one two thr fou fiv";
      &.swapped {
        grid-template-areas: "fiv fou thr two one";
      }
    }
    &.grid-amount5-pre2 {
      grid-template-areas:
        "one two thr fou fiv"
        "one two thr fou fiv";
      &.swapped {
        grid-template-areas:
          "fiv fou thr two one"
          "fiv fou thr two one";
      }
    }
  }
}

.phone {
  .media-grid-related {
    .grid-container {
      &.grid-amount1-pre1 {
        grid-template-areas: "one one";
      }

      // TWO ----------------
      &.grid-amount2-pre1 {
        grid-template-areas: "one two";
      }
      &.grid-amount2-pre2 {
        grid-template-areas: "one two";
      }
      &.grid-amount2-pre3 {
        grid-template-areas: "one two";
      }

      // THREE --------------
      &.grid-amount3-pre1 {
        grid-template-areas:
          "one one"
          "two thr";
      }
      &.grid-amount3-pre2 {
        grid-template-areas:
          "one one"
          "two thr";
      }
      &.grid-amount3-pre3 {
        grid-template-areas:
          "one one"
          "two thr";
      }

      // FOUR --------------
      &.grid-amount4-pre1 {
        grid-template-areas:
          "one one"
          "two thr"
          "fou fou";
      }
      &.grid-amount4-pre2 {
        grid-template-areas:
          "one one"
          "two thr"
          "fou fou";
      }
      &.grid-amount4-pre3 {
        grid-template-areas:
          "one one"
          "two thr"
          "fou fou";
      }

      // FIVE --------------
      &.grid-amount5-pre1 {
        grid-template-areas:
          "one two "
          "thr thr "
          "fou fiv";
      }
      &.grid-amount5-pre2 {
        grid-template-areas:
          "one two "
          "thr thr "
          "fou fiv";
      }
      &.grid-amount5-pre3 {
        grid-template-areas:
          "one two "
          "thr thr "
          "fou fiv";
      }
      &.grid-amount5-pre4 {
        grid-template-areas:
          "one two "
          "thr thr "
          "fou fiv";
      }
      &.grid-amount5-pre5 {
        grid-template-areas:
          "one two "
          "thr thr "
          "fou fiv";
      }
    }
  }
}
