@import "src/colorVariables.scss";
.overlayElement {
  position: fixed;
  background-color: rgba($color: $mid, $alpha: 0.5);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .overlayElementInside {
    position: absolute;
    width: 340px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 30px;
    box-shadow: 0 0 25px 0 rgba($color: #000000, $alpha: 0.11);
  }

  .closeButton {
    position: absolute;
    right: 30px;
    border: none;
    background-color: transparent;
    line-height: 0;
  }

  .lowerButtons {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 30px;
    > button {
      width: 132px;
      height: 30px;
      border: none;
      font-size: 0.8rem;
    }
    .cancelButton {
      background-color: $light;
    }
    .saveButton {
      background-color: $blue;
      color: white;
    }
  }
}
