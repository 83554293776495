@import "src/colorVariables.scss";
.main-pages {
  display: flex;
  flex-direction: column;
  height: 100%;
  &.select-page {
    height: calc(100% - 90px);
  }
  .header {
    height: 110px;
    margin-left: 0;
    margin-right: 0;
    flex-grow: 0;
    // box-shadow: inset 0 -20px 20px -20px rgba(0, 0, 0, 0.1);
    flex-shrink: 0;
    position: absolute;
    width: 100%;

    &.absolute {
      position: absolute;
      width: 100%;
      box-shadow: none;
      .status-filter-parent {
        display: none;
      }
    }

    .left,
    .right {
      display: flex;
      align-items: center;
      .title {
        font-weight: 700;
        font-size: 30px;
        margin-left: 15px;
      }
    }
    .left {
      justify-content: space-between;
      .language-select {
        width: 150px;
      }
    }
    .right {
      justify-content: flex-end;
      > div {
        margin: 0 30px;
      }
      .language-select {
        position: relative;
        > div {
          width: 100px;
          .css-yk16xz-control {
            border: 0;
          }
          .css-1okebmr-indicatorSeparator,
          .css-tlfecz-indicatorContainer,
          .css-1gtu0rj-indicatorContainer {
            display: none;
          }
        }
        .fa-caret-down {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          pointer-events: none;
        }
      }
      .pages-show-option {
        transition: ease-in-out 0.1s;
        cursor: pointer;
      }
      .pages-show-option.selected {
        color: $blue;
      }
    }
  }
  .sites-pages-wrap {
    display: flex;
    flex-grow: 1;
    height: calc(100% - 110px);

    .bg-1 {
      background-color: rgba(162, 162, 162, 0);
    }
    .bg-2 {
      background-color: rgba(162, 162, 162, 0.1);
    }
    .bg-3 {
      background-color: rgba(162, 162, 162, 0.2);
    }
    .bg-4 {
      background-color: rgba(162, 162, 162, 0.3);
    }
    .bg-5 {
      background-color: rgba(162, 162, 162, 0.4);
    }
    .bg-6 {
      background-color: rgba(162, 162, 162, 0.5);
    }
    .bg-7 {
      background-color: rgba(162, 162, 162, 0.6);
    }
  }

  .blur {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.5);
    z-index: 1;
    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 24px;
      color: white;
    }
  }
}
