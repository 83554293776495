.container {
  z-index: 1000;

  width: 100vw;
  height: 100vh;

  // background-color: rgba(0, 0, 0, 0.1);
  background-color: rgba(162, 162, 162, 0.5);

  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0;

  transition: 0.2s;

  .card {
    // padding: 20px;

    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

    // border-radius: 2px;

    position: relative;

    .closeBtn {
      padding: 0;
      margin: 0;

      width: 100%;
      height: 100%;

      width: 28px;
      height: 28px;

      background-color: rgba(255, 255, 255, 0);

      display: flex;
      justify-content: center;
      align-items: center;

      position: absolute;
      top: 30px;
      right: 30px;

      svg {
        width: 23px;
        height: 23px;
      }
    }
  }

  &.loaded {
    opacity: 1;
  }
}
