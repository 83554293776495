.edit-menu .menu-template .wrap-table table .menues .each-page .second-level {
  .static-palette {
    position: relative;
    .title {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      font-family: "Libre Baskerville";
      input {
        text-align: center;
        color: white;
        font-weight: bold;
        font-size: 22px;
        padding: 0;
      }
    }
    .edit-module {
      position: absolute;
      display: grid;
      place-items: center;
      width: 30px;
      height: 30px;
      top: 10px;
      right: 10px;
      transition: 0.2s ease-in-out;
      background-color: rgba($color: #ffffff, $alpha: 0.5);
      opacity: 0;
      cursor: pointer;
      z-index: 2;
    }
    &:hover {
      .edit-module {
        opacity: 1;
      }
      .title {
        input {
          background-color: rgba($color: #ffffff, $alpha: 0.5);
        }
      }
    }
  }
}

.static-palette-options {
  #handle {
    cursor: move;
    height: 28px;
    display: flex;
    align-items: center;
    margin-left: 8px;
    svg {
      margin: 0 1px;
    }
  }
  .close {
    top: 4px;
    right: 4px;
  }
}
