@import "src/colorVariables.scss";
.pl-infoscreens {
  display: flex;
  gap: 20px;

  &.disabled {
    pointer-events: none;
  }

  .added-is,
  .unadded-is {
    flex-grow: 1;
    .region {
      display: flex;
      margin-bottom: 10px;
      .region-wrap {
        flex-basis: 200px;
        display: flex;
        justify-content: space-between;
        max-height: 40px;

        .region-name {
          font-size: 18px;
          font-weight: 600;
        }
        svg {
          font-size: 0.6rem;
          margin-top: 8px;
        }
      }
      .list-in-region {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        .all-is {
          font-weight: 600;
          &.selected {
            background-color: #313131;
            color: white;
          }
        }
        button {
          text-align: left;
          margin-left: 4px;
          &:hover {
            background-color: $light;
          }
          &.selected {
            background-color: rgba($color: $mid, $alpha: 0.3);
          }
        }
      }
    }
    button {
      border: none;
      background-color: transparent;
    }
  }
  .added-is {
  }
  .unadded-is {
  }
  .add-funcs {
    .add,
    .remove {
      button {
        width: 115px;
        height: 40px;
        margin-bottom: 15px;
        border-radius: 20px;
        background-color: transparent;
        border: 1px solid black;
        .text,
        svg {
          color: black;
        }
      }
      button:disabled {
        border: 1px solid $mid;
        .text,
        svg {
          color: $mid;
        }
      }
    }
    .add {
      .text {
        margin-left: 15px;
      }
    }
    .remove {
      .text {
        margin-right: 15px;
      }
    }
  }
}
