@import "src/colorVariables.scss";

.text-and-expand {
  .expand-vertical {
    .ck-content {
      padding: 0 25px;
    }
    .expand-vertical-content {
      z-index: 1;
      max-height: 235px;
      overflow: hidden;
      background-color: $lighter;
      padding-bottom: 5px;
      position: relative;

      .vfi-text-area {
        margin: 36px 25px 0 25px;

        div {
          padding: 0;

          p {
            padding-bottom: 16px;
          }
        }

        .ck {
          min-height: 201px;

          blockquote {
            // padding-left: 10px;
            // padding-right: 10px;
            margin-bottom: 20px;
            p {
              color: $dark;
              font-family: "Libre Baskerville";
              font-size: 27px;
              margin-bottom: 10px;
              &:last-of-type {
                color: $mid;
                font-size: 20px;
                font-weight: 400;
              }
              &:first-of-type {
                color: $dark;
                font-size: 27px;
              }
            }
          }

          p,
          h1,
          h2,
          h3 {
            margin-top: 0;
            margin-bottom: 15px;
          }

          p {
            font-family: "Source Sans Pro";
            font-size: 1rem;
            letter-spacing: 0;
            line-height: 26px;
            margin: 0;
          }
          h1 {
            font-size: 30px;
            line-height: 34px;
            letter-spacing: 5.3px;
            font-weight: 700;
          }
          h2 {
            font-size: 22px;
            line-height: 22px;
            letter-spacing: 0.77px;
            font-weight: 700;
          }
          h3 {
            font-size: 18px;
            line-height: 25px;
            letter-spacing: 0.5px;
            font-weight: 700;
          }
        }
      }

      .expand-vertical-shadow {
        position: absolute;
        height: 50px;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(transparent, $lighter);
        pointer-events: none;
      }
    }
    .expand-vertical-button {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $lighter;
      cursor: pointer;
      transition: ease-in-out 0.2s;
      pointer-events: auto;
      &:hover {
        // background-color: $light;
      }
      svg {
        transition: ease-out 0.2s;
        transform: rotate(0deg);
        // vertical-align: middle;
        // height: 100%;
      }
    }
  }
}

.phone {
  .expand-vertical {
    .ck-content {
    }
    .expand-vertical-content {
      .vfi-text-area {
        .ck {
          blockquote {
            p {
              &:last-of-type {
                font-size: 16.8px;
              }
              &:first-of-type {
                font-size: 16px;
              }
            }
          }
        }
      }
    }

    .expand-vertical-shadow {
      position: absolute;
      height: 50px;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(transparent, $lighter);
      pointer-events: none;
    }
  }
  .expand-vertical-button {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $lighter;
    cursor: pointer;
    transition: ease-in-out 0.2s;
    pointer-events: auto;
    &:hover {
      // background-color: $light;
    }
    svg {
      transition: ease-out 0.2s;
      transform: rotate(0deg);
      // vertical-align: middle;
      // height: 100%;
    }
  }
}

.expand-vertical.expanded {
  .expand-vertical-content {
    max-height: unset;
    .expand-vertical-shadow {
      background-image: none;
    }
  }
  .expand-vertical-button {
    svg {
      transform: rotate(180deg);
    }
  }
}

.text-and-expand {
  .expand-vertical {
    // position: absolute;
    // right: 38px;
    width: 100%;
    max-width: 400px;
    max-height: 297px;

    .expand-vertical-button {
      position: relative;
      z-index: 1;
    }
  }
}
