@import "src/colorVariables.scss";
.vfi-custom-select-wrap {
  position: relative;
  &.error {
    .vfi-custom-select {
      .css-1s2u09g-control,
      .css-1pahdxg-control,
      .css-319lph-ValueContainer {
        border: 2px solid $red;
      }
    }
  }
  .vfi-custom-select {
    .css-1s2u09g-control,
    .css-1pahdxg-control {
      border-width: 1px;
      min-height: 50px;
    }
    .css-1hb7zxy-IndicatorsContainer,
    .css-1wy0on6 {
      display: none;
    }
    .css-6j8wv5-Input {
      padding: 6px 4px;
    }
  }
  .chevron {
    position: absolute;
    font-size: 12px;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
}
