@import "src/colorVariables.scss";
.main-whatson {
  height: 100%;

  .header {
    height: 110px;
    margin-left: 0;
    margin-right: 0;
    flex-grow: 0;
    // box-shadow: inset 0 -20px 20px -20px rgba(0, 0, 0, 0.1);
    flex-shrink: 0;
    position: absolute;
    width: 100%;
    position: absolute;
    background-color: white;
    z-index: 1;

    &.absolute {
      position: absolute;
      width: 100%;
      box-shadow: none;
      background-color: transparent;
      .status-filter-parent {
        display: none;
      }
    }

    .left,
    .right {
      display: flex;
      align-items: center;
      .title {
        font-weight: 700;
        font-size: 30px;
        margin-left: 15px;
      }
    }
    .left {
      // justify-content: space-between;
      .language-select {
        width: 150px;
      }

      .whatson-select {
        margin-left: 40px;
        width: 120px;
      }

      .vfi-input-text {
        margin-left: 40px;
      }
    }
    .right {
      justify-content: flex-end;
      > div {
        margin: 0 30px;
      }
      .language-select {
        position: relative;
        > div {
          width: 100px;
          .css-yk16xz-control {
            border: 0;
          }
          .css-1okebmr-indicatorSeparator,
          .css-tlfecz-indicatorContainer,
          .css-1gtu0rj-indicatorContainer {
            display: none;
          }
        }
        .fa-caret-down {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          pointer-events: none;
        }
      }
      .pages-show-option {
        transition: ease-in-out 0.1s;
        cursor: pointer;
      }
      .pages-show-option.selected {
        color: $blue;
      }
    }
  }
}
