.page-section-content {
  &.xl,
  &.xxl {
    .breadcrumbs-container {
      margin-left: 40px;
      margin-right: 40px;
    }
  }
  &.md {
    .breadcrumbs-container {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
  &.sm {
    .breadcrumbs-container {
      margin-left: 10px;
      margin-right: 10px;

      display: none;
    }
  }
}
