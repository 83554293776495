@import "src/colorVariables.scss";
.themes {
  height: 100%;
  overflow: auto;
  background-color: #f4f4f5;

  .themes-section {
    // background-color: red;
    padding-left: 30px;
    height: 100%;
    flex-basis: calc(100% - 420px);
    max-width: unset;
    display: flex;
    flex-direction: column;
    width: auto;
    .themes-section-header {
      min-height: 100px;

      .fixed {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70px;

        &:hover {
          background-color: $light;
        }

        .header-title {
          font-weight: 600;
          font-size: 19px;
          margin-right: 50px;
          cursor: pointer;
          transition: ease-in-out 0.2s;

          &:hover {
            color: $blue;
          }
        }
      }
    }
    .themes-section-content {
      .select-themes {
        display: flex;
        gap: 30px;

        .theme {
          padding: 3px;
          &.selected {
            box-shadow: inset 0px 0px 0px 3px #373737;
          }
          cursor: pointer;
          > div {
            background-color: white;
            padding: 10px;
          }
        }
      }
    }
  }
}
