@import "src/colorVariables.scss";
.App {
  .login-contents {
    height: 100vh;
    font-family: "Source Sans Pro", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    &.vita {
      .vitaLogo {
        position: absolute;
        right: 30px;
        top: 30px;
        img {
          width: 130px;
        }
      }
      .content {
        .imgcontainer {
          .vfi-logo {
            display: none;
          }
          .vita-logo {
            width: 100px;
            height: unset;
            margin-right: 0px;
            margin-top: -14px;
          }
          .vfi-text {
            display: flex;
            justify-content: center;
            gap: 4px;
            align-items: center;
            font-size: 3.3em;
            .dash,
            .type {
              opacity: 0.3;
              font-weight: 300;
            }
            .dash {
              margin-top: -10px;
              font-weight: 100;
            }
          }
        }
        .my-facebook-button-class,
        .or-container {
          display: none;
        }
      }
      .create-profile,
      .create-event {
        display: none;
      }
    }
    .content {
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: transparent;
      width: 100%;
      .imgcontainer {
        width: calc(100%);
        text-align: center;
        margin: 0 0 58px 0;
        img {
          width: 40px;
          height: 40px;
          filter: brightness(0%);
          margin-right: 20px;
        }

        .vfi-text {
          font-family: "VFIfonts", Fallback, sans-serif;
          list-style: 28px;
          font-size: 1.5em;
          vertical-align: middle;
        }
      }
      #Login {
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .top-message {
          text-align: center;
        }
        .error-message {
          max-width: 450px;
          margin: auto;
          padding-left: 20px;
          margin-bottom: -24px;
          color: $red;
          font-size: 12px;
          height: 24px;
          padding-bottom: 4px;
          text-align: left;
        }
        input[type="email"].no-value,
        input[type="password"].no-value {
          background-color: rgb(240, 240, 240);
          box-shadow: none;
        }

        input[type="email"],
        input[type="password"],
        .password {
          width: 100%;
          padding: 16px 10px;
          margin-bottom: 23px;
          display: inline-block;
          //border: 1px solid #ccc;
          border: none;
          border-radius: 2px;
          box-sizing: border-box;
          height: auto;
          font-size: 16px;
          box-shadow: 0px 0px 0px 2px $color-button-blue;
        }
        // .password {
        //   margin-bottom: 5px;
        // }
        ::placeholder {
          color: rgba($color: #000000, $alpha: 0.5);
          font-weight: 600;
        }

        button[type="submit"] {
          font-family: Helvetica, sans-serif;
          font-weight: 700;
          font-size: calc(0.27548vw + 12.71074px);
          background-color: $color-button-blue;
          border-radius: 2px;
          transition: ease-in-out 0.2s;
        }
        button {
          color: white;
          padding: 16px 20px;
          margin: 8px 0;
          border: none;
          cursor: pointer;
          width: 100%;
        }
        button:hover {
          opacity: 0.8;
        }

        .cancelbtn {
          width: auto;
          padding: 10px 18px;
          background-color: #f44336;
        }

        img.avatar {
          width: 200px;
        }
        .wrap-container {
          width: calc(100%);
        }
        .container {
          margin: auto;
          padding: 0;
          max-width: 450px;
          height: 570px;
        }
        .show-password-container {
          text-align: left;
          .show-password {
            vertical-align: text-bottom;
            color: rgba($color: #000000, $alpha: 0.4);
            margin-left: 10px;
            font-size: 0.95em;
            transition: ease-in-out 0.2s;
          }
        }
        .remember-me-container {
          margin: 3px 0;
          text-align: left;
          .remember-me {
            vertical-align: text-bottom;
            color: rgba($color: #000000, $alpha: 0.4);
            margin-left: 10px;
            font-size: 0.95em;
            transition: ease-in-out 0.2s;
          }
          .remember-me.checked {
            color: rgba($color: #000000, $alpha: 0.75);
          }
        }

        .cannot-login {
          height: 22px;
          width: 300px;
          color: $mid;
          // font-family: "Source Sans Pro";
          font-size: 15px;
          letter-spacing: 0;
          line-height: 22px;
          // text-align: center;
          cursor: pointer;
          margin: auto;
          margin-top: 38px;
          text-align: center;
        }

        .or-container {
          color: lightgrey;
          margin: 32px 0;
          .or-line {
            display: inline-block;
            width: 40%;
            border-bottom: 1px solid;
            vertical-align: middle;
          }
          .or-word {
            display: inline-block;
            width: 20%;
            text-align: center;
          }
        }

        .my-facebook-button-class {
          width: 100%;
          height: 60px;
          color: black;
          text-align: left;
          background-color: transparent;
          border: 2px solid lightgray;
          border-radius: 2px;
          position: relative;
          transition: ease-in-out 0.2s;
          img {
            top: 50%;
            transform: translate(0, -50%);
            width: 20px;
            height: 20px;
            position: absolute;
          }
          .fb-text {
            font-weight: 700;
            top: 50%;
            transform: translate(0, -50%);
            position: absolute;
            width: calc(100% - 40px);
            text-align: center;
          }
        }
        .my-facebook-button-class:hover {
          background-color: lightblue;
          border: 2px solid lightblue;
        }
      }
    }

    .password-title {
      color: #373737;
      font-family: "Libre Baskerville";
      font-size: 25px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 25px;
      text-align: center;

      margin-bottom: 2rem;
    }

    .password-info {
      text-align: left;
      margin: 0 15px 30px 15px;
    }

    .password-text-guide {
      display: flex;
      > div {
        margin-left: 2px;
      }
      > div:last-child {
        margin-left: 30px;
      }
      ul {
        text-align: left;
        padding: 0 0 0 10px;
        color: #373737;
        font-family: "Source Sans Pro";
        font-size: 16px;
        letter-spacing: 0;
        line-height: 24px;
      }
    }
  }
}

.create-profile {
  user-select: none;
  cursor: pointer;
  position: absolute;
  top: 30px;
  right: 100px;
  transition: ease-in-out 0.2s;
  .text {
    margin-right: 15px;
    font-weight: 700;
    font-size: 0.9em;
  }
}
.create-profile:hover {
  color: $color-button-blue;
}

.create-event {
  user-select: none;
  cursor: pointer;
  position: absolute;
  top: 70px;
  right: 100px;
  transition: ease-in-out 0.2s;
  .text {
    margin-right: 15px;
    font-weight: 700;
    font-size: 0.9em;
  }
}
.create-event:hover {
  color: $color-button-blue;
}
