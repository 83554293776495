.instagram-in-whatson {
  margin-bottom: 100px;
  .edit-contents {
    opacity: 1;
    transition: opacity 0.5s;
  }

  .edit-contents.hidden {
    user-select: none;
    opacity: 0;
    pointer-events: none;
  }
  .row {
    margin: 0;
  }
  .hashtag,
  .profile-link {
    width: 50%;
    display: inline-block;
    padding: 0;
    margin-bottom: 20px;
  }
  .hashtag {
    padding-right: 2vw;
  }
  .profile-link {
    padding-left: 2vw;
  }
  .vfi-custom-select {
    .css-26l3qy-menu {
      z-index: 2;
    }
  }
  .instagram-image-links {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    // justify-content: center;
    .insta {
      width: 300px;
      display: inline-block;
      margin: 15px 30px 15px 0;
      &.hidden {
        opacity: 0;
      }
      .insta-image {
        width: 300px;
        height: 300px;
        position: relative;
        img {
          height: 100%;
          object-fit: cover;
        }
      }
      .insta-link-wrap {
        margin-top: 5px;
        .insta-text {
          white-space: nowrap;
          margin-right: 5px;
        }
      }
    }
  }
}
