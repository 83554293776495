@import "src/colorVariables.scss";

.physical {
  .wrap-products {
    border-radius: 5px;
    border: 1px solid rgba(162, 162, 162, 0.2);
    // background-color: rgba(162, 162, 162, 0.05);
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: calc(100% - 40px);
    .remove {
      cursor: pointer;
      position: absolute;
      right: -30px;
    }
    .level-brand:nth-child(odd) {
      border-bottom: 1px solid rgba(162, 162, 162, 0.2);
    }
    table {
      width: 100%;
      border-collapse: collapse;

      tr {
        th:not(.delete) {
          > div {
            border-bottom: 0;
            font-weight: 400;

            display: flex;
            position: relative;
            padding: 0.2rem;
            padding-right: 0.6rem;
            justify-content: space-between;
            align-items: center;
            padding: 1.2rem;
            padding-right: 0.6rem;

            .expand {
              width: 50%;
              height: 54px;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              cursor: pointer;
            }
            div:not(.remove) {
              position: relative;
              .remove {
                cursor: pointer;
                position: absolute;
                right: -30px;
                top: 0;
              }
              label {
                color: $dark-blue;
                left: 10px;
              }
              label {
                position: absolute;
              }
            }
          }
        }
        th.delete {
          width: 40px;
          div {
            display: flex;
            justify-content: center;
          }
        }
        th {
          padding: 0;
        }
      }
    }
    > table {
      > tr:first-of-type {
        th {
          > div {
            background-color: white;
            border-radius: 5px;
          }
        }
      }
    }
  }
  .brands {
    // box-shadow: 0 2px 8px inset rgba(0, 0, 0, 0.07);

    .wrap-add {
      margin: 0 0 30px 12px;
    }
    table {
      box-shadow: 0 0px 18px -6px inset rgb(0 0 0 / 10%);
      border-collapse: collapse;

      tr {
        &.product-row {
          border-bottom: 1px solid rgba(162, 162, 162, 0.2);
        }
        th {
          // border-bottom: 1px solid rgba(162, 162, 162, 0.2);
          > div {
            // margin-top: 10px;

            border-bottom-left-radius: 5px;

            border-top: 0;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }
        }
      }
      .varients {
        background-color: white;
        background-color: rgba(162, 162, 162, 0.04);
        table {
          box-shadow: unset;
          border-bottom: 0;
          width: 98%;
          float: right;
          tr {
            th {
              border-bottom: 1px solid rgba(162, 162, 162, 0.2);
              > div {
                box-shadow: unset;
              }
              .properties {
                .remove {
                  position: unset;
                }
                td:first-of-type {
                  label {
                    left: 10px;
                  }
                }
                th {
                  border: 0;
                  border-top: 1px solid rgba(162, 162, 162, 1);
                }
                th:not(th:first-of-type) {
                  padding-left: 1.2rem;
                }
                // box-shadow: 0 8px 6px -6px inset rgb(0 0 0 / 10%);
                // background-color: rgba(162, 162, 162, 0.04);
                display: flex;
                flex-direction: column;
                border: 0;
                align-items: flex-end;
                width: 100%;
                td:last-of-type {
                  width: unset;
                }
                .wrap-add {
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
