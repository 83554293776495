@import "src/colorVariables.scss";

.filters {
  .wrap-filter-options {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    > div {
      flex: 1 1 auto;
      display: flex;
      gap: 4px;
      align-items: center;
      text-transform: capitalize;
      min-width: 100px;
      border: 1px solid rgba($color: #000000, $alpha: 0.2);
      padding: 10px;
      cursor: pointer;
      &.selected {
        background-color: $red;
        color: white;
      }
    }
    input {
      display: unset;
      // visibility: hidden;
    }
  }
}
