@import "src/colorVariables.scss";
.main {
  .overlay {
    .close-overlay {
      z-index: 1;
      position: absolute;
      font-size: 28px;
      right: 30px;
      top: 10px;
    }
  }
}
