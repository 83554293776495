@import "src/colorVariables.scss";

.confPrices {
  .wrap-prices {
    display: flex;
    flex-direction: column;
    gap: 20px;
    > div {
      display: flex;
    }
    .orderPrice {
      padding: 0 20px;
      > div {
        padding: 0px 6px;
        cursor: pointer;
        &:hover {
          background-color: $light;
        }
      }
    }
    .table {
      padding: 20px;
      width: 100%;
      border-radius: 5px;
      border: 1px solid rgba(162, 162, 162, 0.2);
      position: relative;
      .remove {
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 10px;
      }
      > div {
        gap: 20px 10px;
        flex-wrap: wrap;
        display: flex;

        > div {
          flex: 1 1 auto;
          min-width: calc(33% - 10px);
          i {
            font-size: 0.8em;
            opacity: 0.7;
          }
          &.w25 {
            min-width: calc(25% - 10px);
          }
          &.w100 {
            width: 100%;
            display: block;
            .properties-cont {
              width: 100%;
              display: grid;
              grid-template-columns: 1fr 1fr 1fr;
              grid-column-gap: 10px;
              .property-bool-check {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }
}
