@import "src/colorVariables.scss";
.dates {
  .date-type-wrap {
    position: relative;
    .date-type {
      .css-1s2u09g-control,
      .css-1pahdxg-control {
        border-width: 3px;
      }
      .css-1hb7zxy-IndicatorsContainer,
      .css-1wy0on6 {
        display: none;
      }
      .css-6j8wv5-Input {
        padding: 6px 4px;
      }
    }
    .chevron {
      position: absolute;
      font-size: 12px;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
    }
  }
  .titles {
    display: flex;
    margin-bottom: 5px;
    .top-text {
      flex-basis: 0;
      flex-grow: 1;

      &.time {
        max-width: 200px;
      }
    }
  }
  .dates-list {
    width: 100%;
  }
  .dates-content {
    display: flex;
    flex-wrap: wrap;

    .left-side,
    .right-side {
      flex-grow: 1;
      flex-basis: 0;
      margin-top: 30px;
      max-width: 700px;
    }
    .left-side {
      padding-right: 50px;
      border-right: 1px solid rgba(176, 186, 201, 0.3);
    }
    .right-side {
      padding-left: 50px;
    }

    &.many {
      .left-side {
        display: block;
      }
    }
    @media ($smallerDesktop) {
      display: block;
      .left-side {
        border-right-width: 0;
      }
      .right-side {
        padding-left: 0;
      }
    }
  }
  .time-seperate {
    text-align: center;
  }
  .left-side,
  .left-side-specific {
    // THESE ARE: single-date,.many-dates,.specific-dates
    display: flex;
    .left-wrap,
    .right-wrap {
      .top-text {
        margin-bottom: 5px;
      }
      margin-top: 20px;
      padding-right: 20px;
      flex-grow: 1;
      flex-basis: 0;
    }
    .left-wrap {
      .in-content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        .date-text {
          min-width: 100px;
        }
        .react-datepicker-wrapper {
          max-width: 175px;
          margin-right: 40px;
          .react-datepicker__input-container input.error {
            border: 2px solid $red;
          }
        }
        &.start-date {
          margin-bottom: 2px;
        }
      }
    }
    .right-wrap {
      max-width: 200px;
      .time-text {
        width: 100px;
      }
      .start-time,
      .end-time {
        display: flex;
        align-items: center;
        // display: none;
        .time-select.error {
          .css-1s2u09g-control,
          .css-1pahdxg-control,
          .css-1insrsq-control {
            border: 2px solid $red;
          }
        }

        .time-select,
        .css-b62m3t-container,
        .css-1insrsq-control {
          width: 54px;

          background-color: $input-fill;
          .css-319lph-ValueContainer,
          .css-1d8n9bt,
          .css-319lph-ValueContainer {
            padding-left: 10px;
            padding-right: 10px;
            text-align: center;
          }
        }

        .menu {
          width: 150%;

          text-align: center;

          transform: translateX(-12.5%);

          /* width */
          ::-webkit-scrollbar {
            width: 10px;
          }

          /* Track */
          ::-webkit-scrollbar-track {
            background: #f1f1f1;
          }

          /* Handle */
          ::-webkit-scrollbar-thumb {
            background: #b8b8b8;

            transition: 0.2s;
          }

          /* Handle on hover */
          ::-webkit-scrollbar-thumb:hover {
            background: #838383;
          }
        }
      }
      .css-1hb7zxy-IndicatorsContainer,
      .css-1wy0on6 {
        display: none;
      }
      .css-1s2u09g-control,
      .css-1pahdxg-control,
      .css-1insrsq-control {
        background-color: rgba(162, 162, 162, 0.05);
        border: 2px solid transparent;
        box-shadow: none;
        .css-319lph-ValueContainer,
        .css-1d8n9bt {
          align-items: center;
          justify-content: center;
          height: 50px;
          width: 54px;
          .css-qc6sy-singleValue {
            margin-bottom: 10px;
          }
        }
      }
      .css-1pahdxg-control {
        border-color: $blue;
      }
      .time-select,
      .css-b62m3t-container,
      .css-3iigni-container {
        margin: 1px;

        background-color: $input-fill;
      }
      .css-319lph-ValueContainer,
      .css-1d8n9bt {
        padding: 14px 18px;

        background-color: $input-fill;
      }
    }
    .left-side-specific {
    }
    p {
      margin: 0;
    }
    @media ($smallPhone) {
      flex-direction: column;
      .left-wrap,
      .right-wrap {
        max-width: unset;
        .in-content {
          flex-wrap: nowrap;
          p {
            width: 100px;
          }
        }
      }
      .clock-times {
        max-width: unset;
      }
    }
  }
  .right-side {
    .visible-date-wrap {
      display: flex;
      align-items: center;
      p {
        margin: 0;
        // margin-right: 20px;
        width: 100px;
      }
    }
  }
  .single-date {
    padding: 12.5px 0;

    .react-datepicker-wrapper {
      display: block;
    }
  }

  .many-dates {
    display: flex;
    flex-wrap: wrap;
    .start-date,
    .end-date {
      display: flex;
    }

    .react-datepicker-wrapper {
      width: 100%;
    }
  }

  .days {
    margin-top: 50px;
    .days-text {
      margin-bottom: 10px;
    }
    .day-wrap > label {
      width: 120px;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      .day {
        margin-left: 15px;
        margin-bottom: 15px;
        // position: absolute;
      }
    }
  }

  .specific-dates {
    .specific-date {
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid rgba($color: $mid, $alpha: 0.25);
      position: relative;
      .trash-icon {
        width: 22px;
        height: 22px;
        position: absolute;
        right: -2px;
        top: -5px;
        font-size: 12px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .add-button {
      width: 120px;
      margin: auto;
      text-align: center;
      cursor: pointer;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      .text {
        margin-right: 5px;
      }
      &:hover {
        .text {
          text-decoration: underline;
        }
      }
    }
    @media ($smallPhone) {
      .titles {
        .top-text.time {
          display: none;
        }
      }
    }
  }

  .react-datepicker-wrapper {
    input[type="text"] {
      padding: 15px 30px 15px 15px;
      background-color: $input-fill;
      border: none;
      border-radius: 2px;
      border: 2px solid transparent;
      position: relative;
      width: 100%;
      // padding: 15px 15px;
      padding: 10px;
      width: 175px;
      // text-align: center;
    }
    input[type="text"]:hover {
      box-shadow: 0px 0px 2px #919191;
      //border: 1px solid #E3E3E3;
    }

    input[type="text"]:focus {
      background-color: white;
      box-shadow: 0px 0px 1px #313131;
      // border: 2px solid #009FFF;
    }
  }

  .date-visible {
    padding: 0;
  }
}
