@import "src/colorVariables.scss";
.visit-faroe-islands-top.edit-module {
  height: unset;
  .background-color {
    .value {
      display: flex;
      gap: 8px;
      > div {
        height: 20px;
        width: 20px;
        border-radius: 2px;
        border: 1px solid rgba(162, 162, 162, 0.3);
        cursor: pointer;
      }
      .color1 {
        background-color: $background-color1;
      }
      .color2 {
        background-color: $background-color2;
      }
      .color3 {
        background-color: $background-color3;
      }
      .color4 {
        background-image: linear-gradient(
          to right,
          white,
          black,
          white,
          black,
          white,
          black,
          white
        );
      }
      .selected {
        border: 2px solid rgba($color: $blue, $alpha: 1);
      }
    }
  }
}
