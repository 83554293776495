@import "src/colorVariables.scss";
.sidebarElement {
  box-shadow: inset -15px 0 15px #eaeaea;
  min-width: 200px;
  max-width: 255px;
  width: inherit;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.03);
  display: flex;
  flex-direction: column;
  overflow: auto;
  h1 {
    font-size: larger;
    font-weight: 700;
    padding: 40px 15px 0 25px;
    margin-bottom: 0;
  }
  > p {
    font-size: 12px;
    color: gray;
    margin: 20px 0 0 25px;
    // font-weight: 600;
  }
  > button {
    padding: 5px 25px;
    color: #313131;
    background-color: transparent;
    // width: 100%;
    border: none;
    text-align: left;
    &:hover{
      background-color: #efefef;
    }
    &[active="true"] {
      background-color: lightgray;
    }
  }
}
