button {
	display: inline-block;
	margin-bottom: 0;
	font-weight: 400;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-image: none;
	border: 1px solid transparent;
	position: relative;

	&.uppercase{
		text-transform: uppercase;
	}

	&.primary {
		color: #fff;
		background-color: #009FFF;
		border-color: #009FFF;
		font-weight: bold;

		&:hover {
			background-color: #008FEF;
			border-color: #008FEF;
		}
	}

	&.danger {
		color: #fff;
		background-color: #d9534f;
		border-color: #d43f3a;

		&:hover {
			background-color: #c9302c;
			border-color: #ac2925;
		}
	}

	&.warning {
		color: #fff;
		background-color: #f0ad4e;
		border-color: #eea236;

		&:hover {
			background-color: #ec971f;
			border-color: #d58512;
		}
	}

	&.info {
		color: #fff;
		background-color: #5bc0de;
		border-color: #46b8da;

		&:hover {
			background-color: #31b0d5;
			border-color: #269abc;
		}
	}

	&.success {
		color: #fff;
		background-color: #5cb85c;
		border-color: #4cae4c;

		&:hover {
			background-color: #449d44;
			border-color: #398439;
		}
	}

	&.default {
		color: #a2a2a2;
		background-color: #fff;
		border-color: #ccc;

		&:hover {
			background-color: #e6e6e6;
			border-color: #adadad;
		}
	}

	&.link {
		font-weight: 400;
		color: #337ab7;
		border-radius: 0;
		background-color: transparent;
		-webkit-box-shadow: none;
		box-shadow: none;
		border-color: transparent;

		&:hover {
			color: #23527c;
			text-decoration: underline;
			background-color: transparent;
		}
	}

	&.black{
		color: #fff;
		background-color:black;
		border-color: black;
		&:hover{
			background-color:#333;
			border-color: #333;
		}
	}

	&.size_normal{
		padding: 4px 35px;
		font-size: 14px;
		line-height: 1.42857143;
		border-radius: 4px;
	}

	&.size_large{
		padding: 15px 25px;
    font-size: 17px;
  	letter-spacing: 0;
  	line-height: 21px;
  	border-radius: 3px;
	}

	&.size_small{
		padding: 5px 35px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
	}

	&.block{
		display: block;
    width: 100%;
	}

	&.rounded{
		border-radius: 999px;
	}

	&.icon_right{
		padding-right: 55px;
	}

	&.icon_left{
		padding-left: 55px;
	}

	.iconCont{
		display: inline-block;
		position: absolute;
		&.right{
			right: 15px;
		}

		&.left{
			left: 15px;
		}
	}
}