@import "src/colorVariables.scss";
.filter-media {
  .search-box {
    margin-top: 5px;
    margin-bottom: 10px;
    input {
      color: white;
    }
  }
  .top-dropdown {
    display: flex;
    align-items: center;
    // border: 1px solid gray;
    padding: 5px 10px;
    cursor: pointer;
    user-select: none;
    .text {
      flex-grow: 1;
      text-transform: uppercase;
      font-size: 12px;
    }
    svg {
      font-size: 16px;
    }
  }
  .filter-content {
    padding: 5px 10px;
    label {
      margin: 0;
      margin-top: 2px;
      margin-bottom: 3px;
      font-size: 0.9em;
      display: flex;
      gap: 10px;
    }
    .headline {
      margin-top: 6px;
      font-weight: bold;
    }
    .user-wrap {
      display: flex;
      align-items: center;
      // label{
      //     margin: 0;
      //     padding: 0;
      // }

      .user-image {
        width: 30px;
        height: 30px;
        border-radius: 15px;
        object-fit: cover;
      }
      .user-info {
        .darker {
          color: $mid;
        }
      }
    }
    .dates-wrap {
      display: flex;
      gap: 10px;
      .date {
        position: relative;
        margin-bottom: 10px;
        .react-datepicker-wrapper {
          display: flex;
          height: 30px;

          input[type="text"] {
            height: 100%;
            padding: 15px;
            background-color: $input-fill;
            border: none;
            border-radius: 2px;
            border: 2px solid transparent;
            position: relative;
            width: 100%;
            color: white;
          }
          input[type="text"]:hover {
            box-shadow: 0px 0px 2px #919191;
            //border: 1px solid #E3E3E3;
          }

          input[type="text"]:focus {
            background-color: white;
            box-shadow: 0px 0px 1px #313131;
            border: 2px solid #009fff;
          }
        }
        .clear-date {
          position: absolute;
          right: 4px;
          bottom: 0;
          cursor: pointer;
        }
      }
    }
    .sort-by {
      cursor: pointer;
      user-select: none;
      .radio-check {
        .vfi-radio-2 {
          border-color: white;
        }
        input:checked ~ .vfi-radio-2 {
          .dot-inside {
            background-color: white;
          }
        }
      }
    }
  }
}
