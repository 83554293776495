.main-pages {
  .wrap-tree-edit {
    display: flex;
    width: 100%;
    height: 100%;
    .sites-pages-wrap {
      flex-grow: unset;
      height: unset;
    }
    .wrap-component {
      flex-basis: 100%;
      width: 0;
    }
  }
  .header {
    .left {
      .title {
        font-size: larger;
      }
    }
  }
}
.content {
  .wrap-component {
    padding: 30px;
    padding-top: 0px;
    height: 100%;
  }
}
