.button{
	text-align: center;
	font-weight: 600;
	font-size: 18px;
	line-height: unset;
	cursor: pointer;

	// Button types
	&.primary{
		background-color: #009fff;
		color: white;
	}

	&.secondary{
		background-color: #a2a2a24d;
		color: #31313177;
	}

	&.black{
		background-color: #000;
		color: #fff;

		&:disabled{
			opacity: 0.2;
		}
	}

	// Sizes
	&.small{
		padding: 5px 10px;
		font-size: 16px;
	}

	&.medium{
		padding: 10px 20px;
	}

	&.large{
		padding: 15px 40px;
	}

	// Block-level button
	&.block{
		width: 100%;
	}

	// On hover over all buttons
	&:hover:not(:disabled){
		opacity: 0.8;
	}

	// Disabled button
	&:disabled{
		opacity: 0.5;
		cursor: not-allowed;
	}
}
