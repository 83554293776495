@import "src/colorVariables.scss";
// Before grid styling ------------------------------------------------------

.media-grid.media-grid-team {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  > div.add-media {
    display: none;
  }
  .grid-container {
    display: flex;
    min-height: 450px;
    width: 1280px;
    margin: auto;
    gap: 52px 64px;
    flex-wrap: wrap;

    .item {
      height: calc(450px - 6px);
      margin-top: 3px;
      width: calc((1280px / 4) - 48px);

      .one-media {
        img {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }
        height: calc(65% + 52px);
        .add-media {
          height: unset;
          background-color: transparent;
        }
      }
      .team-info {
        height: 35%;
        margin-top: -52px;

        > div:not(.errors) {
          padding: 20px;
          border: 1px solid rgba(162, 162, 162, 0.2);
          border-top: 0;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }
        p {
          margin: 0;
          line-height: 1em;
          font-size: 1em;
          font-weight: 100;
        }
        a {
          color: inherit;
        }
      }
      .bottom-shadow {
        top: calc(25% + 52px);
        bottom: unset;
      }
    }
    .add-team {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f6f6f6;
      cursor: pointer;
      max-height: 450px;
      opacity: 0.6;
      svg {
        color: black;
      }
    }
    .add-team:hover {
      opacity: 1;
    }
  }
}
.smallerDesktop {
  .media-grid.media-grid-team {
    .grid-container {
      justify-content: center;
    }
  }
}
