@import "src/colorVariables.scss";

.product {
  overflow: auto;
  width: 100%;
  position: absolute;
  background-color: #fafafa;
  .mask {
    background-color: rgba(162, 162, 162, 0.3);
    height: 100%;
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 3;
  }
  .top {
    background-color: white !important;
  }
  .drag-drop {
    margin: unset;
    display: flex;
    align-items: flex-end;
    text-align: center;
    gap: 2.4rem;
    .dropzone {
      width: 50%;
    }
    .add,
    .upload-box .text-content {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-direction: column;
      height: 150px;
      width: 100%;
      border: 2px solid #f5f5f5;
      padding: 20px;
    }
    .add {
      // background-color: $mid-between;
      height: 40px;
      width: 40px;
      svg {
        height: 20px;
        width: 20px;
      }
    }
    .upload-bar {
      height: 5px;
      width: 50%;
      top: 50%;
      left: 50%;
      .progress-bar {
        height: 100%;
        background-color: $blue;
      }
    }
  }
  .max-width {
    display: flex;
    padding: 2.4rem;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1280px;

    .content {
      width: 100%;
      background-color: unset;

      .editInventoryComponent {
        padding: 20px;
        background-color: white;
        box-shadow: 0 0 10px rgba(162, 162, 162, 0.1);
        // position: fixed;
        // z-index: 9;
        // top: 50%;
        // right: 50%;
        // transform: translate(50%, -50%);
        display: flex;
        min-width: 600px;
        justify-content: space-between;
        .left-content {
          border-right: 1px solid rgba(162, 162, 162, 0.1);
          padding-right: 20px;
          display: flex;
          flex-direction: column;
          gap: 20px;
          max-width: 250px;
          > div {
            &.stock {
              > div {
                flex-wrap: wrap;
              }
            }
            display: flex;
            flex-direction: column;
            gap: 10px;
            > div {
              position: relative;

              display: flex;
              gap: 10px;
              &.extra-price {
                gap: 0;
                .select {
                  > div {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;

                    border-left: 0;
                  }
                }
                input {
                  border-top-right-radius: 0;
                  border-bottom-right-radius: 0;
                }
              }
              > div {
                position: relative;
                flex: 1 1 auto;
                &.select-container {
                  min-width: 110px;
                }
              }
              .select {
                > div {
                  border: 1px solid rgba(162, 162, 162, 0.2);
                  background-color: #fafafa;
                }
              }
            }
          }
        }
        .right-content {
          padding-left: 8px;
          min-width: 200px;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 20px;
          .cta {
            display: flex;
            justify-content: flex-end;
            gap: 20px;
          }

          .wrap-price {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            table {
              border-collapse: collapse;
              text-align: center;
              td {
                padding: 0;
              }
              td:first-of-type,
              th:first-of-type {
                text-align: left;
								max-width: 100px;
              }
              tr:not(:nth-child(2)) {
                td:first-of-type {
                  padding-top: 32px;
                }
                td:nth-child(2) {
                  .line {
                    position: relative;
                    width: 50%;
                    margin-left: 50%;
                    &::before {
                      content: "";
                      height: 10px;
                      bottom: -10px;
                      left: 0;
                      position: absolute;
                      border-left: 1px solid;
                    }
                    &::after {
                      content: "▾";
                      position: absolute;
                      left: -7px;
                      bottom: 0;
                      width: 0;
                      height: 0;
                      clear: both;
                    }
                  }
                }
                td:last-of-type {
                  .line {
                    width: 50%;
                    margin-right: 50%;
                    border-right: 1px solid;
                  } 
                }
                .line {
                  height: 16px;
                  border-bottom: 1px solid;
                  margin-bottom: 16px;
                }
              }
            }
          }
        }
      }
      label {
        position: absolute;
        left: 10px;
        top: 5px;
        font-size: 14px;
        color: $dark-blue;
        z-index: 1;
      }

      .div-input {
        min-height: 112px;
      }

      .wrap-column {
        display: flex;
        justify-content: center;
        gap: 2.4rem;
        padding-bottom: 60px;
        > div {
          display: flex;
          flex-direction: column;
          gap: 2.4rem;
          > div {
            gap: 2.4rem;
            padding: 2.4rem;
            background-color: white;
            box-shadow: 0 0 10px rgba(162, 162, 162, 0.1);
            // border: 1px solid rgba(162,162,162,0.2);
            border-radius: 0.2rem;
            display: flex;
            flex-direction: column;
            gap: 2.4rem;
            > div {
              position: relative;
            }
          }
          .price,
          .inventory {
            table {
              tr {
                // th,

                td {
                  // text-align: right;
                  padding-left: 20px;
                  position: relative;
                  padding-top: 1.2rem;
                  // max-width: 100px;
                  label {
                    left: 30px;
                    top: calc(1.2rem + 5px);
                  }
                  input {
                  }
                  .select {
                    text-align: left;
                    > div {
                      align-items: center;
                    }
                  }
                  .choose-rate,
                  .rateType {
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    top: calc(50% + (0.6rem));
                    transform: translateY(-50%);
                    left: 30px;
                  }
                  .rateType {
                    width: 50px;
                    align-items: flex-start;
                    justify-content: flex-start;
                  }
                  .choose-rate {
                    top: calc(50% + (0.6rem));
                    gap: 4px;
                    .selected {
                      background-color: $blue;
                      color: white;
                    }
                    > div {
                      border-radius: 4px;
                      width: 22px;
                      height: 22px;
                      cursor: pointer;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }
                  }
                  input.rate {
                    padding: 22px 10px 8px 50px;
                  }
                  .edit-price {
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 30px;
                    height: 30px;
                    &:hover {
                      background-color: rgba(162, 162, 162, 0.1);
                    }
                  }
                }
                td:last-of-type {
                  width: 40px;
                }
                .unit {
                  > div {
                    > div {
                      position: relative;
                      .edit-unit {
                        position: absolute;
                        top: 5px;
                        right: 10px;
                        font-size: 12px;
                        cursor: pointer;
                      }
                      label {
                        top: 5px;
                        left: 10px;
                      }
                      input {
                        // padding: 22px 30px 8px 10px;
                      }
                    }
                    .select {
                      min-width: 126px;
                      top: 1.2rem;
                      display: none;
                      position: absolute;
                      z-index: 9;
                      &.show {
                        display: unset;
                      }
                    }
                  }
                }
                th {
                  font-weight: 400;
                  opacity: 0.4;
                  padding-left: 20px;
                  padding-bottom: 1.2rem;
                  border-bottom: 1px solid rgba(162, 162, 162, 0.2);
                }
                th:first-of-type,
                td:first-of-type {
                  text-align: left;
                  padding-left: 0;
                }
              }
              // tr:nth-child(2) {
              //   td:first-of-type {
              //     opacity: 0.5;
              //   }
              // }
            }
          }
          .general-information {
            .rating {
              input {
                width: 40%;
              }
            }
						.general-info-cp{
							display: flex;
							flex-direction: row;
							gap: 25px;
							justify-content: end;
							margin-top: 25px;

						}
          }
          .media {
						position: relative;
						.media-preload-container{
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 50px;
							padding-top: 25px;
						}
            .wrap-image {
              width: 100%;
              display: flex;
              flex-direction: column;
              gap: 1.2rem;

              .add {
                // position: absolute;
                // top:0;
              }
              .images {
                display: flex;
                flex-wrap: wrap;
                gap: 1.2rem;
                .one-media {
                  height: 150px;
                  min-width: 150px;

                  margin: unset;
                }
              }
            }
            .wrap-onemedia {
              position: relative;
              .one-media {
                height: 150px;
                min-width: 150px;
                margin: unset;
              }
              .remove-image {
                top: 4px;
                right: 6px;
                height: 20px;
                width: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                border-radius: 2px;
                cursor: pointer;
                color: #f64e60;
              }
              .remove-image:hover {
                background-color: white;
              }
            }
						.external-media{
							width: 100%;
							.external-items-container{
								width: 100%;
								display: grid;
								grid-template-columns: repeat(auto-fill, minmax(150px, 200px));
								gap: 25px;
								.image-container{
									box-shadow: 5px 5px 15px 5px #0000001f;
									aspect-ratio: 1;
									img{
										height: 100%; 
										width:100%;
										object-fit: cover;
									}
								}	
							}
						}
          }
          .select-varient {
						position: relative;

						.variants-cp{
							display: flex;
							justify-content: end;
							position: absolute;
							top: 0;
							left: 0;
							padding: 25px;
							width: 100%;
							.add-variant-preload-container{
								height: 50px;
								padding-top: 25px;
							}
						}
            .wrap-varients {
              // display: flex;
							// flex-direction: row;
              // border-radius: 5px;
              // border: 1px solid rgba(162, 162, 162, 0.2);

							min-width: 700px;
							.variant-card{
								// min-width: 700px;
								.variant-card-header{
									background-color: #fbfbfb;
									border-bottom: 1px solid rgba(162, 162, 162, 0.2);
									cursor: pointer;
								}
								.variant-card-content{
									// min-width: 700px;
									flex-direction: column;
									.variant-card-cp{
										display: flex;
										flex-direction: row;
										justify-content: end;
										gap: 15px;
										padding: 10px 25px;
										.delete-button{
											cursor: pointer;
											color: #f64e60;
										}
									}
									.variant-details{
										.variant-label{
											display: flex;
											flex-direction: row;
											align-items: center;
											gap: 25px;
											width: 100%;
											.label-label{
												font-weight: bold;
											}
											.edit-label-input{
												padding: 0;
											}
											.edit-label-button{
												cursor: pointer;
												&.check-button{
													color: green;
												}
												&.cancel-button{
													color: #f64e60;
												}
											}
										}
									}
									.property-edit-td{
										display: flex;
										flex-direction: row;
										gap: 25px;
										.delete-property-btn{
											color: #f64e60;
											margin-top: 5px;
											cursor: pointer;
										}
									}
									.variant-card-media{
										position: relative;
										.preloader-cont{
											position: absolute;
											top: 25px;
											right: 25px;
										}
									}
									>div{
										padding: 25px 25px;
										display: flex;
										flex-direction: column;
										align-items: start;
										table{
											width: 100%;
											td,th{
												border-bottom: 1px solid rgba(162, 162, 162, 0.2);			
											}
										}
										.edit-details-btn{
											cursor: pointer;
											width: 100%;
											display: flex;
											flex-direction: row;
											justify-content: end;
										}
										.property-edit-container{
											display: flex;
											justify-content: end;
											width: 100%;
											.edit-property-btn{
												cursor: pointer;
											}
											.cta {
												display: flex;
												justify-content: flex-end;
												gap: 20px;
											}
										}
									}
								
									.overlay-inner{
										margin-top: 150px;
										color: white;
										h3, p{
											text-align:center;
										}
									}

									.add-property-row{
										display: block;
										width: 100%;
										.add-property-field{
											display: flex;
											width: 100%;
											gap: 20px;
											.add-property-select{
												min-width: 150px;
											}
										}
									}
									.value-input{
										padding: 0 10px;
									}
								}
								.delete-preloader-container{
									height: 50px;
									padding-top: 25px;
								}

								.external-media{
									.items-container{
										width: 100%;
										display: grid;
										grid-template-columns: repeat(auto-fill, minmax(150px, 200px));
										gap: 25px;
										.media-item{
											box-shadow: 5px 5px 15px 5px #0000001f;
											aspect-ratio: 1;
											img{
												height: 100%; 
												width:100%;
												object-fit: cover;
											}
										}
									}
								}
							}
              // .wrap-image {
              //   .images {
              //     justify-content: unset !important;
              //   }
              // }
              // > div {
              //   input {
              //     display: unset;
              //   }
              //   position: relative;
              //   width: 100%;
              //   border-bottom: 1px solid rgba(162, 162, 162, 0.2);
              //   > div {
              //     display: flex;
              //     justify-content: space-between;
              //     // align-items: center;
              //     > div:not(.drag-drop) {
              //       padding: 20px;
              //       align-items: center;
              //       display: flex;
              //       gap: 20px;
              //     }
              //   }
              //   &:last-child {
              //     border-bottom: 0;
              //   }
              // }
              // .wrap-table {
              //   width: 100%;
              //   padding: 20px;
              //   box-shadow: 0px 5px 10px -5px inset rgba(162, 162, 162, 0.2);

              //   flex-direction: column;

              //   table {
              //     width: 100%;
              //   }
              // }
            }
          }
          .availability,
          .warehouse {
            > div {
              display: flex;
              align-items: center;
              justify-content: space-between;
              label {
                cursor: pointer;
                width: 100%;
                position: unset;
                top: 0;
                margin-bottom: 0;
              }
              input {
                cursor: pointer;
                display: unset;
              }
            }
          }
        }
        .first-column {
					min-width:600px;
          width: 70%;
        }
        .second-column {
					min-width:300px;
          width: 30%;

					.brand-update-btn-container{
						display: flex;
						flex-direction: row;
						gap: 20px;
						justify-content: end;
					}

					.storage{
						table{
							width: 100%;
						}
					}
					.product-prices{
						.product-price-rules{
							table{
								width: 100%;
								td{
									padding: 10px;
									.delete-btn{
										cursor: pointer;
										color: #f64e60;
									}
								}
							}
						}
						.load-container{
							height: 40px;
						}
						.price-rule-label-input{
							margin-bottom: 25px;
							padding: 15px;
						}
						.form-input-container{
							display: flex;
							flex-direction: row;
							gap: 15px;
							justify-content: space-between;
							margin-bottom: 45px;
							.rate-input{
								max-width:100px;
								padding: 5px 15px;
							}
						}
						.add-cp-btn-container{
							display: flex;
							flex-direction: row;
							gap: 15px;
							justify-content: end;
						}
					}

					// Publicity panel
					.publicity{
						
					}
        }
      }
    }
  }
	.overlay-inner{
		width: 500px;
		color: white;
		margin: 30% auto;
		text-align: center;
	}
}
