@import "src/colorVariables.scss";
.whatson-session-edit-view {
  min-width: 500px;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  .box {
    //   height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: $lighter-blue;
    border-radius: 2px;
  }
  .more-time {
    padding: 20px 70px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    button {
      background-color: $blue;
      color: white;
      font-size: 13px;
      border-radius: 4px;
      padding: 5px 15px;
      letter-spacing: 0.43px;
      font-weight: 300;
    }
  }
  .default {
    padding: 10px 20px;
  }
}
