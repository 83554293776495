


.container {
	z-index: 100;

	padding-top: 200px;

	width: 100vw;
	height: 100vh;

	font-size: 50px;
	color: #fff;

	position: fixed;
	top: 0;
	left: 0;

	background-color: rgba(100, 100, 100, 0.5);
	// background-color: #fff;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;

	gap: 20px;
}