@import "src/colorVariables.scss";
.vfi-carousel.edit-module {
  height: 630px;
  width: 430px;
  display: flex;
  flex-direction: column;
  user-select: none;
  margin: 0;

  #handle {
    cursor: move;
    height: 28px;
    display: flex;
    align-items: center;
    margin-left: 8px;
    svg {
      margin: 0 1px;
    }
  }
  .top {
    background-color: $light;
    display: flex;
    justify-content: center;
    height: 26px;
    flex-shrink: 0;
    .top-choice {
      padding: 0 22px;
      font-size: 13px;
      font-weight: bold;
      letter-spacing: 1px;
      user-select: none;
      cursor: pointer;
      color: $mid;
      height: 100%;
      display: grid;
      place-items: center;
      text-transform: uppercase;

      &.active {
        color: black;
      }
    }
  }
  .sub-screens {
    display: flex;
    justify-content: space-evenly;
    margin: 10px 0;
    padding: 6px;
    > div {
      cursor: pointer;
      padding: 0 10px;
      font-weight: 600;
    }
    .active {
      border-bottom: 1px solid;
    }
  }
  .close {
    top: 6px;
    right: 6px;
  }
  .content {
    display: block;
    width: unset;
    padding-top: 0;
    max-height: 604px;
    overflow: auto;
    .pages {
      .search {
        display: flex;
        align-items: center;
        padding: 10px 0 0 0px;
        position: sticky;
        top: 0;
        background-color: white;
        input {
          background-color: $mid-between;
          transform: unset;
          top: unset;
        }
      }
      .list {
        .each-list-page {
          display: flex;
          padding: 10px 10px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          align-items: center;
          .image {
            height: 70px;
            width: 70px;
            min-width: 70px;
            img {
              height: 100%;
              width: 100%;
              object-fit: contain;
            }
          }
          .page-info {
            padding: 0 10px;
            .page-title {
              display: flex;
              align-items: center;
              .menu-hidden {
                margin-left: 10px;
              }
            }
            .last-edit {
              font-size: 12px;
              color: $mid;
            }
          }
        }
      }
    }
    .modules {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 10px;
      padding: 5px;
      .wrap-modules {
        > div:hover {
          background-color: rgba($color: $mid, $alpha: 0.1);
          cursor: pointer;
        }
      }
      .wrap-modules {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        > div {
          display: flex;
          width: calc(50% - 5px);
          height: 120px;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .templates {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 10px;
      padding: 5px;
      .wrap-templates:hover {
        background-color: rgba($color: $mid, $alpha: 0.1);
        cursor: pointer;
      }

      .wrap-templates {
        width: calc(50% - 5px);
        height: 120px;
        &.selected {
          background-color: rgba($color: $mid, $alpha: 0.1);
        }
        .template {
          padding: 25px;
          width: 100%;
          height: 100%;
          display: flex;
          gap: 2px;
        }
        .reverse {
          flex-direction: row-reverse;
        }
        .template {
          .td {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 2px;
            > div {
              height: 100%;
              width: 100%;
              border: 1px solid;
              display: grid;
              place-items: center;
            }
          }
        }
      }
    }
  }
}
