.domainBanner {
  position: fixed;
  top: 0;
  left: 50%;

  transform: translateX(-50%);

  background-color: #fff59d;
  z-index: 999999999;

  padding: 1em 1.25em;
  opacity: 80%;
  border-radius: 0 0 5px 5px;

  font-weight: 600;

  pointer-events: none;

  .spanBold {
    font-weight: 700;
  }

  pointer-events: auto;

  &.collapsed {
    height: 1em;
    overflow: hidden;

    width: 200px;
    padding: 0;

    .wrapper {
      display: none;
    }

    &:hover {
      background-color: darken(#fff59d, 10%);
    }
  }
}
