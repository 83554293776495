@import "src/colorVariables.scss";
.main {
  .content {
    .overlay {
      .media-edit {
        height: 100%;
        .header {
          height: 110px;
          padding: 30px 40px;
          font-size: 16px;
          text-align: right;
          .save,
          .cancel {
            position: relative;
            display: inline-block;
            width: 90px;
            height: 100%;
            border-radius: 2px;
            cursor: pointer;
            transition: ease-in-out 0.2s;
            .text {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              display: inline-block;
              vertical-align: middle;
              margin: auto;
              font-weight: 600;
              font-size: 17px;
            }
          }
          .save {
            background-color: $blue;
            color: white;
          }
          .cancel {
            background-color: $lighter;
            color: rgba($mid, 0.5);
          }
          .save:hover {
            background-color: $light-blue;
            color: white;
          }
          .cancel:hover {
            background-color: $light;
            color: rgba($mid, 0.8);
          }
          .save,
          .cancel,
          .undo,
          .redo {
            margin-left: 20px;
            vertical-align: middle;
          }
          .close {
            float: left;
            opacity: 1;
            cursor: pointer;
            height: 100%;
            display: table;
            .icon {
              display: table-cell;
              vertical-align: middle;
              text-align: center;
              height: 100%;
            }
          }
        }
        .body {
          height: calc(100% - 110px);

          .crop-top {
            height: 100%;
            max-height: 55%;
            padding: 40px;
            .crop-display {
              //max-width: 60%;
              width: 60%;
              height: 100%;
              float: left;
              text-align: center;
              overflow: auto;
              .ReactCrop {
                // height: 100%;
                // overflow: auto;
                > div,
                img {
                  // height: 100%;
                  //max-height: 100%;
                }
                > div {
                  //display: inline-block;
                  //max-width: 1000%;
                  //height:100%;
                  // min-width: 1000%;
                }
                img {
                  //object-fit: contain;
                  //margin: auto;
                  //height: 100%;
                }
              }
            }
            .crop-tweaks {
              display: inline-block;
              height: 100%;
              width: 40%;
              padding: 0 20px;

              p {
                color: $mid;
                font-size: 12px;
                //margin-bottom: 0;
                font-weight: 600;
              }

              input[type="range"] {
                width: 100%;
                -webkit-appearance: none;
                background: #d3d3d3;
                height: 1px;
                margin-bottom: 50px;
              }
              input[type="range"]::-moz-range-thumb {
                width: 9px;
                height: 9px;
                border: 2px solid black;
                background: white;
                cursor: pointer;
              }
              input[type="range"]::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                width: 12px;
                height: 12px;
                border: 2px solid black;
                border-radius: 6px;
                background: white;
                cursor: pointer;
              }

              .aspect-ratio {
                display: inline-block;
                font-size: 10px;
                text-align: center;
                vertical-align: bottom;
                font-weight: bold;
                color: black;
                background-color: transparent;
                border: 1px solid rgba(162, 162, 162, 0.3);
                border-radius: 2px;
                margin-right: 10px;
                position: relative;
                cursor: pointer;
                transition: ease-in-out 0.5s;
                span {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
              }

              .aspect-ratio.selected {
                background-color: $dark;
                color: white;
              }

              .one-one {
                width: 24px;
                height: 24px;
              }

              .one-two {
                width: 24px;
                height: 48px;
              }

              .two-three {
                width: 24px;
                height: 37px;
              }

              .three-two {
                width: 24px;
                height: 17px;
              }

              .three-four {
                width: 24px;
                height: 33px;
              }

              .four-three {
                width: 24px;
                height: 19px;
              }

              .four-five {
                width: 24px;
                height: 31px;
              }

              .nine-sixteen {
                width: 24px;
                height: 45px;
              }

              .sixteen-nine {
                width: 45px;
                height: 24px;
              }

              .custom {
                width: 49px;
                height: 23px;
              }
            }
          }
          .crop-bottom {
            height: 45%;
            background-color: $light;
            .cloudinary-images {
              height: 100%;
              overflow: auto;
              // display: grid;
              // display: flex;
              flex-direction: column;

              // .one-one{grid-area: one;}
              // .one-two{grid-area: two;}
              // .two-three{grid-area: three;}
              // .three-two{grid-area: four;}
              // .three-four{grid-area: five;}
              // .four-three{grid-area: six;}
              // .four-five{grid-area: seven;}
              // .nine-sixteen{grid-area: eight;}
              // .sixteen-nine{grid-area: nine;}

              grid-template-areas:
                "one one three four four eight five two"
                "one one nine nine six eight seven two";
              grid-template-rows: 50% 50%;

              .cloudinary-suggestions {
                margin: auto;
                max-width: 1362px;
                .c-image {
                  //position: absolute;
                  // display: inline-block;
                  float: left;
                  margin-top: 12px;
                  margin-left: 12px;
                  height: 100%;
                  vertical-align: top;
                  //width: 30%;
                  // img{
                  //     height: 100%;
                  //     width: 100%;
                  //     object-fit: cover;
                  //     transition: ease-in-out 2s;
                  //     cursor: pointer;
                  // }
                  // img:hover{
                  //     height: 100%;
                  //     width: 100%;
                  //     object-fit: contain;
                  // }
                  img {
                    height: 100%;
                    //width: 100%;
                    object-fit: contain;
                    transition: ease-in-out 2s;
                    cursor: pointer;
                  }
                }

                .c-image.one {
                  width: 409px;
                  height: 330px;
                }
                .c-image.two {
                  width: 238px;
                  height: 159px;
                }
                .c-image.three {
                  width: 498px;
                  height: 159px;
                }
                .c-image.four {
                  width: 409px;
                  height: 159px;
                }
                .c-image.five {
                  width: 123px;
                  height: 159px;
                }
                .c-image.six {
                  width: 192px;
                  height: 159px;
                }
                .c-image.seven {
                  width: 157px;
                  height: 330px;
                  vertical-align: top;
                }
              }
            }
          }
        }
        .loading-blur {
          position: fixed;
          background-color: rgba(black, 0.6);
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          progress {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 20%;
            height: 6px;
            border: none;
            background-color: white;
          }

          progress::-webkit-progress-bar {
            background-color: white;
          }

          progress::-webkit-progress-value {
            background-color: $blue;
          }

          progress::-moz-progress-bar {
            background-color: $blue;
          }
        }
      }
    }
  }
}
