@import "src/colorVariables.scss";
.overlay {
  .advanced-text-area {
    > h1 {
      position: absolute;
      top: 15px;
    }
    //margin: 100px 20px 0 20px;
    padding: 75px 20px 85px;
    height: 100%;
    .vfi-text-area {
      height: 100%;
      //overflow: hidden;
      .ck-content {
        height: 100%;
      }
      .ck-editor {
        height: 100%;
        .ck-editor__top {
          display: block;
        }
        .ck-editor__main {
          height: calc(100% - 40px);
          border: 1px solid var(--ck-color-toolbar-border);
          .ck-content.ck-blurred {
            background-color: white;
          }
          .ck-content {
            height: 100%;
          }
        }
      }
    }

    .cancel-button,
    .update-button {
      display: inline-block;
      position: absolute;
      font-size: 17px;
      font-weight: 600;
      padding: 5px 10px;
      bottom: 25px;
      cursor: pointer;
    }

    .cancel-button {
      background-color: $light;
      right: 20px;
    }
    .update-button {
      background-color: $blue;
      color: white;
      right: 115px;
    }
  }
}
