@import "src/colorVariables.scss";
.infoscreen-content {
  height: 100%;

  .infoscreen-articles {
    height: 100%;
  }

  .infoscreen-view {
    display: flex;
    height: 100vh;

    .heading-padding {
      padding: 40px 15px 0 25px;
      margin-bottom: 20px;
      min-height: 64px;
      &.sub {
        font-weight: 600;
        font-size: 12px;
        color: $mid;
        margin-bottom: 5px;
        padding-top: 142px;
      }
    }

    // List option stylings..
    .list-parent {
      height: 100%;
      overflow-y: auto;
      width: 200px;
      &.gray {
        background-color: rgba(0, 0, 0, 0.03);
        box-shadow: inset -15px 0 15px #eaeaea;
      }
    }

    .list-option {
      display: flex;
      // justify-content: space-between;

      padding: 5px 10px 5px 25px;
      cursor: pointer;
      color: #313131;

      min-width: 155px;

      input#name {
        width: 90%;
        height: 24px;
      }
      .icon {
        width: 30px;
      }
    }

    .list-option:hover:not(.disabled) {
      background-color: rgba($color: #efefef, $alpha: 1);

      .infoscreen-options-button {
        opacity: 1;
      }
    }

    .list-option.disabled {
      color: #cccccc;
      cursor: unset;
    }

    .list-option.selected {
      // background-color: lightgray;
      background: rgb(211, 211, 211);
      background: linear-gradient(
        90deg,
        rgba(211, 211, 211, 1) 0%,
        rgba(218, 218, 218, 1) 50%,
        rgba(227, 227, 227, 1) 80%
      );
    }

    .infoscreen-articles {
      display: flex;
      width: 100%;
      height: 100%;
      .edit-page.row {
        width: 100%;
        height: 100%;
      }
      .is-articles-content {
        flex-grow: 1;
        .is-articles-display {
          flex-grow: 1;
        }
      }
    }
  }
  .blur {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.5);
    z-index: 1;
    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 24px;
      color: white;
    }
  }
}
