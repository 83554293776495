.text-and-expand {
  display: flex;
  width: 924px;
  margin: auto;
  gap: 70px;
  // justify-content: center;
  // margin-left: 70px;
  .tae-second {
    width: 490px;
  }
  .vfi-text-area {
    min-width: unset;
    margin-left: 100px;
  }
  .expand-vertical {
    margin-right: -136px;
    margin-top: 12px;
  }
  &.swapped {
    .vfi-text-area {
      margin-left: unset;
    }
    .tae-first {
      order: 1;
    }
    .tae-second {
      order: 4;
    }
    .tae-third {
      order: 3;
    }
    //   .tae-fourth {
    //     order: 2;
    //   }
    .tae-first {
      min-width: 600px;
    }

    .expand-vertical {
      margin-right: unset;
      margin-left: -136px;
    }
  }
}

// .tablet {
//   .text-and-expand {
//     .tae-first {
//       display: none;
//     }

//     .tae-second {
//       width: 100%;
//       margin: 0;
//     }

//     .expand-vertical {
//       max-width: 400px;
//       margin: auto;
//     }
//   }
// }

.phone,
.tablet {
  .text-and-expand {
    padding-bottom: 4px;
    flex-wrap: wrap !important;

    .tae-first {
      display: none;
    }

    .tae-second {
      width: 100%;
      max-width: 100%;
      margin: 0;
    }

    .vfi-text-area,
    .expand-vertical {
      // width: 100%;
      flex: 0 0 100%;
      // max-width: 400px;

      margin: auto;
    }

    .expand-vertical {
      margin-top: 10px;
    }

    .expand-vertical {
      position: relative;
      right: unset;
      .vfi-text-area {
        margin-left: 0;
        margin-right: 0;
        padding: 0 12px;

        .ck-content {
          margin-top: 36px;
        }
      }
    }

    .tae-third {
      display: none;
    }
  }
}
.tablet {
  .text-and-expand {
    .vfi-text-area {
      max-width: 724px;
      flex-direction: column;
    }
  }
}
