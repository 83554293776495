@import "src/colorVariables.scss";

$color-border-light: rgba(162, 162, 162, 0.2);

$vfi-dark: #373737;

@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap");
$LibreBasker: "Libre Baskerville";
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700;800&display=swap");
$sansPro: "Source Sans Pro";

.auto-related {
  max-width: 1440px;
  margin: auto;
  //   &.media.module.row {
  // 	 max-width: 1440px;
  //   }
}
.auto-related {
  padding: 0;

  margin-top: 5rem;
  .auto-related-title {
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    h2 {
      opacity: 0.2;
      color: $vfi-dark;
      font-family: $LibreBasker;
      font-size: 30px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 35px;
      text-align: center;
      margin: 0;
    }
  }

  .auto-related-content {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(auto-fill, 1fr);
    gap: 40px;

    background-color: #f2f2f2;
    padding: 40px;

    .auto-related-item {
      position: relative;
      width: 100%;
      aspect-ratio: 1;

      .bottom-shadow {
        position: absolute;
        height: 100%;
        width: 100%;
        bottom: 0;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.6) 0%,
          rgba(0, 0, 0, 0) 100%
        );
      }
      h3 {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        color: #ffffff;
        font-family: $LibreBasker;
        font-size: clamp(1.063rem, 2.5vw, 1.313rem);
        font-weight: bold;
        letter-spacing: 0;
        line-height: 30px;
        text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3);

        margin: 0;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.display.display-active {
  .auto-related-module {
    max-width: 1360px !important;

    .auto-related-content {
      gap: 25px;

      .auto-related-item {
        width: 236px;
        height: 236px;
      }
    }
  }
}
