@import "src/colorVariables.scss";
.read-also {
  font-family: "Libre Baskerville";
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  margin: auto;
  width: 700px;
  .read-also-wrap {
    &.active {
      pointer-events: all;
      cursor: pointer;
    }
  }
  svg,
  .read-also-sign {
    color: $blue;
    font-weight: bold;
  }

  //new
  svg {
    font-size: 21px;
    font-weight: 500;
    line-height: 36px;
  }
}
.phone {
  .read-also {
    flex: 0 0 calc(100% - 40px);
    max-width: calc(100% - 40px);
    font-size: 17px;
  }
}
.tablet {
  .read-also {
    .read-also-wrap {
      max-width: 724px;
      margin-left: 12px;
    }
  }
}
