@import "src/colorVariables.scss";
.main {
  .content {
    .overlay {
      .wrapper {
        height: 100%;
        display: flex;
        width: 100%;
        // align-items: stretch;
        .user-panel {
          flex-grow: 3;
          height: 100%;
          overflow: hidden;
          .top-header {
            height: 30%;
            min-height: 180px;
            // background-color: $lighter;
            background-color: rgba(162, 162, 162, 0.1);
            border-bottom: 1px solid $light;

            .header {
              height: 80%;
              padding: 20px 30px 0 30px;
              font-size: 16px;
              text-align: center;

              .close {
                float: left;
                opacity: 1;
                cursor: pointer;
                height: 100%;
                display: table;
                .icon {
                  display: table-cell;
                  height: 100%;
                }
              }
              .close > svg {
                vertical-align: middle;
              }

              .imgtext {
                font-size: 10px;
                color: $dark;
                text-align: center;
              }

              .userInfo {
                // display: inline-block;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 100%;
                margin: auto;

                // .dragndrop-photo-parent:hover, .dragndrop-photo-parent:visited, .dragndrop-photo-parent:active, .dragndrop-photo-parent:focus{
                //     outline: none;
                // }
                .dragndrop-photo-parent {
                  // outline: none;
                  height: 100px;

                  section:hover,
                  section:active,
                  section:focus,
                  section:visited {
                    outline: none;
                    div:hover,
                    div:active,
                    div:focus,
                    div:visited {
                      outline: none;
                    }
                  }
                  section {
                    outline: none;
                    div {
                      outline: none;
                    }
                  }

                  section {
                    // height: 100%;

                    div {
                      height: 100%;

                      .upload-box {
                        margin: auto;
                        border-radius: 50%;
                        width: 100px;
                        height: 100px;
                        border: 3px dotted rgba(162, 162, 162, 0.5);
                        position: relative;
                        overflow: hidden;
                        transition: height 0.5s;
                        cursor: pointer;
                        &.on-drag {
                          border: 3px dotted $blue;
                        }
                        &.uploading {
                          border: 3px dotted $blue;
                        }
                        .text-content {
                          position: absolute;
                          top: 50%;
                          left: 50%;
                          transform: translate(-50%, -50%);
                          text-align: center;
                          color: $mid;
                          font-size: 13px;
                          display: grid;
                          place-items: center;
                          width: 76px;
                          pointer-events: none;
                        }
                      }

                      .upload-bar {
                        height: 5px;
                        width: 35%;
                        transform: translate(-50%, -50%);
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        background-color: $mid;
                      }

                      .profile_pic {
                        height: 100px;
                        position: relative;
                        img {
                          cursor: pointer;
                          margin: auto;
                          border-radius: 50%;
                          height: 95%;
                          display: block;
                          width: 100px;
                          object-fit: cover;
                        }
                        svg {
                          position: absolute;
                          top: 0px;
                          right: 0%;
                          font-size: 14px;
                          cursor: pointer;
                          opacity: 0;
                          transition: ease 0.2s;
                        }
                        &:hover {
                          svg {
                            opacity: 1;
                          }
                        }
                        &.uploading {
                          img {
                            border: 2px dashed $blue;
                          }
                        }
                      }
                    }
                  }
                }

                p {
                  margin: 0;
                  padding: 0;
                  white-space: nowrap;
                }

                .name {
                  opacity: 0.98;
                  color: #000000;
                  font-size: 19px;
                  font-weight: bold;
                  letter-spacing: 0;
                  text-align: center;
                  height: 15%;
                }

                .lastlogin {
                  opacity: 0.98;
                  color: $mid;
                  font-size: 15px;
                  letter-spacing: 0;
                  text-align: center;
                  height: 15%;
                }
              }
            }
            .panels {
              height: 20%;
              margin: 0;
              position: relative;
              width: 50%;

              ul {
                height: 100%;
                position: absolute;
                margin: 0;
                bottom: 0;
                padding-left: 6%;
                display: flex;
                align-items: flex-end;

                .Active {
                  border-bottom: 1px solid $dark;
                  color: $dark;
                }

                li {
                  margin: 0 6px;
                  padding: 0 5px;
                  height: 95%;
                  list-style-type: none;
                  // display:inline-block;
                  display: flex;
                  align-items: center;
                  color: $mid;
                  font-size: 15px;
                  cursor: pointer;
                  margin-right: 50px;
                  :hover {
                    cursor: pointer;
                  }
                }
              }
            }
          }
          .body {
            height: 70%;
            margin: 0;
            font-size: 16px;
            .save {
              cursor: pointer;
              position: relative;
              background-color: $blue;
              color: white;
              width: 30%;
              height: 30px;
              text-align: center;
              margin-right: auto;
              margin-left: auto;
              margin-top: 30px;
              margin-bottom: 20px;
              .text {
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                display: inline-block;
                font-weight: 600;
                font-size: 12px;
              }
            }
            .SaveText {
              margin-top: 60px;
              text-align: center;
              width: 30%;
              margin-left: auto;
              margin-right: auto;
              margin-bottom: 55px;
              // border:1px solid $green;
              color: $green;
              vertical-align: middle;
              font-weight: 600;
              font-size: 12px;
            }
            .SaveTextHide {
              display: none;
            }
          }
        }

        .newUser {
          .top-header .panels {
            .Activity {
              display: none;
            }
          }
        }
      }
    }
  }

  .account-status {
    background: red;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    height: 60px;
    z-index: -1;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}
