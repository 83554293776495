@import "src/colorVariables.scss";

.confSettings {
  .boxContent {
    display: flex;
    flex-direction: column;
    gap: 20px;
    > div {
      p {
        margin-bottom: 0;
        font-weight: 600;
      }
      > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        // justify-content: space-between;
        input {
          display: flex;
          max-width: 300px;
        }
      }
    }
  }
}
