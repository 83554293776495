@import "src/colorVariables.scss";

.options-list.custom-elements {
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  .search,
  .title {
    width: 100%;
  }
  .search {
    position: relative;

    svg {
      position: absolute;
      margin-left: 10px;
    }
    input {
      width: 100%;
      padding-left: 40px;
      background-color: transparent;
      border: none;
      top: 50%;
      transform: translateY(-25%);
    }

    &:focus-visible {
      outline: transparent;
    }
  }
  .title {
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    letter-spacing: 1px;
    background-color: $text-color;
    color: white;
    margin-left: 0;
    line-height: unset;
  }
  .choice {
    display: flex;
    flex-direction: column;
    width: 80px;
    // height: 80px;
    align-items: center;
    justify-content: top;
    cursor: pointer;
    padding-top: 20px;
    .custom-icon {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      .wrap-box {
        margin-top: 2px;
        border: 1px solid;
        height: 54%;
        width: 80%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        > div {
          border: 1px solid;
          width: 25%;
          height: 70%;
        }
        .ck-content {
          display: none;
          p {
            margin-bottom: 15px;
          }
        }
      }
    }
    .image {
      display: flex;
      width: 30px;
      height: 30px;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      img {
        width: 22px;
        height: 22px;
      }
    }
    .text {
      font-size: 12px;
      margin-top: 2px;
      text-align: center;
      line-height: 15px;
      margin-bottom: 20px;
      margin-top: 8px;
      color: #373737;
      user-select: none;
    }

    &:hover {
      background-color: $light;
      // // background-color: black;

      // .image,
      // .custom-icon {
      //   // background-color: rgba($color: #000000, $alpha: 0.8);
      //   // color: white;
      //   // img {
      //   //   filter: brightness(0) invert(1);
      //   // }
      // }
      // .text {
      //   // color: white;
      // }
    }
  }
  .render-page-modules {
    display: flex;
    flex-wrap: wrap;
    padding: 15px 0;
    max-height: 580px;
  }
}
