@import "src/colorVariables.scss";

.gapi-analytics-data-chart {
  rect {
    fill: transparent;
  }
}
.analytics-content {
  height: 100%;
  overflow-y: auto;

  .analytics-wrapper {
    width: 100%;
    text-align: center;
    padding: 2rem;
    position: relative;
    & > h1 {
      text-align: left;
      font-size: 30px;
      opacity: 0.98;
      font-weight: bold;
    }

    .header-sel {
      width: 100%;
      height: 80px;
      padding: 10px 0 10px 0;

      // overflow: auto;
      // position: relative;
      .select {
        width: 300px;
        float: left;
      }
    }

    .dates {
      width: 100%;
      height: 48px;
      padding: 0;
      margin: 0;
      // padding-bottom: 12%;

      .react-datepicker-wrapper {
        z-index: 999 !important;
        height: 100%;
        width: 120px;
        float: left;
        display: block;
        background-color: rgba(162, 162, 162, 0.1);
        input {
          background-color: rgba(162, 162, 162, 0.1);
          color: $mid;
          padding: 8% 5%;
        }
      }

      .from,
      .to {
        width: 60px;
        padding-top: 14px;
        height: 100%;
        float: left;
        color: $dark;
        text-align: center;
        display: block;
      }
      .from {
        display: none;
      }
    }

    .analytics-dashboard {
      .charts-wrapper {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        #europe-traffic-geo-chart {
          max-width: 800px;
          margin: auto;
          width: 100%;
          margin-bottom: 50px;
        }
        #Session-by-date,
        #Active-users-chart {
          width: 47%;
        }
        #Session-by-source,
        #Session-by-device {
          align-self: flex-start;
          width: 47%;
        }
        #Page-view-per-path {
          width: 100%;
        }

        .geo-chart-wrapper {
          width: 100%;
          .gapi-data-chart-container {
            padding: 1rem;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .analytics-auth-widgets {
        text-align: left;
        margin-bottom: 2rem;

        & > button {
          position: absolute;
          top: 2rem;
          right: 2rem;
          background: transparent;
          border: none;
          padding: 0;
          font-size: 0.9rem;
          border-bottom: 1px solid transparent;
          color: crimson;
          transition: border 1s;
          &:hover {
            border-bottom: 1px solid crimson;
          }
        }
      }
    }
  }
}
// .charts-wrapper {
//   display: flex;
//   flex-wrap: wrap;
//   flex-direction: row;
// }
// .gapi-data-chart-container {
//   padding: 1rem;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   // border: 1px solid #d3d3d3a6;
//   margin: 0 2rem 2rem 0;
//   width: auto;
//   min-width: 450px;
//   max-width: 500px;
//   // box-shadow: 0px 6px 10px 0px #80808036;
// }

// #gapi-sessions-geo-chart-28-days {
//   width: 100%;

//   .gapi-analytics-data-chart {
//     width: 100%;
//     div {
//       width: 100%;
//       div {
//         width: 100%;
//         padding: 0;
//         margin: 0;
//         svg {
//           width: 100%;
//         }
//       }
//     }
//     .lrt {
//       width: 100%;
//     }
//   }
// }

// .analytics-auth-widgets {
//   text-align: left;
//   margin-bottom: 2rem;

//   & > button {
//     position: absolute;
//     top: 2rem;
//     right: 2rem;
//     background: transparent;
//     border: none;
//     padding: 0;
//     font-size: 0.9rem;
//     border-bottom: 1px solid transparent;
//     color: crimson;
//     transition: border 1s;
//     &:hover {
//       border-bottom: 1px solid crimson;
//     }
//   }
// }

// #gapi-pageviews-per-path-chart-7-days {
//   max-width: none;
// }
