@import "src/colorVariables.scss";
.slider-options {
  height: 630px;
  width: 430px;
  display: flex;
  flex-direction: column;
  user-select: none;
  margin: 0;
  #handle {
    cursor: move;
    height: 28px;
    display: flex;
    align-items: center;
    margin-left: 8px;
    svg {
      margin: 0 1px;
    }
  }
  .close {
    top: 4px;
    right: 4px;
  }
  .top {
    background-color: $light;
    display: flex;
    justify-content: center;
    height: 26px;
    flex-shrink: 0;
    .top-choice {
      padding: 0 22px;
      font-size: 13px;
      font-weight: bold;
      letter-spacing: 1px;
      user-select: none;
      cursor: pointer;
      color: $mid;
      height: 100%;
      display: grid;
      place-items: center;

      &.active {
        color: black;
      }
    }
  }
  .content {
    width: 100%;
    padding: 20px 20px 0px 20px;
    .options {
      width: 80%;
      margin: auto;
      color: $dark;
      font-size: 16px;
      > div {
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        label {
          margin-bottom: 0;
          cursor: pointer;
        }
      }
    }
  }
}
