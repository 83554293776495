@import "src/colorVariables.scss";
@import "src/colorVariablesDs.scss";

.serviceTabs {
  background-color: white;
  max-width: 1280px;
  margin: auto;
  position: relative;
  z-index: 3;
  .top {
    display: flex;
    .add-tab {
      width: 100%;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 5px inset rgba($color: #000000, $alpha: 0.1);
      cursor: pointer;
    }
    .wrap-tab {
      cursor: pointer;
      width: 100%;
      height: 80px;
      border-right: 1px solid rgba($color: #000000, $alpha: 0.1);
      // border-top: 1px solid;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      flex-direction: column;
      > div {
        text-transform: uppercase;
        font-size: 0.8em;
        font-weight: 600;
      }
      .change-icon {
        position: absolute;
        right: 2px;
        top: 2px;
        border-radius: 2px;
        display: flex;
        align-items: center;
        background-color: rgba(162, 162, 162, 0.2);
        padding: 4px;
      }
      .change-icon:hover {
        background-color: rgba(162, 162, 162, 0.6);
      }
      .icon {
        height: 32px;

        img {
          height: 100%;
          object-fit: contain;
          filter: grayscale(1) invert(0);
        }
      }
      &.selected {
        background-color: $mainColor;
        color: white;
        .icon {
          img {
            filter: grayscale(1) invert(1);
          }
        }
      }
      .remove {
        top: 4px;
        height: 20px;
        width: 20px;
        left: 4px;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.7;
        cursor: pointer;
        z-index: 3;
      }
    }
    > div:last-child {
      border-right: unset;
    }
  }
  .list {
    min-height: 264px;
    border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
    display: flex;
    justify-content: space-between;
    padding: 30px;
    align-items: stretch;
    .links {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: calc((100% / 3) * 2 - 40px);
      gap: 20px;
      > div {
        font-weight: 500;
        cursor: pointer;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
        padding: 0 4px 20px 4px;
        width: 100%;
        font-size: 0.8em;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    .feature-image {
      max-width: calc(100% / 3);
      height: 100%;
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &.phone {
    .top {
      flex-wrap: wrap;
      .wrap-tab {
        width: 50%;
      }
      > div:nth-child(1),
      > div:nth-child(2) {
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
      }
    }
    .list {
      .links {
        width: 100%;
      }
      .feature-image {
        display: none;
      }
    }
  }
}
