@import "src/colorVariables.scss";
.vfi-div-input {
  border: 2px solid transparent;
  border-radius: 2px;
  background-color: rgba($color: $light, $alpha: 0.2);
  color: white;
  padding: 0 12px;
  &.focus {
    border: 2px solid $blue;
  }
  &.disabled {
    background-color: transparent;
  }
}
