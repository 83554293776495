.media-grid {
  .item {
    .whatson-item {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
      img {
        pointer-events: none;
      }
      .title {
        color: white;
        font-family: "Libre Baskerville";
        font-weight: bold;
        font-size: 21px;
        margin-bottom: 113px;
        text-align: center;
        background-color: transparent;
      }
    }
  }
}

.whatson-grid.edit-module {
  .content {
    .media {
      .medium {
        height: 110px;
        img {
          pointer-events: none;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        p.title {
          position: absolute;
          bottom: 0;
          left: 10px;
          width: calc(100% - 20px);
          text-align: center;
        }
      }
      .add-wrap {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
        .add {
          position: unset;
        }
      }
    }
    .templates {
      margin-top: 20px;
    }
  }
}
