@import "src/colorVariables.scss";
.download {
  width: 100%;
  max-width: 1440px;
  display: grid;

  row-gap: 1rem;
  margin: 40px auto;
  justify-content: center;
}

.download.grid {
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  grid-auto-flow: dense;

  &.box-amount1 {
    .download-box {
      justify-self: center;
    }
  }

  &.box-amount2 {
    .box1 {
      justify-self: right;
    }
  }

  &.box-amount3 {
    .box1,
    .box3 {
      justify-self: right;
    }
  }

  &.box-amount4 {
    // @media (max-width: 1079px) {
    //     .box1,
    //     .box3 {
    //         justify-self: right;
    //     }
    // }
  }

  // &.box-amount2,
  // &.box-amount3,
  // &.box-amount4 {
  //     @media ($md) {
  //         .download-box {
  //             justify-self: center;
  //         }
  //     }
  // }
}

.download.list {
  grid-auto-columns: 480px;

  .box1 {
    grid-column: 1/2;
  }
  .box2 {
    grid-column: 2/3;
  }
  .box3 {
    grid-column: 3/4;
  }
  .box4 {
    display: none;
  }

  // @media ($pgsize) {
  //     row-gap: 2.5rem;
  //     .box3 {
  //         grid-column: 1/2;
  //         grid-row: 2;
  //     }
  //     .box4 {
  //         grid-column: 2/3;
  //         grid-row: 2;
  //     }
  // }

  // @media ($lg) {
  //     display: flex;
  //     flex-direction: column;
  //     align-items: center;
  //     gap: 2.5rem;
  // }
}

.download-box {
  position: relative;
  width: 100%;
  .media-wrap {
    position: relative;

    .one-media {
      height: 100%;
      max-width: unset;
      .image-container {
        img {
          position: absolute;
        }
        .missing-image {
          min-height: unset;
          min-width: unset;
        }
      }
    }

    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-color: gray;
    }

    .middle-diamond {
      position: absolute;
      width: 22px;
      height: 22px;
      transform: translate(-50%, -50%) rotateZ(45deg);
      background-color: white;
    }
  }
  .lower-wrap {
    .title,
    .subtitle {
      color: $text-color;
    }

    .title {
      font-family: "Libre Baskerville";
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 22px;
    }
    .subtitle {
      font-family: "Source Sans Pro";
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0;

      margin-top: 10px;

      background-color: transparent;
    }
    .button-link {
      text-decoration: none;
      display: inline-block;
      width: 100%;
    }
    .button-wrap {
      // padding: 0 20px;
    }

    .button {
      margin: 25px auto auto auto;
      border: 2px solid $text-color;
      max-width: 352px;
      min-height: 52px;
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 0.5rem;
      gap: 1rem;

      transition: all 0.2s cubic-bezier(0.22, 0.61, 0.36, 1);
      .button-label {
        // font-family: $sansPro;
        width: 100%;
        font-size: 15px;
        color: $text-color;
        font-weight: bold;
        letter-spacing: 2px;
        line-height: 19px;
        text-align: center;

        text-transform: uppercase;
      }
      .download-button-icon {
        color: $text-color;
        font-size: 23px;
      }

      &:hover {
        background-color: $text-color;
        .button-label {
          color: #fff;
        }
        .download-button-icon {
          color: #fff;
        }
        svg {
          color: #fff;
        }
        .vfi-input-text {
          label {
            input {
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.download-box.grid {
  max-width: 480px;

  .media-wrap {
    height: 180px !important;
    .middle-diamond {
      left: 50%;
    }
  }

  .lower-wrap {
    padding: 4px;

    .title,
    .subtitle {
      text-align: center;
      padding: 0 20px;
    }

    .title {
      margin-top: 35px;
      height: 22px;
    }
    .button-link {
      padding: 0 20px;
    }

    .button-wrap {
      padding: 0 20px;
    }
  }
}

.download-box.list {
  display: flex;
  max-width: 480px;

  .media-wrap {
    width: 40%;
    height: 250px;

    .middle-diamond {
      right: -22px;
      top: 50%;
    }
  }

  .lower-wrap {
    width: 100%;
    max-width: 300px;
    padding: 0 30px;

    .title,
    .subtitle {
      text-align: left;
      padding: 0px;
    }

    .title {
      margin-top: 30px;
    }

    // @media ($md) {
    //     padding: 0 clamp(15px, 2.5vw, 30px);
    // }
  }
}

// CMS stylings
.download-box {
  .lower-wrap {
    .vfi-input-text {
      input {
        padding: 0;
        text-align: center;
        background-color: transparent;
      }
      &.title {
        margin-top: 35px;
        input {
          font-weight: bold;
          letter-spacing: 1px;
          font-size: 19px;
        }
      }
      &.subtitle {
        margin-top: 10px;
        input {
          font-size: 16px;
        }
      }
      &.button-label {
        min-width: 100px;
        width: auto;
        input {
          width: 100%;
          font-weight: 600;
          font-size: 15px;
          letter-spacing: 2px;
          text-transform: uppercase;
        }
      }
    }
    .button {
      .download-module-options {
        position: absolute;
        right: -10px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        cursor: pointer;
        &:hover {
          background-color: $light;
        }
      }
    }
  }
}

.tablet,
.phone {
  .download-module {
    flex-wrap: wrap;
    .one-download {
      width: 50%;
      .lower-wrap {
        .button {
          flex-wrap: nowrap;
          .button-label {
            input {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .download.grid {
    &.box-amount4 {
      // @media (max-width: 1079px) {
      .box1,
      .box3 {
        justify-self: right;
      }
      // }
    }

    &.box-amount2,
    &.box-amount3,
    &.box-amount4 {
      // @media ($md) {
      .download-box {
        justify-self: center;
      }
      // }
    }
  }

  .download.list {
    // @media ($pgsize) {
    row-gap: 2.5rem;
    .box3 {
      grid-column: 1/2;
      grid-row: 2;
    }
    .box4 {
      grid-column: 2/3;
      grid-row: 2;
    }
    // }

    // @media ($lg) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
    // }
  }
}
.size700,
.size350 {
  .download-module {
    .one-download {
      .button {
        .download-button {
          margin-right: 20px;
        }
      }
    }
  }
}
.size350 {
  .download-module {
    .one-download {
      width: 100%;
      .button {
        .download-button {
          margin-right: 0;
        }
      }
    }
  }
}
