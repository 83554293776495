@import "src/colorVariables.scss";
.edit-sitesMenu {
  height: 100%;
  background-color: #f4f4f5;
  .loading {
    pointer-events: none;
    opacity: 0.5;
  }
  &.row {
    margin-left: 0;
    margin-right: 0;
    flex-wrap: nowrap;
  }
  a:link {
    color: $text-color;
  }
  a:visited {
    color: $text-color;
  }

  .sitesMenu-section {
    // background-color: red;
    padding-left: 30px;
    height: 100%;
    flex-basis: calc(100% - 420px);
    max-width: unset;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: auto;

    .sitesMenu-section-header {
      min-height: 100px;

      .fixed {
        height: 50px;
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        &.single {
          .one-language-wrap {
            justify-content: center;
          }
        }
        // &.selected {
        //     border-bottom: 1px solid black;
        //     color: black;
        // }
        // &.disabled {
        //     pointer-events: none;
        //     opacity: 0.2;
        // }
        &:hover {
          background-color: $light;
        }

        .header-title {
          font-weight: 600;
          font-size: 19px;
          margin-right: 50px;
          cursor: pointer;
          transition: ease-in-out 0.2s;

          &:hover {
            color: $blue;
          }
        }

        .add-language {
          cursor: pointer;
        }

        .one-language,
        .add-language {
          width: 70px;
          height: 50px;
          // padding: 15px 25px;
          margin: 10px;
          border-bottom: 1px solid $mid;
          color: $mid;
          font-size: 14px;
          transition: ease-in-out 0.2s;
          user-select: none;
          display: flex;
          align-items: center;
          justify-content: center;
          &.selected {
            border-bottom: 1px solid black;
            color: black;
          }
          &.disabled {
            pointer-events: none;
            opacity: 0.2;
          }
          &:hover {
            background-color: $light;
          }
          .one-language-wrap {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            width: 100%;
            .the-language,
            .trash {
              padding: 4px 8px;
              border-radius: 2px;
              cursor: pointer;
              transition: ease-out 0.05s;

              &:hover {
                background-color: rgba($color: #ffffff, $alpha: 1);
                &.trash {
                  color: $red;
                }
              }
            }
            .trash {
              color: $mid;
            }
          }
        }
      }
    }
    .sitesMenu-section-content {
      height: calc(100% - 120px);
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.11);
      align-self: center;
      width: 100%;
      position: relative;
      transition: ease-in-out 0.5s;
      .blur {
        position: absolute;
        display: grid;
        place-items: center;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 9;
        font-size: 24px;
      }
      .sitesMenu-content-top {
        padding: 10px;
        min-height: 50px;
        background-color: white;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
        justify-content: center;
        .edit-section {
          flex-wrap: wrap;
          justify-content: center;
          display: flex;
          & > div {
            display: inline-block;
            margin-left: 10px;
            padding: 5px 10px;
            border-radius: 2px;
            cursor: pointer;
            transition: ease-in-out 0.2s;
            white-space: nowrap;
            &:hover {
              background-color: rgba($color: #000000, $alpha: 0.1);
            }
          }
          .discard-button {
            color: $red;
          }
        }
        .headline-text {
          flex-grow: 1;
          text-align: center;
          font-size: 16px;
        }
        .display-sitesMenu {
          margin: 0 10px;
          padding: 0 5px;
          cursor: pointer;
          border-radius: 2px;
          transition: ease-in-out 0.2s;
          &:hover {
            background-color: rgba($color: #000000, $alpha: 0.1);
          }
          &.active {
            background-color: $dark;
            color: white;
          }
        }
      }
      .footer-content {
        height: 90%;
        overflow-y: auto;
        .module {
          position: relative;
          max-width: 1200px;
          margin: 15px 0;
          padding: 0 30px;
          color: $text-color;

          p,
          h1,
          h2,
          h3 {
            line-height: 1.5rem;
            margin-bottom: 14px;
          }

          h1,
          h2,
          h3 {
            font-weight: bolder;
          }

          p {
            // Used as spacer between H3's,
            // 24px + h4 margin-bottom of 16px = 40px space
            margin-bottom: 0;
          }

          h3 {
            font-size: 17px;
            font-weight: bold;
            letter-spacing: 0.4px;
            line-height: 22px;

            margin-bottom: 1.25rem;
          }

          h4 {
            &.paragraph_libre {
              font-size: 16px;
              font-style: italic;
              letter-spacing: 0;
              line-height: 20px;

              margin-bottom: 1rem;

              a {
                color: #000000;
              }
            }
          }

          // h3 {
          //     font-size: 1.25em;
          //     margin-top: 50px;
          // }

          // .module-options {
          //     // display: none;
          //     position: absolute;
          //     right: 20px;
          //     top: 20px;
          //     width: 28px;
          //     height: 28px;
          //     padding: 4px;
          //     cursor: pointer;
          //     z-index: 2;
          //     border-radius: 4px;
          //     color: rgba($color: #000000, $alpha: 0.5);
          //     transition: ease-in-out 0.2s;
          //     &:hover {
          //         background-color: $light;
          //         color: rgba($color: #000000, $alpha: 1);
          //     }
          // }

          .col-lg-1,
          .col-lg-2,
          .col-lg-3,
          .col-lg-4,
          .col-lg-5,
          .col-lg-6,
          .col-lg-7,
          .col-lg-8,
          .col-lg-9,
          .col-lg-10,
          .col-lg-11,
          .col-lg-12,
          .col,
          &.col {
            padding: 0;
          }

          .col-lg-1 {
            max-width: 62px;
          }

          .row {
            margin-left: 0;
            margin-right: 0;
          }

          .ck-content {
            overflow: hidden;
            height: 100%;
            background-color: rgba($color: $mid, $alpha: 0.1);
          }

          &.row,
          .row {
            align-items: stretch;
          }
        }

        &.disabled {
          .module {
            .vfi-text-area {
              pointer-events: none;
            }

            .ck-content {
              background-color: transparent;
            }
            input {
              background-color: transparent;
            }
            .link-to {
              opacity: 0;
            }
          }
        }
      }
    }
  }
}
