@import "src/colorVariables.scss";
.grid-template-choose {
  height: 100%;
  margin-top: -10px;
  .close {
    position: absolute;
    right: 0;
    margin-top: 20px;
    margin-right: 20px;
    opacity: 1;
    cursor: pointer;
    z-index: 1;
  }
  .content {
    display: flex;
    flex-direction: column;
    width: unset;
    height: 100%;

    h2 {
      margin: 20px;
    }

    .templates {
      flex-grow: 1;
      background-color: $lighter;
      margin: 0 20px;
      padding: 10px;
      // display: flex;
      align-content: flex-start;
      flex-wrap: wrap;
      overflow: auto;
      .grid-types {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 10px;
        padding: 6px;
        width: 100%;
        .grid-type {
          font-size: 13px;
          font-weight: bold;
          cursor: pointer;
          padding: 2px 10px;
          transition: ease-out 0.2s;
          &.active {
            // background-color: black;
            // color: white;
            border-bottom: 1px solid;
          }
        }
      }
      .template {
        transition: ease-in-out 0.2s;
        background-color: lightgray;
        display: inline-block;
        margin: 5px;
        padding: 5px;
        user-select: none;
        cursor: pointer;
        .media-grid.choice,
        .media-grid-related.choice {
          width: 200px;
          height: 100px;
          .grid-container {
            grid-template-rows: 50% 50%;
            height: 100%;
            .item {
              border: 1px solid black;
              margin: 1px;
            }
          }
        }
        .media-grid-related.choice {
          height: 100px;
          .grid-container {
            // grid-template-rows: auto;
          }
        }
        .text {
          text-align: center;
        }
        &.selected {
          background-color: $green;
        }
      }
    }

    .final-options {
      display: flex;
      justify-content: flex-end;
      .option {
        width: 90px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: bold;
        margin: 20px 20px;
        cursor: pointer;

        &.select {
          background-color: $blue;
          color: white;
        }

        &.cancel {
          background-color: $light;
        }

        &.inactive {
          opacity: 0.2;
          cursor: default;
        }
      }
    }
  }
}
