@import "src/colorVariables.scss";
.pages-list {
  width: 0px;
  height: 100%;
  background-color: rgba(162, 162, 162, 0.1);
  box-shadow: inset -10px 0 20px -7px rgba(0, 0, 0, 0.1);
  transition: ease-out 0.75s;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  user-select: none;
  &:hover {
    .add-page,
    .select-page,
    .setting {
      opacity: 1;
    }
  }
  .load-icon {
    position: absolute;
  }
  &.loaded {
    width: 255px;
  }
  .the-list {
    // overflow: hidden;
    .pages-headline {
      margin: 25px 0 0 30px;
      font-size: 12px;
      font-weight: 600;
      white-space: nowrap;
      color: $mid;
      min-height: 18px;

      &.top {
        margin-top: 108px;
      }
      &.shared-pages {
        margin-top: 80px;
      }
    }
    .page {
      font-size: 16px;
      padding: 3px 0 5px 30px;
      white-space: nowrap;
      cursor: pointer;
      background-color: transparent;
      transition: ease-in 0.1s;
      position: relative;
      &.hidden {
        display: none;
      }
      &.text-input {
        padding: 4px 25px 4px 25px;
        input {
          padding: 0;
          background-color: white;
        }
      }
      .short-lang {
        position: absolute;
        font-size: 12px;
        top: 8px;
        left: 8px;
        font-style: italic;
        color: $mid;
      }
      .title {
        max-width: 150px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
        &.is-draft {
          color: $mid;
        }
      }
      .deleted {
        display: inline-block;
        margin-left: 5px;
        font-size: 12px;
        color: $mid;
      }
      &:hover {
        background-color: lightgray;
        box-shadow: inset -10px 0 20px -7px rgba(0, 0, 0, 0.1);
        .options-button {
          opacity: 1;
        }
      }
      .default-button {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
      .options-button {
        position: absolute;
        width: 20px;
        right: 10px;
        top: 50%;
        text-align: center;
        transform: translateY(-50%);
        border-radius: 2px;
        opacity: 0;
        transition: ease-in-out 0.1s;
        &:hover {
          background-color: $mid;
        }
      }
      &.selected {
        background-color: black;
        color: $light;
        box-shadow: -10px 0 20px inset rgba(255, 255, 255, 0.2);
        .options-button {
          &:hover {
            background-color: $dark;
          }
        }
      }
      &.page-menu-open {
        background-color: rgba($color: $mid, $alpha: 0.5);
      }
      .status {
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
        pointer-events: none;
        .stat {
          color: $mid;
          font-size: 13px;
          margin: -6px 0;
          // display: inline-block;
          font-style: italic;
          display: flex;
        }
      }
      &.ghost {
        cursor: unset;
        &:hover {
          background-color: transparent;
          box-shadow: unset;
          .options-button {
            opacity: 1;
          }
        }
        .title {
          color: $blue;
        }
      }
    }
  }
  .add-page,
  .select-page,
  .setting {
    font-size: 20px;
    margin: auto;
    margin-bottom: 40px;
    cursor: pointer;
    background-color: $dark;
    opacity: 0;
    width: 50px;
    height: 50px;
    display: grid;
    place-items: center;
    border-radius: 60px;
    position: sticky;
    bottom: 40px;
    min-height: 50px;
    transition: ease-out 0.2s;
    svg {
      color: white;
      margin: auto;
      display: block;
    }
  }
  .select-page {
    text-align: center;
    width: unset;
    height: unset;
    // border-radius: unset;
    background-color: unset;
    padding: 5px 10px;
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
    p {
      margin: 0;
    }
    &:hover {
      background-color: black;
      color: white;
    }
  }
}
.pages-list:last-child {
  box-shadow: none;
}

@keyframes add {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.2;
  }
}
