@import "src/colorVariables.scss";
.page-history {
  width: 100%;
  font-size: 12px;
  th {
    color: $mid;
    border-bottom: 1px solid $light;
    padding: 2px 5px;
  }
  td {
    color: $dark;
    border-bottom: 1px solid $light;
    padding: 2px 10px 2px 5px;
  }
  tr.data-row {
    user-select: none;
    cursor: pointer;
    &:hover {
      background-color: rgba($color: #000000, $alpha: 0.1);
    }
    &.selected {
      background-color: rgba($color: #000000, $alpha: 0.2);
      .td {
        font-weight: bold;
      }
    }
  }
}
