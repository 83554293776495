.tree-sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;

  h1 {
    font-size: larger;
    font-weight: 700;
    padding: 40px 15px 0 25px;
  }
  .small-gray {
    font-size: 11px;
    color: rgba($color: #000000, $alpha: 0.35);
    margin: 20px 0 0 25px;
    font-weight: 700;
  }
  .sites-select {
    margin: 0 20px 0 25px;
    .css-yk16xz-control {
      background-color: rgb(241, 242, 244);
      border: none;
    }
    .css-g1d714-ValueContainer {
      padding: 2px 8px 2px 35px;
      font-size: 0.95em;
      font-weight: 600;
    }
    .css-1okebmr-indicatorSeparator {
      background-color: transparent;
    }
    svg {
      display: none;
    }
  }
  .folder-icon {
    position: absolute;
    transform: translate(30px, -31px);
    pointer-events: none;
  }
  .caret-down-icon {
    position: absolute;
    transform: translateY(-31px);
    right: 35px;
    pointer-events: none;
  }

  // List option stylings..
  .pages-parent {
    flex: 0 1 auto;
    overflow-y: auto;
    .tree-branch {
      margin-left: 25px;
      font-size: 14px;

      .tree-title {
        //user-select: none;
        padding: 0px 0;
        .file-icon {
          margin-right: 15px;
        }
        .arrow-icon {
          //padding-right: 10px;
          display: inline-block;
          width: 20px;
        }
        .title {
          pointer-events: none;
        }
      }

      .between-branch {
        height: 8px;
      }

      .between-branch.last-root {
        height: 100px;
      }
    }

    .tree-branch.no-children {
      padding-left: 20px;
      .between-branch {
        height: 8px;
      }
    }

    .tree-branch.no-children.is-root {
      padding-left: 25px;
      margin-left: 0;
    }

    .tree-branch.selected > .tree-title {
      background-color: rgb(230, 230, 230);
    }
  }
}
