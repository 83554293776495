.container{
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	&.greyout{
		background-color: #0009;
	}
}