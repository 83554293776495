@import "src/colorVariables.scss";

#circle-btn {
  all: unset;
  cursor: pointer;
}

#circle-btn {
  font-size: 20px;
  margin: auto;
  margin-bottom: 40px;
  background-color: $dark;
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  display: grid;
  place-items: center;
  border-radius: 60px;
  transition: ease-out 0.2s;

  svg {
    color: white;
    margin: auto;
    display: block;
  }
  &:active {
    scale: 0.95;
  }
}
