@import "src/colorVariables.scss";
.approval-wrap {
  max-width: 242px;
  display: flex;
  // flex-wrap: wrap;
  justify-content: center;
  // width: min-content;

  // margin-top: 10px;
  max-width: unset;
  justify-content: flex-start;
  gap: 10px;
  padding: 0 1px;

  .approve,
  .changes-required,
  .deny {
    width: max-content;
    margin: 0 5px;
    font-size: 14px;
    // background-color: $light;
    display: inline-block;
    padding: 1px 5px;
    color: black;
    transition: ease-in-out 0.2s;
    cursor: pointer;
    margin-bottom: 2px;
    box-shadow: 0 0 2px $mid;

    margin: 0;
    font-size: 15px;
    // margin-right: 10px;
    flex: 1 1 auto;
    text-align: center;
    padding: 10px;
  }

  :hover {
    // background-color: rgba(197, 197, 197, 0.63);
    background-color: rgba(100, 100, 100, 0.1);
  }
  .chosen {
    color: $blue;
  }
}
