.download-module.edit-module {
  margin: 0;
  height: 275px;
  .content {
    .media {
      padding: unset;
      display: flex;
      .option {
        flex-grow: 1;
      }
    }
  }
}
