.infoscreen-sidebar {
  display: flex;
  flex-direction: column;
  height: 100vh;

  h1 {
    font-size: larger;
    font-weight: 700;
    padding: 125px 15px 0 25px;
    margin-bottom: 22px;

    min-height: 64px;
  }

  // List option stylings..
  .list-parent {
    height: 100%;
    margin-bottom: 40px;
    overflow-y: auto;
  }

  .list-option {
    display: flex;
    justify-content: space-between;

    padding: 5px 10px 5px 25px;
    cursor: pointer;
    color: #313131;

    input#name {
      width: 90%;
      height: 24px;
    }
  }

  .list-option:hover {
    background-color: rgba($color: #efefef, $alpha: 1);

    .infoscreen-options-button {
      opacity: 1;
    }
  }

  .list-option.selected {
    // background-color: lightgray;
    background: rgb(211, 211, 211);
    background: linear-gradient(
      90deg,
      rgba(211, 211, 211, 1) 0%,
      rgba(218, 218, 218, 1) 50%,
      rgba(227, 227, 227, 1) 80%
    );
  }
}
