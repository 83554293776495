@import "src/colorVariables.scss";

$xxxl: "max-width: 2019px";
$xxl: "max-width: 1535px";
$xl: "max-width: 1279px";
$lg: "max-width: 1279px";
$md: "max-width: 767px";
$sm: "max-width: 639px";

$color-border-light: rgba(162, 162, 162, 0.2);

$vfi-dark: #373737;

@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap");
$LibreBasker: "Libre Baskerville";
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700;800&display=swap");
$sansPro: "Source Sans Pro";
.page-section-content {
  &.xl {
    .auto-related {
      .auto-related-content {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
}
.page-section-content {
  &.lg {
    .auto-related {
      .auto-related-content {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}
.page-section-content {
  &.md {
    .auto-related {
      .auto-related-content {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}
.page-section-content {
  &.sm {
    .auto-related {
      .auto-related-content {
        grid-template-columns: 1fr;
      }
    }
  }
}
