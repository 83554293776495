@import "src/colorVariables.scss";

.amenities {
  .placeAmenities {
    margin-top: 70px;
    list-style: none;
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
    padding: 0;

    @media screen and (min-width: 1600px) {
      width: 60%;
    }

    @media screen and (min-width: 1200px) {
      columns: 2;
      -webkit-columns: 2;
      -moz-columns: 2;
    }

    .option {
      margin-bottom: 10px;
      > label {
        margin: 0;
        padding-bottom: 0;
        display: flex;
        gap: 10px;
        align-items: center;
        line-height: 1;
        label {
          margin: 0;
        }
        > span {
          line-height: 1;
        }
      }

      .text {
        color: #000;
        padding: 0;
      }

      .smallText {
        color: rgba(162, 162, 162, 0.5);
        font-style: italic;
        padding: 0;
      }

      label {
        user-select: none;
        cursor: pointer;
      }

      .tooltipIcon {
        font-size: 0.8rem;
      }
    }
  }
}

.tooltip {
  // max-width: 400px;
  // // padding: 20px 15px;
  // text-align: center;

  // h3 {
  //   font-size: 1.2rem;
  //   text-align: left;
  //   margin-bottom: 20px;
  // }
  // p {
  //   font-size: 1rem;
  // }
}
