.list-type {
  &.whatson-module {
    width: 100%;
    height: 100%;
    margin: 0 auto;

    &.whatson-list {
      .heart-icon {
        position: absolute;
        top: 21px;
        right: 24px;
        i,
        svg {
          height: 26px;
          width: 26px;
          color: #ffffff;
          font-family: "Font Awesome 5 Pro";
          font-size: 26px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 26px;

          &.heart-white {
            font-weight: 900;
          }
        }
      }
    }
  }

  &.whatson-list {
    .container {
      display: flex;
      flex-direction: column;
      gap: 30px;
      max-width: 960px;
      margin: 0 auto;
    }

    .list-item {
      display: flex;
      height: 220px;
    }

    .image-container {
      position: relative;
      width: 100%;
      max-width: 380px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .text-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      width: 100%;
      padding: 46px 30px 30px;

      // @media ($lg) {
      //   padding: 36px 20px 20px;
      // }

      // @media ($md) {
      //   padding: 1rem;
      // }

      .top-container {
        h3 {
          color: #373737;
          font-family: "Libre Baskerville";
          font-size: 25px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 30px;

          margin: 0 0 10px;

          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          @supports (-webkit-line-clamp: 2) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
        p {
          color: #373737;
          font-family: "Source Sans Pro";
          font-size: 16px;
          font-style: italic;
          letter-spacing: 0;
          line-height: 17px;

          max-height: 70px;

          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          @supports (-webkit-line-clamp: 4) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;

            //   @media ($md) {
            //     -webkit-line-clamp: 3;
            //   }
          }

          margin: 0;
        }
      }

      .bottom-container {
        display: flex;
        justify-content: space-between;
        p {
          color: #373737;
          font-family: "Source Sans Pro";
          font-size: 15px;
          letter-spacing: 0;
          line-height: 19px;

          margin: 0;
        }
        .icon-container {
          display: flex;
          gap: 0.5rem;
          .icon {
            font-size: 20px;
            width: 20px;
            height: 20px;
            img {
              filter: invert(1);
              width: 100%;
              height: 100%;

              vertical-align: top;
            }
          }
        }
      }
    }
  }
}

.card-type {
  &.whatson-module {
    width: 100%;
    height: 100%;
    margin: 0 auto;

    &.whatson-card {
      .heart-icon {
        position: absolute;
        top: 21px;
        right: 24px;
        i,
        svg {
          height: 26px;
          width: 26px;
          color: #ffffff;
          font-family: "Font Awesome 5 Pro";
          font-size: 26px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 26px;

          &.heart-white {
            font-weight: 900;
          }
        }
      }
    }
  }

  &.whatson-card {
    .container {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 30px;
      width: fit-content;
      max-width: 1440px;
      margin: 0 auto;
      padding: 36px 55px;

      background-color: #f2f2f2;

      .card-item {
        position: relative;

        width: 100%;
        min-width: 310px;

        height: 400px;

        display: flex;
        flex-direction: column;

        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
      }
    }

    .card-item {
      min-width: 248px;
      max-width: 310px;
      width: 100%;
      height: 388px;

      display: flex;
      flex-direction: column;

      box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    }

    .image-container {
      min-width: 248px;
      max-width: 310px;
      width: 100%;
      height: 200px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .text-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      padding: 18px 20px 20px;

      background-color: #ffffff;

      .top-container {
        display: flex;
        height: calc(100% - 40px);
        flex-direction: column;
        justify-content: space-between;

        h3 {
          color: #373737;
          font-family: "Libre Baskerville";
          font-size: 19px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 25px;

          margin: 0 0 10px;
          margin-top: 10px !important;
          margin-bottom: 10px !important;

          @supports (-webkit-line-clamp: 2) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
        p {
          color: #373737;
          font-family: "Source Sans Pro";
          font-size: 16px;
          font-style: italic;
          letter-spacing: 0;
          line-height: 17px;

          height: 100%;
          max-height: 52px;

          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          @supports (-webkit-line-clamp: 3) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }

          margin: 0;
        }
      }

      .bottom-container {
        display: flex;
        justify-content: space-between;
        p {
          color: #373737;
          font-family: "Source Sans Pro";
          font-size: 15px;
          letter-spacing: 0;
          line-height: 19px;

          margin: 0;
        }
        .icon-container {
          display: flex;
          gap: 0.5rem;
          .icon {
            font-size: 20px;
            width: 20px;
            height: 20px;
            img {
              filter: invert(1);
              width: 100%;
              height: 100%;

              vertical-align: top;
            }
          }
        }
      }
    }
  }
}

.card-type,
.list-type {
  .image-container {
    &.background-color {
      position: relative;
      .bottom-shadow {
        position: absolute;
        height: 100%;
        width: 100%;
        bottom: 0;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.6) 0%,
          rgba(0, 0, 0, 0) 100%
        );
      }

      &.color0 {
        background-color: #c12759;
      }
      &.color1 {
        background-color: #006b74;
      }
      &.color2 {
        background-color: #009fff;
      }
      &.color3 {
        background-color: #217d3d;
      }
      &.color4 {
        background-color: #ae2c64;
      }
    }
  }
}
