.preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .inner {
    &.hidden {
      display: none;
    }

    &.display {
    }
  }
}
