@import "src/colorVariables.scss";
.whatson-list-2 {
  overflow: hidden auto;
  height: calc(100% - 100px);
  .whatson-list {
    padding: 0 10px;
  }
  .showMore {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    > div {
      cursor: pointer;
      padding: 15px 20px;
      color: white;
      background-color: $blue;
    }
  }
}
