@import "src/colorVariables.scss";
.visit-faroe-islands-top {
  height: calc(100vh - 100px);
  position: relative;
  .carousel {
    height: 100%;
    .carousel-inner {
      height: 100%;
      .carousel-item {
        height: 100%;
        .one-media {
          max-width: unset;
          .image-container {
            max-height: unset;
            width: 100%;
            aspect-ratio: 5 / 3;
          }
        }
      }
    }
  }
  .add-media,
  .new-slide,
  .remove-page,
  .is-hero {
    position: absolute;
    bottom: 20px;
    font-size: 24px;
    padding: 5px 12px;
    border-radius: 2px;
    cursor: pointer;
    background-color: rgba($color: #ffffff, $alpha: 0.4);
    transition: ease-in-out 0.2s;
    &:hover {
      background-color: rgba($color: #ffffff, $alpha: 0.5);
    }
    bottom: 20px;
    right: 20px;
  }
  .add-media {
    color: white;
    background-color: transparent;
    left: 50%;
    top: 50%;
    bottom: unset;
    right: unset;
    transform: translate(-50%, -50%);
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 15px;
    .text {
      font-size: 14px;
      display: flex;
    }
  }
  .is-hero {
    position: absolute;
    z-index: 1;
    bottom: 140px;
    height: 44px;
  }
  .new-slide {
    bottom: 50px;
    right: 50px;
    padding: 5px 14px;
    z-index: 1;
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($color: #ffffff, $alpha: 0.8);
    &:hover {
      background-color: rgba($color: #ffffff, $alpha: 1);
    }
  }
  .remove-page {
    right: 80px;
    color: $red;
  }
  .image {
    height: 100%;
    width: 100%;
    object-fit: cover;

    .missing-image {
      background-color: black;
      opacity: 0.8;
      img {
        display: none;
      }
    }
  }
  .ck.ck-content {
    padding: 0;
    p {
      margin: 0;
    }
  }

  .middle-top {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 28px;
    display: flex;
    z-index: 1;
    .option {
      background-color: rgba($color: #ffffff, $alpha: 0.4);
      width: 28px;
      height: 28px;
      display: grid;
      place-items: center;
      font-size: 17px;
      border-radius: 2px;
      color: black;
      margin: 0 1px;
      cursor: pointer;
      transition: ease-out 0.2s;
      &.red {
        color: $red;
      }
      &:hover {
        background-color: rgba($color: #ffffff, $alpha: 0.8);
      }
    }
  }
  .faroe-islands-map {
    position: absolute;
    right: 50px;
    bottom: 45px;
    width: 30%;
    height: 90px;
  }
  .left-content {
    position: absolute;
    left: 80px;
    bottom: 170px;
    color: #fff;
    z-index: 1;
    width: auto;
    max-width: 900px;
    min-height: 230px;
    border: 1px solid transparent;
    padding: 0;
    display: flex;
    flex-direction: column;

    transition: ease-out 0.2s;
    &.active:hover {
      background-color: rgba($color: #000000, $alpha: 0.4);
      border: 1px solid $blue;
    }

    .hero-headline {
      margin-bottom: 25px;
      color: #ffffff;
      font-family: "Source Sans Pro";
      font-size: 54px;
      font-weight: bold;
      letter-spacing: 3.09px;
      line-height: 54px;
      text-shadow: 1px 1px 1px rgb(0, 0, 0);

      padding: 0;
      min-height: 54px;
    }

    .hero-excerpt {
      margin: 0;
      margin-bottom: 30px;
      color: #ffffff;
      font-family: "Source Sans Pro";
      font-size: 19px;
      letter-spacing: 0;
      line-height: 29px;

      min-height: 29px;

      p {
        margin: 0;
      }
    }

    .link {
      font-size: 20px;
      color: white;
      text-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.2);
      svg {
        margin-left: 10px;
      }
      .ck.ck-content.ck-editor__editable {
        background-color: transparent;
      }
    }
    .link {
      user-select: none;
      display: flex;
      align-items: center;
      .link-options-2 {
        cursor: pointer;
        color: black;
        background-color: rgba($color: #ffffff, $alpha: 0.4);
        border-radius: 2px;
        margin-left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px;
        font-size: 17px;
        transition: ease-out 0.2s;
        svg {
          margin-left: unset;
        }
        &:hover {
          background-color: $blue;

          color: white;
        }
      }
      .link-button {
        box-sizing: border-box;
        height: 52px;
        width: 352px;
        border: 2px solid #ffffff;
        border-radius: 2px;
        background-color: rgba(255, 255, 255, 0);

        display: flex;
        justify-content: center;
        align-items: center;

        .link-button-text {
          color: #ffffff;
          font-family: "Source Sans Pro";
          font-size: 15px;
          font-weight: bold;
          letter-spacing: 2px;
          line-height: 19px;
          text-align: center;
          text-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.3);

          text-transform: uppercase;
        }

        &.active {
          cursor: pointer;
          pointer-events: all;
        }
      }
    }
    .select-page {
      border: 1px solid gray;
      background-color: rgba(128, 128, 128, 0.2);
      display: inline-block;
      padding: 0 10px;
      border-radius: 2px;
      user-select: none;
      cursor: pointer;
    }
    .is-external {
      margin-left: 20px;
    }
  }
  .navigation-wrap {
    position: absolute;
    bottom: 45px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    margin-left: 30px;
    align-items: center;
    .left-button,
    .right-button {
      img {
        height: 28px;
      }
      margin-bottom: 22px;
      pointer-events: auto;
      cursor: pointer;
      width: 70px;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &.icon-to-arrow {
      width: 100%;
      justify-content: space-between;
      margin: auto;
      bottom: unset;
      top: 50%;
      transform: translate(-50% -50%);
      .left-button,
      .right-button {
        img {
          height: 60px;
        }
      }
    }
    .nav-icons {
      max-width: 230px;
      overflow: hidden;
      height: 107px;
      .icons-wrap {
        transition: ease-in-out 0.5s;
        margin-left: 0;
        display: flex;
        .icon {
          width: 70px;
          height: 70px;
          min-width: 70px;
          position: relative;
          background-color: transparent;
          margin-right: 10px;
          border-radius: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: ease-in-out 0.5s;

          &.active {
            background-color: rgba($color: #000000, $alpha: 0.4);
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer;
          }
          .sub-text {
            position: absolute;
            bottom: -37px;
            input {
              padding: 0;
              font-size: 17px;
              color: white;
              text-align: center;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }

  .arrow-buttons {
    pointer-events: all;
    .left-button,
    .right-button {
      position: absolute;
      top: 50%;
      z-index: 2;
    }

    .left-button {
      left: 42px;
    }

    .right-button {
      right: 42px;
    }
  }
}

.tablet {
  .visit-faroe-islands-top {
    height: calc(70vh - 100px);

    .left-content {
      width: 70%;
      max-width: 750px;
      left: 70px;

      .hero-headline {
        word-wrap: break-word;

        font-size: 46px;
        line-height: 46px;
        min-height: 46px;
      }

      .excerpt {
        line-height: 27px;
        min-height: 27px;
      }
    }
  }
}

.phone {
  .visit-faroe-islands-top {
    height: calc(65vh - 100px);

    .left-content {
      width: 90%;
      max-width: 90%;
      left: 0;
      margin-left: 5%;

      .hero-headline {
        word-wrap: break-word;

        font-size: 38px;
        line-height: 38px;
        min-height: 38px;
      }

      .excerpt {
        line-height: 24px;
        min-height: 24px;
      }
    }
  }
}
